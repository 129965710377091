import { Action, createReducer, on } from '@ngrx/store';
import {
    VendorCandidateRequirementDocumentUploadState,
    getInitialVendorCandidateRequirementDocumentUploadState
} from '../state/vendor-candidate-requirement-document-upload.state';
import * as actions from '../actions';
import { LoadingTypes } from 'src/app/shared/models';

export const vendorCandidateRequirementDocumentUploadReducer = (
    reducerState: VendorCandidateRequirementDocumentUploadState,
    reducerAction: Action
) => {
    return createReducer(
        getInitialVendorCandidateRequirementDocumentUploadState(),
        on(
            actions.setCandidateForDocumentUpload,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                candidate: action.candidate,
                candidateId: action.candidate.id
            })
        ),
        on(
            actions.uploadRequirementFilePreview,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                documentPreviewUploadLoading: LoadingTypes.LOADED,
                // documentPreviewUploadSuccess: false,
                // documentPreviewUploadFail: false,
                error: null,
                candidateId: action.candidateId,
                candidateOldUserId: action.candidateOldUserId,
                contractId: action.contractId,
                docTypeId: action.docTypeId
            })
        ),
        on(
            actions.uploadRequirementFilePreviewSuccess,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                documentPreviewUploadLoading: LoadingTypes.LOADED,
                // documentPreviewUploadSuccess: true,
                // documentPreviewUploadFail: false,
                deleteDocumentCalledWithNavigationSuccess: false,
                error: null,
                candidateDocumentId: action.candidateDocumentId
            })
        ),
        on(
            actions.uploadRequirementFilePreviewFailure,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                documentPreviewUploadLoading: LoadingTypes.FAIL,
                // documentPreviewUploadSuccess: false,
                // documentPreviewUploadFail: true,
                error: action.error
            })
        ),
        on(
            actions.getDocumentUploaded,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                documentPreviewDownloadLoading: LoadingTypes.LOADING,
                // documentPreviewDownloadSuccess: false,
                // documentPreviewDownloadFailure: false,
                error: null,
                candidateDocumentId: action.candidateDocumentId
            })
        ),
        on(
            actions.getDocumentUploadedSuccess,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                documentPreviewDownloadLoading: LoadingTypes.LOADED,
                // documentPreviewDownloadSuccess: true,
                // documentPreviewDownloadFailure: false,
                error: null,
                file: action.file
            })
        ),
        on(
            actions.getDocumentUploadedFailure,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                documentPreviewDownloadLoading: LoadingTypes.FAIL,
                // documentPreviewDownloadSuccess: false,
                // documentPreviewDownloadFailure: true,
                error: action.error
            })
        ),
        on(
            actions.getMetadataForRequirementFile,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                metadataFieldsLoading: LoadingTypes.LOADING,
                // metadataFieldsLoadedSuccess: false,
                // metadataFieldsLoadedFailure: false,
                error: null
            })
        ),
        on(
            actions.getMetadataForRequirementFileSuccess,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                metadataFieldsLoading: LoadingTypes.LOADED,
                // metadataFieldsLoadedSuccess: true,
                // metadataFieldsLoadedFailure: false,
                error: null,
                documentAttributeFields: action.documentRequirementAttributeFields,
                documentAttributes: action.documentAttributes
            })
        ),
        on(
            actions.getMetadataForRequirementFileFailure,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                metadataFieldsLoading: LoadingTypes.FAIL,
                // metadataFieldsLoadedSuccess: false,
                // metadataFieldsLoadedFailure: true,
                error: action.error
            })
        ),
        on(
            actions.getFilteredMetadataFieldsForRequirementFile,
            (state, actions): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                filteredMetadataFieldsLoading: LoadingTypes.LOADING,
                // filteredMetadataFieldsLoadedSuccess: false,
                // filteredMetadataFieldsLoadedFailure: false,
                error: null
            })
        ),
        on(
            actions.getFilteredMetadataForRequirementFileSuccess,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                filteredMetadataFieldsLoading: LoadingTypes.LOADED,
                // filteredMetadataFieldsLoadedSuccess: true,
                // filteredMetadataFieldsLoadedFailure: false,
                setDocumentAttributesLoading: LoadingTypes.LOADED,
                documentAttributeFields: action.documentRequirementAttributeFields,
                documentAttributes: action.documentAttributes,
                error: null
            })
        ),
        on(
            actions.getFilteredMetadataForRequirementFileFailure,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                filteredMetadataFieldsLoading: LoadingTypes.FAIL,
                // filteredMetadataFieldsLoadedSuccess: false,
                // filteredMetadataFieldsLoadedFailure: true,
                error: action.error
            })
        ),
        on(
            actions.setDocumentAttributeValue,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                setDocumentAttributesLoading: LoadingTypes.LOADING
                // setDocumentAttributesLoadedSuccess: false,
                // setDocumentAttributesLoadedFailure: false
            })
        ),
        on(
            actions.setDocumentAttributeValueSuccess,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                setDocumentAttributesLoading: LoadingTypes.LOADED,
                // setDocumentAttributesLoadedSuccess: true,
                // setDocumentAttributesLoadedFailure: false,
                docReviewerExpirationDate: action.docReviewerExpirationDate,
                documentAttributes: action.documentAttributes
            })
        ),
        on(
            actions.setDocumentAttributeValueEnd,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                setDocumentAttributesLoading: LoadingTypes.LOADED
            })
        ),
        on(
            actions.saveDocumentMetadata,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                saveDocumentMetadataLoading: LoadingTypes.LOADING,
                saveDocumentMetadataCalled: true,
                documentMetadataInputForSave: action.documentMetadataInputForSave
                // saveDocumentMetadataLoadedSuccess: false,
                // saveDocumentMetadataLoadedFailure: false
            })
        ),
        on(
            actions.saveDocumentMetadataSuccess,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                saveDocumentMetadataLoading: LoadingTypes.LOADED,
                // saveDocumentMetadataLoadedSuccess: true,
                // saveDocumentMetadataLoadedFailure: false,

                // reset the rest of state to update UI
                deleteDocumentCalled: false,
                deleteDocumentLoading: LoadingTypes.LOADED,
                // deleteDocumentLoadedSuccess: true,
                // deleteDocumentLoadedFailure: false,
                documentPreviewUploadLoading: LoadingTypes.LOADED,
                // documentPreviewUploadSuccess: false,
                // documentPreviewUploadFail: false,

                documentPreviewDownloadLoading: LoadingTypes.LOADED,
                // documentPreviewDownloadSuccess: false,
                // documentPreviewDownloadFailure: false,

                metadataFieldsLoading: LoadingTypes.LOADED,
                // metadataFieldsLoadedSuccess: false,
                // metadataFieldsLoadedFailure: false,

                filteredMetadataFieldsLoading: LoadingTypes.LOADED,
                // filteredMetadataFieldsLoadedSuccess: false,
                // filteredMetadataFieldsLoadedFailure: false,

                setDocumentAttributesLoading: LoadingTypes.LOADED,
                // setDocumentAttributesLoadedSuccess: false,
                // setDocumentAttributesLoadedFailure: false,
                documentAttributeFields: [],
                documentAttributes: [],

                candidateDocumentId: null,
                isValid: false,
                docReviewerExpirationDate: null,
                file: null,
                documentMetadataInputForSave: null,
                error: null
            })
        ),
        on(
            actions.saveDocumentMetadataFailure,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                saveDocumentMetadataLoading: LoadingTypes.FAIL,
                // saveDocumentMetadataLoadedSuccess: false,
                // saveDocumentMetadataLoadedFailure: true,
                error: action.error
            })
        ),
        on(
            actions.setIsFormValid,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                isValid: action.isFormValid
            })
        ),
        on(
            actions.deleteDocumentCalled,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                deleteDocumentCalled: true,
                deleteDocumentLoading: LoadingTypes.LOADING,
                // deleteDocumentLoadedSuccess: false,
                // deleteDocumentLoadedFailure: false,
                deleteDocumentCalledWithNavigation: action.deleteDocumentCalledWithNavigation,
                deleteDocumentCalledWithNavigationSuccess: false
            })
        ),
        on(
            actions.deleteDocumentSuccess,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                deleteDocumentCalled: false,
                deleteDocumentLoading: LoadingTypes.LOADED,
                // deleteDocumentLoadedSuccess: true,
                // deleteDocumentLoadedFailure: false,
                deleteDocumentCalledWithNavigation: action.deleteDocumentCalledWithNavigation,
                deleteDocumentCalledWithNavigationSuccess: action.deleteDocumentCalledWithNavigation,

                // reset the rest of state to update UI
                documentPreviewUploadLoading: LoadingTypes.LOADED,
                // documentPreviewUploadSuccess: false,
                // documentPreviewUploadFail: false,

                documentPreviewDownloadLoading: LoadingTypes.LOADED,
                // documentPreviewDownloadSuccess: false,
                // documentPreviewDownloadFailure: false,

                metadataFieldsLoading: LoadingTypes.LOADED,
                // metadataFieldsLoadedSuccess: false,
                // metadataFieldsLoadedFailure: false,

                filteredMetadataFieldsLoading: LoadingTypes.LOADED,
                // filteredMetadataFieldsLoadedSuccess: false,
                // filteredMetadataFieldsLoadedFailure: false,

                setDocumentAttributesLoading: LoadingTypes.LOADED,
                // setDocumentAttributesLoadedSuccess: false,
                // setDocumentAttributesLoadedFailure: false,

                saveDocumentMetadataCalled: false,

                saveDocumentMetadataLoading: LoadingTypes.LOADED,
                // saveDocumentMetadataLoadedSuccess: false,
                // saveDocumentMetadataLoadedFailure: false,

                documentAttributeFields: [],
                documentAttributes: [],

                candidateDocumentId: null,
                isValid: false,
                docReviewerExpirationDate: null,
                file: null,
                documentMetadataInputForSave: null,
                error: null
            })
        ),
        on(
            actions.deleteDocumentCancelled,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                deleteDocumentCalled: false,
                deleteDocumentLoading: LoadingTypes.LOADED
                // deleteDocumentLoadedSuccess: false,
                // deleteDocumentLoadedFailure: false
            })
        ),
        on(
            actions.deleteDocumentFailure,
            (state, action): VendorCandidateRequirementDocumentUploadState => ({
                ...state,
                deleteDocumentCalled: false,
                deleteDocumentLoading: LoadingTypes.FAIL
                // deleteDocumentLoadedSuccess: false,
                // deleteDocumentLoadedFailure: true
            })
        )
    )(reducerState, reducerAction);
};
