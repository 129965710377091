import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
    name: 'requestTimeOff'
})

export class RequestTimeOffPipe implements PipeTransform {
    transform(value: Date[], dateFormat: string = 'MM/DD/YYYY'): string {
        if (!value || value.length === 0) {
            return '';
        }
        const sortedDates = value.map((date) => dayjs(date)).sort((a, b) => (a.isBefore(b) ? -1 : 1));
        const result: string[] = [];
        let startIndex = 0;

        while (startIndex < sortedDates.length) {
            let endIndex = startIndex;

            // Find the end index of the consecutive date range
            while (
                endIndex < sortedDates.length - 1 &&
                this.isConsecutive(sortedDates[endIndex], sortedDates[endIndex + 1])
            ) {
                endIndex++;
            }

            if (startIndex === endIndex) {
                result.push(sortedDates[startIndex].format(dateFormat));
            } else {
                result.push(
                    `${sortedDates[startIndex].format(dateFormat)} - ${sortedDates[endIndex].format(dateFormat)}`
                );
            }

            startIndex = endIndex + 1;
        }
        return result.join(', ');
    }

    // Check if two dates are consecutive
    private isConsecutive(date1: dayjs.Dayjs, date2: dayjs.Dayjs): boolean {
        return date1.add(1, 'day').isSame(date2, 'day');
    }
}
