import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities';
import { JobSettingsResponse, JobSettingsSaveRequest } from '../models/job-settings.model';

@Injectable({
    providedIn: 'root'
})
// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
export class VendorJobPermissionService {
    private readonly coreApiPath: string;

    constructor(private readonly httpClient: HttpClient, @Inject(APP_CONFIG) private readonly settings: Settings) {
        this.coreApiPath = combine(this.settings.CORE, 'ayaconnect');
    }

    saveJobSettings(settings: JobSettingsSaveRequest): Observable<JobSettingsResponse> {
        const url = combine(this.coreApiPath, 'VendorJobs', 'SaveVendorJobSettings');
        return this.httpClient.post<JobSettingsResponse>(url, settings);
    }
}
