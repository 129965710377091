import { InjectionToken } from '@angular/core';

export interface Settings {
    CORE: string;
    V1: string;
    QM: string;
    INTRANET: string;
    AYA_HEALTHCARE: string;
    NOVA: string;
    ENABLE_FEATURE_POLLING: boolean;
    FEATURE_POLL_INTERVAL: number;
    IDLE_USER_TIMEOUT: number;
    CDN_DOMAIN: string;
    APP_NAME: string;
    DOMAIN: string;
    ACTIVATE_PENDO: boolean;
    LAUNCHDARKLY_KEY: string;
    SERVER_NAME: string;
    ENV: string;
    DATADOG_CLIENT_TOKEN: string;
    DATADOG_SERVICE: string;
    DATADOG_CLIENT_TOKENRUM: string;
    DATADOG_APPLICATIONID: string;
    AUTH_CLIENTID: string;
    AUTH_ORGANIZATIONID: string;
    AUTH_DOMAIN: string;
    AUTH_CACHE_LOCATION: 'memory' | 'localstorage';
    AUTH_CONNECTION: string;
    TEST_ENVIRONMENT: boolean;
    SHIFTS_MARKETING: string;
    FLEXWISE_URL: string;
    POLARIS_URL: string;
}

export const APP_CONFIG = new InjectionToken<Settings>('app.config');
export const VERSION_POLL_INTERVAL = new InjectionToken<number>('VERSION_POLL_INTERVAL');
