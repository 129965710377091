import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'formly-options-input',
    template: `
        <div class="form-wrapper">
            <form [formGroup]="optionsForm">
                <mat-form-field>
                    <mat-label>{{ to.label }}</mat-label>
                    <input
                        matInput
                        formControlName="option" />
                </mat-form-field>
            </form>
            <div>
                <button
                    mat-stroked-button
                    (click)="add()"
                    type="button"
                    [disabled]="(buttonEnabled$ | async) === false">
                    <mat-icon>add</mat-icon>&nbsp;Add
                </button>
            </div>
        </div>
        <div class="options-list">
            <ng-container *ngFor="let option of values; let i = index">
                <div>
                    <button
                        mat-stroked-button
                        (click)="delete(option)"
                        color="{{ hasValues ? 'accent' : 'warn' }}"
                        type="button">
                        <ng-container *ngIf="(field.props.hasValues | async) === false"
                            ><mat-icon>clear</mat-icon>&nbsp;&nbsp;</ng-container
                        ><span>{{ option || 'No Value' }}</span>
                    </button>
                </div>
            </ng-container>
        </div>
    `,
    styleUrls: ['./options-input.component.scss']
})
export class OptionsInputComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
    d$ = new Subject<void>();
    values = [];
    hasValues = false;
    buttonEnabled$ = of(false);
    optionsForm: UntypedFormGroup = new UntypedFormGroup({
        option: new UntypedFormControl('')
    });

    ngOnInit() {
        this.optionsForm.setParent(this.field.form);
        this.detectChanges();
        this.initOptions();

        this.checkFieldHasValues();
    }

    ngOnDestroy(): void {
        this.d$.next();
        this.d$.complete();
    }

    checkFieldHasValues(): void {
        this.field.props['hasValues'].pipe(takeUntil(this.d$)).subscribe((hasValues) => {
            this.hasValues = hasValues;
        });
    }

    initOptions(): void {
        if (typeof this.field.key === 'string' || typeof this.field.key === 'number') {
            this.values = this.model[this.field.key];
        }
    }

    detectChanges() {
        this.optionsForm.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => {
            if (this.optionsForm.get('option').value) {
                this.buttonEnabled$ = of(true);
            } else {
                this.buttonEnabled$ = of(false);
            }

            this.setModelOptions();
        });
    }

    add() {
        const value = this.optionsForm.get('option').value.replace(/,/g, '');
        this.values.push(value);
        this.setModelOptions();
        this.optionsForm.get('option').setValue('');
    }

    delete(value: string) {
        if (!this.hasValues || !value) {
            this.values = this.values.filter((x) => x !== value);
            this.setModelOptions();
            this.optionsForm.get('option').setValue(this.optionsForm.get('option').value);
        }
    }

    private setModelOptions() {
        if (typeof this.field.key === 'string' || typeof this.field.key === 'number') {
            this.model[this.field.key] = this.values;
        }
    }
}
