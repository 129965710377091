import { ShiftsListState, initialState } from './reducers/shifts-list.reducers';
import { ShiftCombinedHistoryState, initialCombinedHistoryState} from './reducers/shift-combined-history.reducer';
import { ShiftsLookupsState, initialLookupsState } from './reducers/shifts-lookups.reducer';
import { ShiftDetailsState, initialDetailsState } from './reducers/shift-details.reducer';
import { ClientLookupsState, initialClientLookupsState } from './reducers/client-lookup.reducer';
import { ShiftCandidatesState, initialShiftCandidatesState } from './reducers/shift-candidates.reducer';
import { BulkShiftsListState, initialBulkShiftsListState } from './reducers/bulk-shifts-list.reducers';
import { initialShiftAttachmentsState, ShiftsAttachmentsState } from './reducers/shift-attachments.reducer';
import { ApprovalState, getInitialApprovalState } from 'src/app/approvals/store/state/approval.state';
import { ApprovalDetailsState, getInitialApprovalDetailsState } from 'src/app/approvals/store/state/approval-details.state';
import { initialShiftSubmittalsState, ShiftSubmittalsState } from './reducers/shift-submittals.reducer';
import { initialShiftBookedCandidatesState, initialShiftSubmittalCandidatesState, ShiftBookedCandidatesState, ShiftSubmittalCandidatesState }
      from './reducers/shift-submittal-candidates.reducer';
import { initialShiftFacilityAddressInfoState, ShiftFacilityAddressInfoState } from './reducers/shift-facilityaddressinfo.reducer';
import { ShiftTemplatesState, initialState as initialTemplatesState } from './reducers/shift-templates.reducers';
import { ShiftTemplateDetailsState, initialState as initialTemplateDetailsState } from './reducers/shift-template-details.reducers';
import { ShiftReleaseSettingTemplateState, initialState as initialShiftReleaseSettingTemplateState } from 'src/app/shifts/store/reducers/shift-release-tiers.reducers';

export const shiftsStoreKey = 'shifts';

export interface ShiftsState {
    list: ShiftsListState,
    lookups: ShiftsLookupsState,
    details: ShiftDetailsState,
    combinedHistory: ShiftCombinedHistoryState,
    clientLookups: ClientLookupsState,
    shiftCandidates: ShiftCandidatesState,
    bulkShifts: BulkShiftsListState,
    attachments: ShiftsAttachmentsState,
    shiftApprovals: ApprovalState,
    approvalDetails: ApprovalDetailsState,
    shiftSubmittals: ShiftSubmittalsState,
    shiftSubmittalCandidates: ShiftSubmittalCandidatesState,
    shiftBookedCandidates: ShiftBookedCandidatesState,
    shiftFacilityAddressInfo: ShiftFacilityAddressInfoState,
    templates: ShiftTemplatesState,
    templateDetails: ShiftTemplateDetailsState,
    shiftReleaseSettingsTemplate: ShiftReleaseSettingTemplateState,
}

export const initialShiftsState = (): ShiftsState => ({
        details: initialDetailsState,
        list: initialState,
        lookups: initialLookupsState,
        combinedHistory: initialCombinedHistoryState,
        clientLookups: initialClientLookupsState,
        shiftCandidates: initialShiftCandidatesState,
        bulkShifts: initialBulkShiftsListState,
        shiftApprovals: getInitialApprovalState(),
        attachments: initialShiftAttachmentsState,
        approvalDetails: getInitialApprovalDetailsState(),
        shiftSubmittals: initialShiftSubmittalsState,
        shiftSubmittalCandidates: initialShiftSubmittalCandidatesState,
        shiftBookedCandidates: initialShiftBookedCandidatesState,
        shiftFacilityAddressInfo: initialShiftFacilityAddressInfoState,
        templates: initialTemplatesState,
        templateDetails: initialTemplateDetailsState,
        shiftReleaseSettingsTemplate: initialShiftReleaseSettingTemplateState,
});
