import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ToasterService, ToasterStatus } from 'src/app/core/services';
import { FileService } from '../../services/file.service';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as dayjs from 'dayjs';
import { DocumentAttributeFieldOption } from '../models/document-attribute-field-option';
import { DocumentAttributeFieldTypes } from '../enums/document-attribute-field-types';
import { DocumentAttributeField } from '../models/document-attribute-field';

@Injectable()
export class VendorCandidateRequirementDocumentUploadEffects {
    uploadRequirementFile$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.uploadRequirementFilePreview),
            exhaustMap((action) =>
                this._fileService
                    .uploadRequirementFile(
                        action.candidateOldUserId,
                        action.contractId,
                        action.docTypeId,
                        action.fileToUpload
                    )
                    .pipe(
                        map((res) => {
                            return res
                                ? actions.uploadRequirementFilePreviewSuccess({
                                      candidateDocumentId: res.candidateDocumentId
                                  })
                                : actions.uploadRequirementFilePreviewFailure({
                                      error: null
                                  });
                        }),
                        catchError((error: unknown) =>
                            of(
                                actions.uploadRequirementFilePreviewFailure({
                                    error
                                })
                            )
                        )
                    )
            )
        );
    });

    uploadRequirementFilePreviewSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.uploadRequirementFilePreviewSuccess),
            map((action) => {
                return actions.getDocumentUploaded({ candidateDocumentId: action.candidateDocumentId });
            })
        );
    });

    uploadRequirementFilePreviewFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.uploadRequirementFilePreviewFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File could not be uploaded. Please try again.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'right', verticalPosition: 'bottom' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    getDocumentUploaded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getDocumentUploaded),
            exhaustMap((action) => {
                return this._fileService.getDocumentUploadedFile(action.candidateDocumentId).pipe(
                    map((res) => {
                        return res
                            ? actions.getDocumentUploadedSuccess({ file: res })
                            : actions.getDocumentUploadedFailure({ error: res });
                    }),
                    catchError((error: any) => {
                        return of(actions.getDocumentUploadedFailure({ error }));
                    })
                );
            })
        );
    });

    getDocumentUploadedSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getDocumentUploadedSuccess),
            map((action) => {
                return actions.getMetadataForRequirementFile({ file: action.file });
            })
        );
    });

    getDocumentUploadedFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.getDocumentUploadedFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File could not be downloaded for preview after upload success. Please try again.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'right', verticalPosition: 'bottom' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    getMetadataForRequirementFile$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getMetadataForRequirementFile),
            concatLatestFrom(() => [
                this.store.select(selectors.selectCandidateDocumentId),
                this.store.select(selectors.selectDocumentUploadDocTypeId)
            ]),
            exhaustMap((action) =>
                this._fileService.getMetadataForRequirementFile(action[1], action[2]).pipe(
                    map((res) => {
                        if (res) {
                            return actions.getMetadataForRequirementFileSuccess({
                                documentRequirementAttributeFields: res,
                                documentAttributes: res.map((f) => ({
                                    fieldId: f.fieldId,
                                    fieldName: f.fieldName,
                                    fieldType: f.fieldType,
                                    selectedOption: f.selectedOption,
                                    value: f.fieldValue
                                }))
                            });
                        } else {
                            return actions.getMetadataForRequirementFileFailure({
                                error: res
                            });
                        }
                    }),
                    catchError((error: unknown) =>
                        of(
                            actions.getMetadataForRequirementFileFailure({
                                error
                            })
                        )
                    )
                )
            )
        );
    });

    getMetadataForRequirementFileFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.getMetadataForRequirementFileFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File metadata could not be downloaded. Please try again.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'right', verticalPosition: 'bottom' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    getMetadataForRequirementFileSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.getMetadataForRequirementFileSuccess),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File Attached Successfully.',
                        action,
                        ToasterStatus.success,
                        {
                            horizontalPosition: 'right',
                            verticalPosition: 'bottom'
                        }
                    )
                )
            );
        },
        { dispatch: false }
    );

    setDocumentAttributeValue$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.setDocumentAttributeValue),
            concatLatestFrom(() => [
                this.store.select(selectors.selectDocumentUploadMetadataAttributes),
                this.store.select(selectors.selectDocumentUploadDocTypeId),
                this.store.select(selectors.selectDocumentReviewerExpirationDate)
            ]),
            map((action) => {
                const idx = action[1].findIndex((da) => da.fieldName === action[0].fieldName);
                if (idx === -1) {
                    return actions.setDocumentAttributeValueEnd();
                }

                const documentAttribute = action[1][idx];
                let selectedOption: DocumentAttributeFieldOption = null;
                let value: string = null;

                if (action[0].fieldValue !== null) {
                    switch (documentAttribute.fieldType) {
                        case DocumentAttributeFieldTypes.Singleselect:
                        case DocumentAttributeFieldTypes.Multiselect:
                            selectedOption = action[0].fieldValue;
                            break;
                        case DocumentAttributeFieldTypes.Date:
                        case DocumentAttributeFieldTypes.DateCompare:
                            value = this.formatDate(action[0].fieldValue);
                            break;
                        default:
                            value = action[0].fieldValue;
                            break;
                    }
                }

                if (
                    documentAttribute.selectedOption?.fieldOptionId === selectedOption?.fieldOptionId &&
                    documentAttribute.value === value
                ) {
                    return actions.setDocumentAttributeValueEnd();
                }

                const documentAttributes = [
                    ...action[1].slice(0, idx),
                    {
                        ...documentAttribute,
                        selectedOption,
                        value
                    },
                    ...action[1].slice(idx + 1)
                ];

                return actions.getFilteredMetadataFieldsForRequirementFile({
                    documentTypeId: action[2].toString(),
                    documentAttributes,
                    fieldName: action[0].fieldName,
                    docReviewerExpirationDate: action[3]
                });
            })
        );
    });

    filterDocumentAttributeFields$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getFilteredMetadataFieldsForRequirementFile),
            concatLatestFrom(() => [
                this.store.select(selectors.selectDocumentUploadDocTypeId),
                this.store.select(selectors.selectDocumentReviewerExpirationDate)
            ]),
            switchMap((action) => {
                return this._fileService
                    .filterDocumentAttributeFields(action[0].documentTypeId, action[0].documentAttributes)
                    .pipe(
                        map((res) => {
                            this.setExpirationDate(res, action[2]);
                            if (res) {
                                const docAttributes = res.map((f) => ({
                                    fieldId: f.fieldId,
                                    fieldName: f.fieldName,
                                    fieldType: f.fieldType,
                                    selectedOption: f.selectedOption,
                                    value: f.fieldValue
                                }));
                                return actions.getFilteredMetadataForRequirementFileSuccess({
                                    documentRequirementAttributeFields: res,
                                    documentAttributes: docAttributes
                                });
                            } else {
                                return actions.getFilteredMetadataForRequirementFileFailure({
                                    error: res
                                });
                            }
                        }),
                        catchError((error: unknown) =>
                            of(
                                actions.getFilteredMetadataForRequirementFileFailure({
                                    error
                                })
                            )
                        )
                    );
            })
        );
    });

    saveDocumentMetadataCalledProcess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.saveDocumentMetadata),
            concatLatestFrom(() => [
                this.store.select(selectors.selectCandidateDocumentId),
                this.store.select(selectors.selectDocumentUploadDocTypeId),
                this.store.select(selectors.selectDocumentUploadContractId),
                this.store.select(selectors.selectDocumentUploadMetadataFields)
            ]),
            switchMap((action) => {
                return this._fileService
                    .saveDocumentMetadata(
                        action[0].documentMetadataInputForSave,
                        action[1],
                        action[2],
                        action[3],
                        action[4]
                    )
                    .pipe(
                        map((res) => {
                            if (res) {
                                return actions.saveDocumentMetadataSuccess();
                            } else {
                                return actions.saveDocumentMetadataFailure({
                                    error: res
                                });
                            }
                        }),
                        catchError((error: unknown) => {
                            return of(
                                actions.saveDocumentMetadataFailure({
                                    error
                                })
                            );
                        })
                    );
            })
        );
    });

    saveDocumentMetadataSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveDocumentMetadataSuccess),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss('File data saved.', action, ToasterStatus.success, {
                        horizontalPosition: 'right',
                        verticalPosition: 'bottom'
                    })
                )
            );
        },
        { dispatch: false }
    );

    saveDocumentMetadataFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveDocumentMetadataFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File could not be deleted. Please try again.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'right', verticalPosition: 'bottom' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    deleteDocumentCalled$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.deleteDocumentCalled),
            concatLatestFrom(() => [
                this.store.select(selectors.selectCandidateDocumentId),
                this.store.select(selectors.selectDeleteDocumentCalledWithNavigation)
            ]),
            switchMap((action) => {
                return this._fileService.deleteDocument(action[1]).pipe(
                    map((res) => {
                        if (res) {
                            // Called with navigation back
                            return actions.deleteDocumentSuccess({
                                deleteDocumentCalledWithNavigation: action[0].deleteDocumentCalledWithNavigation
                            });
                        } else {
                            return actions.deleteDocumentFailure({
                                error: res
                            });
                        }
                    }),
                    catchError((error: unknown) => {
                        return of(
                            actions.deleteDocumentFailure({
                                error
                            })
                        );
                    })
                );
            })
        );
    });

    deleteDocumentSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.deleteDocumentSuccess),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss('File deleted.', action, ToasterStatus.success, {
                        horizontalPosition: 'right',
                        verticalPosition: 'bottom'
                    })
                ),
                concatLatestFrom(() => [this.store.select(selectors.selectCandidateId)])
            );
        },
        { dispatch: false }
    );

    deleteDocumentFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.deleteDocumentFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File could not be deleted. Please try again.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'right', verticalPosition: 'bottom' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly store: Store,
        private readonly _fileService: FileService,
        private readonly _toasterService: ToasterService
    ) {}

    private readonly formatDate = (value: any) => (value ? dayjs(value).format('YYYY-MM-DD') : null);

    private readonly setExpirationDate = (fields: DocumentAttributeField[], docReviewerExpirationDate: string) => {
        const expirationDateField = fields.find((f) => f.fieldName === 'expirationDate');
        if (expirationDateField) {
            expirationDateField.fieldValue = expirationDateField.fieldValue ?? docReviewerExpirationDate;
        }
    };
}
