import { createAction, props } from '@ngrx/store';
import { ClientUnit } from 'src/app/clinical/models/client-unit.model';
import { LookupUnitItem } from 'src/app/clinical/models/lookup-unit-item-model';
import { ExpertiseLookup } from 'src/app/jobs/models';
import { StateLookup } from 'src/app/jobs/models/lookups/state-lookup';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { NyuLookup } from '../../models/nyu-lookup.model';
import { SaveUnitEnum } from '../../models/save-unit.enum';
import { UnitBoardCertification } from '../../models/unit-board-certification.model';
import { UnitFacilityInformation } from '../../models/unit-facility-information.model';
import { UnitOtherRequirement } from '../../models/unit-other-requirement.model';
import { UnitStateLicense } from '../../models/unit-state-license.model';
import { Unit } from '../../models/unit.model';
import { UnitStateControlledSubstances } from 'src/app/clinical/models/unit-state-controlled-substances';

export const setClientUnitsSearchQuery = createAction(
    '[CLIENT UNIT COMPONENT] Set Client Units Search Query',
    props<{ gridSearchQuery: GridSearchQuery }>()
);

export const setFacilityId = createAction(
    '[CLIENT UNIT COMPONENT] Set Client Units FacilityId',
    props<{ facilityId: number }>()
);

export const loadClientUnits = createAction('[CLIENT UNIT COMPONENT] Load Client Units Grid');
export const loadClientUnitsSuccess = createAction(
    '[CLIENT UNIT API] Load Client Units Grid Successfully',
    props<{ clientUnits: PagingToken<ClientUnit[]> }>()
);
export const loadClientUnitsFailure = createAction(
    '[CLIENT UNIT API] Load Client Units Grid Failure',
    props<{ error: unknown }>()
);

export const updateUnitStatus = createAction(
    '[CLIENT UNIT COMPONENT] Update Unit Status',
    props<{ unitId: number; status: number }>()
);

export const updateUnitStatusSuccess = createAction('[CLIENT UNIT API] Update Unit Status Successfully');
export const updateUnitStatusFailure = createAction(
    '[CLIENT UNIT API] Update Unit Status Failure',
    props<{ error: unknown }>()
);

export const deleteUnit = createAction('[CLIENT UNIT COMPONENT] Delete Unit Status', props<{ unitId: number }>());

export const deleteUnitSuccess = createAction('[CLIENT UNIT API] Delete Unit Status Successfully');
export const deleteUnitFailure = createAction(
    '[CLIENT UNIT API] Delete Unit Status Failure',
    props<{ error: unknown }>()
);

export const loadNyuFloors = createAction(
    '[FACILITY INFORMATION COMPONENT] Load NyuFloors',
    props<{ buildingId: number }>()
);

export const loadNyuFloorsSuccess = createAction(
    '[FACILITY INFORMATION API] Load NyuFloors Info Successfully',
    props<{ nyuFloors: NyuLookup[] }>()
);
export const loadNyuFloorsFailure = createAction(
    '[FACILITY INFORMATION API] Load NyuFloors Failure',
    props<{ error: unknown }>()
);

export const loadNyuDepartments = createAction('[FACILITY INFORMATION COMPONENT] Load NyuDepartments');
export const loadNyuDepartmentsSuccess = createAction(
    '[FACILITY INFORMATION API] Load NyuDepartments InfoSuccessfully',
    props<{ nyuDepartments: NyuLookup[] }>()
);
export const loadNyuDepartmentsFailure = createAction(
    '[FACILITY INFORMATION API] Load NyuDepartments Failure',
    props<{ error: unknown }>()
);

export const loadNyuBuildings = createAction('[FACILITY INFORMATION COMPONENT] Load NyuBuildings');
export const loadNyuBuildingsSuccess = createAction(
    '[FACILITY INFORMATION API] Load NyuBuildings Successfully',
    props<{ nyuBuildings: NyuLookup[] }>()
);
export const loadNyuBuildingsFailure = createAction(
    '[FACILITY INFORMATION API] Load NyuBuildings Failure',
    props<{ error: unknown }>()
);

export const loadBoardCertificationsComponent = createAction(
    '[BOARD CERTIFICATIONS COMPONENT] Loaded',
    props<{ unitId: string }>()
);

export const loadPhysicianSpecialtiesSuccess = createAction(
    '[UNIT DESCRIPTION  API] Load PhysicianSpecialties Successfully',
    props<{ physicianSpecialties: ExpertiseLookup[] }>()
);
export const loadPhysicianSpecialtiesFailure = createAction(
    '[UNIT DESCRIPTION  API] Load PhysicianSpecialties Failure',
    props<{ error: unknown }>()
);

export const loadUnitBoardCertificationsSuccess = createAction(
    '[UNIT DESCRIPTION  API] Load UnitBoardCertifications Successfully',
    props<{ boardCertifications: UnitBoardCertification[] }>()
);
export const loadUnitBoardCertificationsFailure = createAction(
    '[UNIT DESCRIPTION  API] Load UnitBoardCertifications Failure',
    props<{ error: unknown }>()
);

export const loadStateLicensesComponent = createAction(
    '[STATE LICENSES COMPONENT] Loaded',
    props<{ unitId: string }>()
);

export const loadStateControlledSubstancesComponent = createAction(
    '[STATE CONTROLLED SUBSTANCES COMPONENT] Loaded',
    props<{ unitId: string }>()
);

export const loadStatesSuccess = createAction(
    '[UNIT DESCRIPTION  API] Load States Successfully',
    props<{ states: StateLookup[] }>()
);
export const loadStatesFailure = createAction(
    '[UNIT DESCRIPTION  API] Load States Failure',
    props<{ error: unknown }>()
);

export const loadUnitStateLicensesSuccess = createAction(
    '[UNIT DESCRIPTION  API] Load StateLicenses Successfully',
    props<{ stateLicenses: UnitStateLicense[] }>()
);
export const loadUnitStateLicensesFailure = createAction(
    '[UNIT DESCRIPTION  API] Load StateLicenses Failure',
    props<{ error: unknown }>()
);

export const loadUnitStateControlledSubstancesSuccess = createAction(
    '[UNIT DESCRIPTION  API] Load StateControlledSubstances Successfully',
    props<{ stateControlledSubstances: UnitStateControlledSubstances[] }>()
);
export const loadUnitStateControlledSubstancesFailure = createAction(
    '[UNIT DESCRIPTION  API] Load StateControlledSubstances Failure',
    props<{ error: unknown }>()
);

export const loadUnitInfo = createAction(
    '[UNIT DESCRIPTION COMPONENT] Load UnitInfo',
    props<{ unitId: string; hospId: string; token: string; formId: string }>()
);
export const loadUnitInfoSuccess = createAction(
    '[UNIT DESCRIPTION  API] Load UnitInfo Successfully',
    props<{ unitInfo: UnitFacilityInformation }>()
);
export const loadUnitInfoFailure = createAction(
    '[UNIT DESCRIPTION  API] Load UnitInfo Failure',
    props<{ error: unknown }>()
);

export const saveUnitExtraFields = createAction(
    '[UNIT DESCRIPTION  COMPONENT] Save Unit ExtraFields',
    props<{
        unitId: number;
        departmentId: number;
        buildingId: number;
        floorId: number;
        isAccessSecureUDLinksEnabled: boolean;
        isEditMode: boolean;
    }>()
);

export const saveUnitExtraFieldsSuccess = createAction(
    '[UNIT DESCRIPTION  API] Save Unit ExtraFields Successfully',
    props<{ unit: Unit; saveUnitEnum: SaveUnitEnum; isAccessSecureUDLinksEnabled: boolean; isEditMode: boolean }>()
);
export const saveUnitExtraFieldsFailure = createAction(
    '[UNIT DESCRIPTION  API] Save Unit ExtraFields Failure',
    props<{ error: unknown }>()
);

export const saveUnit = createAction(
    '[UNIT DESCRIPTION  COMPONENT] Save Unit',
    props<{
        unit: Unit;
        saveUnitEnum: SaveUnitEnum;
        shouldSaveExtraFeilds: boolean;
        isAccessSecureUDLinksEnabled: boolean;
        isEditMode: boolean;
    }>()
);

export const saveUnitSuccess = createAction(
    '[UNIT DESCRIPTION  API] Save Unit Successfully',
    props<{
        unit: Unit;
        saveUnitEnum: SaveUnitEnum;
        shouldSaveExtraFeilds: boolean;
        isAccessSecureUDLinksEnabled: boolean;
        isEditMode: boolean;
    }>()
);

export const saveUnitFailure = createAction('[UNIT DESCRIPTION  API] Save Failure', props<{ error: unknown }>());

export const loadOtherRequirementComponent = createAction(
    '[OTHER REQUIREMENTS COMPONENT] Loaded',
    props<{ unitId: string }>()
);

export const loadOtherRequirementsSuccess = createAction(
    '[UNIT DESCRIPTION  API] Load OtherRequirements Successfully',
    props<{ otherRequirements: LookupUnitItem[] }>()
);
export const loadOtherRequirementsFailure = createAction(
    '[UNIT DESCRIPTION  API] Load OtherRequirements Failure',
    props<{ error: unknown }>()
);

export const loadUnitOtherRequirementsSuccess = createAction(
    '[UNIT DESCRIPTION  API] Load UnitOtherRequirements Successfully',
    props<{ unitOtherRequirements: UnitOtherRequirement[] }>()
);
export const loadUnitOtherRequirementsFailure = createAction(
    '[UNIT DESCRIPTION  API] Load UnitOtherRequirements Failure',
    props<{ error: unknown }>()
);
