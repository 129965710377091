import { createAction, props } from '@ngrx/store';
import { ListItem } from 'src/app/shared/models/list-item';
import { ShiftListItem } from 'src/app/shifts/models/shift-list-item.model';
import { ShiftList } from 'src/app/vendor-perdiemscheduler/models/shift-list.model';
import { PerdiemSchedulerLocation } from '../models/enum/perdiemscheduler-location.enum';
import { PerdiemCandidate } from '../models/perdiem-candidate.model';
import { PerdiemSchedulerQueryState } from './reducers/perdiemscheduler-query.reducer';

export const componentLoaded = createAction(
    '[VENDOR PERDIEMSCHEDULER COMPONENT] Component Loaded',
    props<{ professionId: number; candidateId?: number }>()
);

export const loadShifts = createAction('[VENDOR PERDIEMSCHEDULER API] Load Shifts');

export const loadShiftsSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Shifts success',
    props<{ shifts: ShiftListItem[]; total: number }>()
);

export const loadShiftsFail = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Shifts fail',
    props<{ error: unknown }>()
);

export const reloadShifts = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Reload Shifts',
    props<{ location: PerdiemSchedulerLocation; city: string; state: string; facilityId: number }>()
);

export const setPerdiemSchedulerSearchQuery = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Set Shifts search query',
    props<{ query: PerdiemSchedulerQueryState }>()
);

export const loadProfessions = createAction('[VENDOR PERDIEMSCHEDULER] Load Professions');

export const loadProfessionsSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Professions success',
    props<{ professions: ListItem[] }>()
);

export const loadProfessionsFail = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Professions fail',
    props<{ error: unknown }>()
);

export const loadSpecialties = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Specialties',
    props<{ professionId: number }>()
);

export const loadSpecialtiesSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Specialties success',
    props<{ specialties: ListItem[] }>()
);

export const loadSpecialtiesFail = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Specialties failure',
    props<{ error: unknown }>()
);

export const loadFacilities = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Facilities',
    props<{ candidateId?: number }>()
);

export const loadFacilitiesSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Facilities success',
    props<{ facilities: ListItem[] }>()
);

export const loadFacilitiesFail = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Facilities fail',
    props<{ error: unknown }>()
);

export const loadStates = createAction('[VENDOR PERDIEMSCHEDULER API] Load States');

export const loadStatesSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load States success',
    props<{ states: ListItem[] }>()
);

export const loadStatesFail = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load States fail',
    props<{ error: unknown }>()
);

export const setSelectedShifts = createAction(
    '[VENDOR PERDIEMSCHEDULER COMPONENT] Set Selected Shifts',
    props<{ shifts: ShiftListItem[] }>()
);

export const loadCandidates = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Candidates',
    props<{
        professionId: number;
        specialtyId: number;
        facilityId: number;
        seriesId: number;
        query: { take: number; skip: number; search: string };
    }>()
);

export const loadCandidatesSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Candidates success',
    props<{ candidates: PerdiemCandidate[]; filteredCandidates: PerdiemCandidate[]; total: number }>()
);

export const loadCandidatesFail = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Candidates fail',
    props<{ error: unknown }>()
);

export const loadFilteredCandidates = createAction(
    '[VENDOR PERDIEMSCHEDULER COMPONENT] Load Filtered Candidates',
    props<{ take: number; skip: number; search: string }>()
);

export const bookCandidate = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Book Candidate',
    props<{
        dialogId: string;
        candidateId: number;
        shiftId: number;
        seriesId: number;
    }>()
);

export const bookCandidateSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Book Candidate success',
    props<{ shift: ShiftList }>()
);

export const bookCandidateFail = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Book Candidate fail',
    props<{ error: unknown }>()
);

export const loadCancelReasons = createAction('[VENDOR PERDIEMSCHEDULER API] Load Cancel Reasons');

export const loadCancelReasonsSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Cancel Reasons success',
    props<{ cancelReasons: string[] }>()
);

export const loadCancelReasonsFail = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Cancel Reasons fail',
    props<{ error: unknown }>()
);

export const removeCandidate = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Remove Candidate',
    props<{
        dialogId: string;
        cancelReason: string;
        shiftId: number;
        seriesId: number;
    }>()
);

export const removeCandidateSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Remove Candidate success',
    props<{ shift: ShiftList }>()
);

export const removeCandidateFail = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Remove Candidate fail',
    props<{ error: unknown }>()
);

export const loadDefaultDateFrom = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Default DateFrom'
);

export const loadDefaultDateFromSuccess = createAction(
    '[VENDOR PERDIEMSCHEDULER API] Load Default DateFrom success',
    props<{ minDate: Date }>()
);
