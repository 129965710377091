import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AcceptTermsAndConditions } from 'src/app/core/state/core.actions';
import { electronicConsent, termsOfUse } from 'src/app/portal/external-access/external-access.const';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IdentityService } from 'src/app/shared/services/identity.service';

@Component({
    selector: 'aya-terms-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
    constructor(
        private readonly _store: Store,
        private readonly _dialogService: DialogService,
        private readonly _identityService: IdentityService,
        @Inject('Window') private readonly _window: Window
    ) {}

    decline(): void {
        this._identityService.signOut();
    }

    showTermsOfService(): void {
        this._dialogService.openInformationDialog({
            data: {
                title: 'Terms of Service',
                text: termsOfUse,
                isHtml: true
            },
            height: '80%'
        });
    }

    showElectronicConsentAgreement(): void {
        this._dialogService.openInformationDialog({
            data: {
                title: 'Electronic Consent Agreement',
                text: electronicConsent,
                isHtml: true
            },
            height: '80%'
        });
    }

    acceptTerms(): void {
        this._store.dispatch(new AcceptTermsAndConditions());
    }
}
