import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, ViewChild } from '@angular/core';
import { FileService } from '../../services/file.service';
import { Candidate, ContractRequirementDocument, ContractRequirements } from 'src/app/shared/models/candidate';
import { Observable, map, takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { Store } from '@ngrx/store';
import { VendorRequirementDocumentUploadInfo } from '../../store/models/vendor-requirement-document-upload-info';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { CandidateService } from 'src/app/shared/services/candidate.service';
import { DocumentAttributesFormComponent } from '../document-attributes-form/document-attributes-form.component';

@Component({
    selector: 'ayac-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentUploadComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild(DocumentAttributesFormComponent, { static: true }) formComponent: DocumentAttributesFormComponent;
    candidate: Candidate;
    validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.tiff', '.pdf', '.doc', '.docx', '.xls', '.xlsx'];
    validExtensionsString = this.validExtensions.join(', ');
    validFileSize = 20;
    isLoading = true;
    isDocumentPreviewUploadLoading$: Observable<boolean>;
    docTypeId = -999;
    displayedColumns: string[] = ['name', 'lastModifiedDate', 'uploadedBy', 'actions'];
    dataSource = new MatTableDataSource<VendorRequirementDocumentUploadInfo>();
    hasChanges = false;
    isFormValid = true;
    contractRequirements$: Observable<ContractRequirements>;
    contractRequirementDocuments: ContractRequirementDocument[];
    selectedDocument: ContractRequirementDocument;
    contractId = -999;
    contractInfoInputString = '';
    areMetadataFieldsLoaded$: Observable<boolean>;
    documentPreviewBlobSrc$: Observable<Blob>;
    isMetadataFormValid$: Observable<boolean>;
    candidateId = -999;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _fileService: FileService,
        private readonly store: Store,
        private readonly _cd: ChangeDetectorRef,
        private readonly _identityService: IdentityService,
        private readonly _dialog: DialogService,
        private readonly _candidateService: CandidateService
    ) {
        super();
    }

    get isAttachmentDataEmpty() {
        return this.dataSource ? this.dataSource.data.length === 0 : null;
    }

    get userLoggedInName() {
        return this._identityService.userFullName;
    }

    @HostListener('window:beforeunload', ['$event']) onRefresh(event: Event) {
        if (this.hasChanges) {
            // Delete the document, the user clicked refresh or back in the browser.
            this.store.dispatch(actions.deleteDocumentCalled({ deleteDocumentCalledWithNavigation: false }));

            // Recommended
            event.preventDefault();

            // Included for legacy support, e.g. Chrome/Edge < 119
            event.returnValue = true;
        }
    }

    ngOnInit(): void {
        this.isDocumentPreviewUploadLoading$ = this.store.select(selectors.selectDocumentUploadPreviewLoading);
        this.areMetadataFieldsLoaded$ = this.store.select(selectors.selectAreMetadataFieldsLoaded);
        this.documentPreviewBlobSrc$ = this.store.select(selectors.selectCandidateDocumentPreviewFile);
        this.isMetadataFormValid$ = this.store.select(selectors.selectIsMetadataFormValid);

        this._route.params
            .pipe(
                map((params: Params) => params.id),
                takeUntil(this.d$)
            )
            .subscribe((candidateId: string) => {
                this.candidateId = +candidateId;
                this._cd.detectChanges();
            });

        //* We have to use three subscription blocks here, otherwise they never complete and we get errors, the loading indicator never disappears
        this._route.queryParams
            .pipe(
                map((params: Params) => params.docType),
                takeUntil(this.d$)
            )
            .subscribe((docType: string) => {
                this.docTypeId = +docType;
                this._cd.detectChanges();
            });

        this.store
            .select(selectors.selectContractRequirements)
            .pipe(takeUntil(this.d$))
            .subscribe((contractRequirements) => {
                if (
                    contractRequirements?.contractRequirements &&
                    contractRequirements?.contractRequirements.length > 0
                ) {
                    this.selectedDocument = contractRequirements.contractRequirements
                        .flatMap((cr) => cr.documents)
                        .find((doc) => doc.documentTypeId === this.docTypeId);

                    this.contractInfoInputString =
                        contractRequirements.contractRequirements[0].contractStage +
                        ' ' +
                        contractRequirements.contractRequirements[0].facility +
                        ' ' +
                        contractRequirements.contractRequirements[0].from +
                        ' ' +
                        contractRequirements.contractRequirements[0].to;

                    this.contractId = this.selectedDocument.contractId;
                    this._cd.detectChanges();
                } else {
                    this.store.dispatch(actions.getContractRequirements({ candidateId: this.candidateId }));
                }
            });

        this._fileService.candidate$.pipe(takeUntil(this.d$)).subscribe((candidate) => {
            if (candidate) {
                this.candidate = candidate;
                this.isLoading = false;
                this.store.dispatch(actions.setCandidateForDocumentUpload({ candidate }));
                this._cd.detectChanges();
            } else {
                // TODO: Convert and move the below method to NgRx Store
                this.getCandidatePageRefresh();
            }
        });

        this.store
            .select(selectors.selectSaveDocumentMetadataLoadedSuccess)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                if (result) {
                    this.hasChanges = false;
                    this._cd.detectChanges();
                    this._router.navigateByUrl(`/vendor/candidate/${this.candidate.id}`);
                }
            });

        this.store
            .select(selectors.selectDocumentDeletedSuccess)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                if (result) {
                    this.hasChanges = false;
                    this.dataSource = new MatTableDataSource<VendorRequirementDocumentUploadInfo>();
                    this._cd.detectChanges();
                }
            });

        this.store
            .select(selectors.selectDeleteDocumentCalledWithNavigationSuccess)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                if (result) {
                    this.hasChanges = false;
                    this._cd.detectChanges();
                    this._router.navigateByUrl(`/vendor/candidate/${this.candidate.id}`);
                }
            });
    }

    getCandidatePageRefresh() {
        this._candidateService
            .getVendorCandidate(this.candidateId)
            .pipe(takeUntil(this.d$))
            .subscribe((candidate) => {
                this.candidate = candidate;
                this.isLoading = false;
                this.store.dispatch(actions.setCandidateForDocumentUpload({ candidate }));
                this._cd.detectChanges();
            });
    }

    attachmentUpload(file) {
        if (file) {
            const data: VendorRequirementDocumentUploadInfo[] = [
                {
                    uploadedBy: this.userLoggedInName,
                    lastModifiedDate: file.lastModifiedDate,
                    name: file.name
                }
            ];
            this.dataSource.data = data;

            this.store.dispatch(
                actions.uploadRequirementFilePreview({
                    docTypeId: this.docTypeId,
                    fileToUpload: file,
                    contractId: this.contractId,
                    candidateId: this.candidate.id,
                    candidateOldUserId: this.candidate.oldUserId
                })
            );

            this.hasChanges = true;
            this._cd.detectChanges();
        }
    }

    deleteAttachment() {
        this._dialog
            .openConfirmationDialog({
                data: {
                    title: 'Candidate Details',
                    text: 'Are you sure you want to delete the document?',
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel'
                }
            })
            .then((result) => {
                if (result) {
                    this.store.dispatch(actions.deleteDocumentCalled({ deleteDocumentCalledWithNavigation: false }));
                    this._cd.detectChanges();
                } else {
                    this.store.dispatch(actions.deleteDocumentCancelled());
                }
            });
    }

    back(): void {
        if (this.hasChanges) {
            this._dialog
                .openConfirmationDialog({
                    data: {
                        title: 'Delete Attachment',
                        text: 'You have unsaved changes! Do you want to leave?',
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel'
                    }
                })
                .then((result) => {
                    if (result) {
                        this.store.dispatch(actions.deleteDocumentCalled({ deleteDocumentCalledWithNavigation: true }));
                    } else {
                        this.store.dispatch(actions.deleteDocumentCancelled());
                    }
                    return result;
                });
        } else {
            this._router.navigateByUrl(`/vendor/candidate/${this.candidate.id}`);
        }
    }

    save(): void {
        this.store.dispatch(
            actions.saveDocumentMetadata({ documentMetadataInputForSave: this.formComponent.form.value })
        );
    }

    canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
        return this.hasChanges && !this.isLoading ? this._openChangedNotSavedDialog() : true;
    }

    private _openChangedNotSavedDialog(): boolean | Promise<boolean> | Observable<boolean> {
        return this._dialog
            .openConfirmationDialog({
                data: {
                    title: 'Candidate Details',
                    text: 'You have unsaved changes! Do you want to leave?',
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel'
                }
            })
            .then((result) => {
                return result;
            });
    }
}
