export enum AyaKiddingMeFeatureFlags {
    ConnectVendorNewSubmittalStatus = 'CONNECT_VENDOR_DISPLAY_NEW_SUBMITTAL_STATUS',
    ConnectVendorSubmittalDetailsHideAcceptDecline = 'CONNECT_VENDOR_SUBMITTAL_DETAILS_HIDE_ACCEPT_DECLINE',
    ConnectVendorCancelSubmittalVisibility = 'CONNECT_VENDOR_CANCEL_SUBMITTAL_VISIBILITY',
    ConnectClientSubmittalEnableActivity = 'CONNECT_CLIENT_SUBMITTAL_ENABLE_ACTIVITY',
    StoreClientSubmittalNotesInASeparateTable = 'STORE_CLIENT_SUBMITTAL_NOTES_IN_A_SEPARATE_TABLE',
    ConnectResetPasswordEspFinding = 'CONNECT_RESET_PASSWORD_ESP_FINDING',
    ConnectClientMessageCenter = 'CONNECT_CLIENT_MESSAGE_CENTER',
    ConnectReportingRedesign = 'CONNECT_CLIENT_REPORTING_REDESIGN',
    ConnectClientDeclineFlowNoteValidation = 'CONNECT_CLIENT_DECLINE_FLOW_NOTE_VALIDATION',
    ConnectClientMoreInformationNeededEnabled = 'CONNECT_CLIENT_MORE_INFORMATION_NEEDED_ENABLED',
    ConnectAdminUpdateColors = 'CONNECT_ADMIN_UPDATE_COLORS'
}
