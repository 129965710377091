export class DateHelper {
    static dayNumber(d: string | Date): number {
        const date = d instanceof Date ? (d as Date) : new Date(d);
        const time = date.getTime();
        const timeZoneOffset = date.getTimezoneOffset() / 60 / 24;
        return Math.floor(time / 1000 / 60 / 60 / 24 - timeZoneOffset);
    }

    static diffDay(d1: string | Date, d2: string | Date): number {
        return DateHelper.dayNumber(d2) - DateHelper.dayNumber(d1);
    }

    static isSameDay(d1: string | Date, d2: string | Date): boolean {
        return DateHelper.dayNumber(d1) === DateHelper.dayNumber(d2);
    }

    static isAfterDay(d1: string | Date, d2: string | Date): boolean {
        return DateHelper.dayNumber(d1) > DateHelper.dayNumber(d2);
    }

    static isBeforeDay(d1: string | Date, d2: string | Date): boolean {
        return DateHelper.dayNumber(d1) < DateHelper.dayNumber(d2);
    }

    static inRangeDay(d: string | Date, start: string | Date, end: string | Date) {
        return (
            DateHelper.dayNumber(d) >= DateHelper.dayNumber(start) &&
            DateHelper.dayNumber(d) <= DateHelper.dayNumber(end)
        );
    }

    static inWorkday(date: string | Date, workdays: number[] = []): boolean {
        const d = new Date(date);
        return workdays.includes(d.getDay());
    }

    static isValidDate(d: Date) {
        return d instanceof Date && !isNaN(d.getTime());
    }

    static getUTCDate(date: string | Date): Date {
        date = date instanceof Date ? date : new Date(date);

        const utcDate = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        );

        return new Date(utcDate);
    }
    /**
     * It will format Date without applied timezone offset.
     * Example: Thu Jan 18 2024 10:15:51 GMT-0800 (Pacific Standard Time)
     * format result will be '2024-01-18T10:14:51Z'
     */
    static dateFormat(date: string | Date): string {
        date = date instanceof Date ? date : new Date(date);
        const year = date.getFullYear(),
            month = (date.getMonth() + 1).toString().padStart(2, '0'),
            day = date.getDate().toString().padStart(2, '0'),
            hours = date.getHours().toString().padStart(2, '0'),
            minutes = date.getMinutes().toString().padStart(2, '0'),
            seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    }

    static dateFormatUTC(date: string | Date): string {
        date = date instanceof Date ? date : new Date(date);

        return date.toISOString().substr(0, 19);
    }

    static adjustForTimezone(date: string | Date): Date {
        date = date instanceof Date ? date : new Date(date);
        var offset = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + offset);

        return date;
    }

    static getDatesRange(start: Date, end: Date): Date[] {
        const dates = [];
        if (!start && !end) {
            return dates;
        }
        start = new Date(start);
        end = new Date(end);
        const current = start;

        while (DateHelper.isBeforeDay(current, end) || DateHelper.isSameDay(current, end)) {
            dates.push(new Date(current));
            current.setDate(current.getDate() + 1);
        }

        return dates;
    }

    static isOverHoursAwayFromDate(date: Date, hours: number): boolean {
        const now = new Date();
        now.setTime(now.getTime() + hours * 60 * 60 * 1000);
        return date > now;
    }

    static timeFormatToHHmm(date: string | Date): string {
        date = date instanceof Date ? date : new Date(date);
        const hours = date.getHours().toString().padStart(2, '0'),
            minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }
}
