import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnitDescriptionEmail } from 'src/app/clinical/models';
import { ClinicalService } from 'src/app/clinical/services/clinical.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { emailValidator } from 'src/app/shared/utilities';

@Component({
    selector: 'app-clinical-unit-description-send-link-modal',
    templateUrl: './send-link-modal.component.html',
    styleUrls: ['./send-link-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SendLinkModalComponent implements OnInit {
    sendLinkForm: UntypedFormGroup;
    linkGenerated = false;
    generatedLink = '';
    expiredLink = false;
    expirationDate: string;
    oldLink = false;

    constructor(
        private readonly dialogRef: MatDialogRef<SendLinkModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly fb: UntypedFormBuilder,
        private readonly _clinicalService: ClinicalService,
        protected readonly toasterService: ToasterService
    ) {}

    ngOnInit(): void {
        this.sendLinkForm = this.fb.group({
            email: ['', [Validators.required, emailValidator]]
        });

        if (this.data.expiredLink !== undefined) {
            this.expiredLink = this.data.expiredLink;
        }
        if (this.data.oldLink !== undefined) {
            this.oldLink = this.data.oldLink;
        }
        this.sendLinkForm.get('email').valueChanges.subscribe((value) => {
            if (this.sendLinkForm.valid) {
                this.generateLink();
            } else {
                this.linkGenerated = false;
                this.generatedLink = '';
            }
        });
    }

    generateLink(): void {
        if (this.sendLinkForm.invalid) {
            this.linkGenerated = false;
            this.generatedLink = '';
            return;
        }
        try {
            const { id, token } = this.data;
            const hostName = `${window.location.protocol}//${window.location.host}/#/`;
            this.generatedLink = `${hostName}units?formId=${id}&contact=1&token=${token}`;
            this.linkGenerated = true;
            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 14);
            this.expirationDate = formatDate(expiration, 'MMMM d, y', 'en-US');
        } catch (error) {
            this.toasterService.fail('Failed to generate link.');
        }
    }

    sendEmail(): void {
        if (this.sendLinkForm.invalid || !this.linkGenerated) {
            return;
        }
        this.dialogRef.close({
            email: this.sendLinkForm.get('email').value,
            link: this.generatedLink,
            expirationDate: this.expirationDate
        });
    }

    copyLink(): void {
        navigator.clipboard
            .writeText(this.generatedLink)
            .then(() => {
                this.toasterService.success('Link copied.');
                const { token } = this.data;
                this.updateUnitUserEmail(this.sendLinkForm.get('email').value, token);
            })
            .catch(() => {
                this.toasterService.fail('Failed to copy link.');
            });
    }

    requestNewLink(): void {
        if (this.sendLinkForm.invalid) {
            return;
        }

        const formData = {
            email: this.sendLinkForm.get('email').value,
            link: this.generatedLink,
            expirationDate: this.expirationDate,
            oldLink: this.oldLink
        };
        this.dialogRef.close(formData);
    }

    public updateUnitUserEmail(email: string, token: string): void {
        const request: UnitDescriptionEmail = {
            emailFor: email,
            token,
            generatedLink: '',
            subject: '',
            oldLink: false
        };

        this._clinicalService.updateUserEmail(request).subscribe({
            next: () => {}
        });
    }

    close(): void {
        this.dialogRef.close();
    }
}
