import { Component, Input } from '@angular/core';
import { ClinicalClient } from 'src/app/clinical/models/client.model';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-clinical-client-profile',
    templateUrl: './client-profile.component.html',
    styleUrls: ['./client-profile.component.scss']
})
export class ClinicalClientProfileComponent extends UnsubscribeOnDestroy {
    loaded = false;
    private _client: ClinicalClient;
    constructor() {
        super();
    }

    get client(): ClinicalClient {
        return this._client;
    }

    @Input() set client(value: ClinicalClient) {
        this._client = value;
        if (value && !this.loaded) {
            this.loaded = true;
        }
    }
}
