import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { DatepickerMultipleComponent } from 'src/app/shared/components/datepicker-multiple/datepicker-multiple.component';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { RequestTimeOffPipe } from 'src/app/shared/pipes/request-time-off.pipe';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { Store } from '@ngrx/store';
import { loadShiftTypes } from 'src/app/lookups/state/actions/shift-types.actions';
import { ListItem } from 'src/app/shared/models/list-item';
import * as LookupsSelectors from 'src/app/lookups/state/lookups.selectors';
import { updateSubmittalPreferences } from '../store/actions/submittals-vendor-details.actions';

@Component({
    selector: 'aya-submittal-rto-dialog',
    templateUrl: './submittal-rto-dialog.component.html',
    styleUrls: ['./submittal-rto-dialog.component.scss'],
    providers: [RequestTimeOffPipe]
})
export class SubmittalRtoDialogComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    submittalId: number;
    newSubmittal: boolean;
    title: string;
    form: FormGroup;
    minDate: Date = new Date();
    requestTimeOffDatesFormat = (date: Date[], viewValue: string) => {
        return this.requestTimeOffDateFormat.transform(date, 'MM/DD/YYYY');
    };

    featureFlag = FeatureFlag;
    availableDatePreferredShiftFlag = false;
    shiftTypes$: Observable<ListItem[]>;
    private readonly _destroy$ = new Subject();

    @ViewChild('rtoDatepicker') rtoDatepicker: DatepickerMultipleComponent;
    @ViewChild('availableDatepicker') availableDatepicker: DatepickerMultipleComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private readonly _data: {
            submittalId: number;
            newSubmittal: boolean;
            title: string;
            rtoNeeded: boolean;
            requestTimeOffDates: Date[];
            availableStartDate?: Date[];
            preferredShiftTypeIds?: number[];
        },
        private fb: FormBuilder,
        private readonly _dialogRef: MatDialogRef<SubmittalRtoDialogComponent>,
        private readonly requestTimeOffDateFormat: RequestTimeOffPipe,
        private readonly _ldFeatureManager: LDFeatureManager,
        private readonly _store: Store
    ) {
        super();
    }

    ngOnInit(): void {
        this._store.dispatch(loadShiftTypes());
        this.shiftTypes$ = this._store.select(LookupsSelectors.getShiftTypes);
        this._ldFeatureManager
            .isEnabled(FeatureFlag.VendorAvailableDatePreferredShift)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.availableDatePreferredShiftFlag = flagIsEnabled;
            });

        this.title = this._data.title;
        this.submittalId = this._data.submittalId;
        this.newSubmittal = this._data.newSubmittal;

        this.form = this.fb.group({
            rtoNeeded: [this._data.rtoNeeded],
            requestTimeOffDates: [this._data.requestTimeOffDates]
        });

        if (this._data.availableStartDate && this.availableDatePreferredShiftFlag) {
            this.form.addControl('availableStartDate', this.fb.control(this._data.availableStartDate));
            this.form.addControl('preferredShifts', this.fb.control(this._data.preferredShiftTypeIds));
        }

        this.form.get('rtoNeeded').valueChanges.subscribe((value) => {
            this.clearDates();
        });
    }

    clearDates(): void {
        this.form.get('requestTimeOffDates').setValue([]);
    }

    onCancel(): void {
        this._dialogRef.close();
    }

    saveRto(): void {
        if (this.rtoDatepicker) {
            this.rtoDatepicker.control.markAsTouched();
            this.rtoDatepicker.control.updateValueAndValidity();
            if (this.rtoDatepicker.control.invalid) {
                this.form.setErrors({ invalidChild: true });
            } else {
                this.form.setErrors(null);
            }
        }

        if (this.form.valid) {
            if (!this.newSubmittal) {
                const selections = this.form.value;
                this._store.dispatch(
                    updateSubmittalPreferences({
                        submittalId: this.submittalId,
                        preferences: {
                            requestedTimeOff: selections.requestTimeOffDates,
                            preferredShiftTypeIds: selections.preferredShifts,
                            availableStartDate: selections.availableStartDate?.[0] ?? null,
                            submittalType: 1
                        }
                    })
                );
            }
            this._dialogRef.close(this.form.value);
        }
    }
}
