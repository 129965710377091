const allowedAnonymous = [
    '/sso/check-user-login-defaults',
    '/sso/identity-providers/user',
    '/ayaNova/UnitDescription/',
    '/AyaNova/performanceEval/',
    '/connect/emailpasswordresetinstructions',
    '/connect/passwordresettoken',
    '/connect/passwordreset',
    '/AyaConnect/Auth/log-access',
    '/AyaConnect/Auth/connect-user-exists',
    '/ayaconnect/client/integration/Nyu/departments',
    '/ayaconnect/client/integration/Nyu/buildings',
    '/ayaconnect/client/integration/Nyu/units',
    '/AyaConnect/Client/Traveler/Extension/*/postpone',
    '/AyaConnect/vendors/Submittals/*/profession-expertises',
    '/ayaconnect/compliance/unitdescription'
];
const allowedAnonymousPages = ['/units'];

function comparePath(allowedPath, currentPath) {
    const pattern = '^' + allowedPath.replace(/\*/gi, '.+').replace(/\//gi, '\\/');
    return new RegExp(pattern, 'i').test(currentPath);
}
function getCurrentLocationPath() {
    const path = window?.location?.hash?.replace('#', '') ?? window?.location?.pathname ?? '';
    return path.split('?')[0].toLowerCase();
}
export function isAllowedAnonymous(uri, coreUrl) {
    const currentPath = uri?.toLowerCase()?.replace(coreUrl, '') ?? '';
    const currentPage = getCurrentLocationPath();
    return (
        allowedAnonymous.some((p) => comparePath(p, currentPath)) ||
        allowedAnonymousPages.some((p) => currentPage === p.toLowerCase())
    );
}
