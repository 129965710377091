import { HttpParams } from '@angular/common/http';

export function interpolateFilteredParams(params: object = {}): HttpParams {
    if (params instanceof HttpParams) {
        return params;
    }

    const httpParams = new HttpParams();
    const filterValue = encodeURIComponent(JSON.stringify(params));

    return httpParams.append(filterValue, '');
}
