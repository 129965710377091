import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { loadJobDetails } from '../../store/actions';
import { selectSubmittalJobDetails, getSubmittalEmailData } from '../../store/selectors';

import { ToasterService } from 'src/app/core/services';
import { SubmittalsService } from '../../services/submittals.service';
import { SubmittalEmailData, SubmittalExpanded } from 'src/app/shared/models/submittals';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-offer-candidate-modal',
    templateUrl: './offer-candidate-modal.component.html',
    styleUrls: ['./offer-candidate-modal.component.scss']
})
export class OfferCandidateModalComponent extends UnsubscribeOnDestroy implements OnInit {
    form: UntypedFormGroup;
    disableAll: false;
    featureFlag = FeatureFlag;
    noteItemIsNotValid: boolean = false;
    standaloneVMSClientOfferFlowEnabled: boolean;

    submittalJobData$ = combineLatest([
        this.store.select(selectSubmittalJobDetails),
        this.store.select(getSubmittalEmailData)
    ]).pipe(filter(([job, emailData]) => !!job && !!emailData));

    get userName() {
        return this.data.submittal.userName;
    }

    get jobId() {
        return this.data.submittal.jobId;
    }

    get facilityName() {
        return this.data.submittal.facilityName;
    }

    get specialty() {
        return this.data.submittal.specialty;
    }

    get shiftText() {
        return this.data.submittal.shiftText;
    }

    get startDate() {
        return this.data.submittal.startDate;
    }

    get vendorName() {
        return this.data.submittal.agencyName;
    }

    contractDuration: number;
    billRate: number;
    shiftLongFormat: string;

    loading: boolean;
    submitting: boolean;

    get managerName() {
        return (
            this.submittalEmailData?.programManagerName?.trim() ||
            this.submittalEmailData?.accountManagerName?.trim() ||
            ''
        );
    }

    private submittalEmailData: SubmittalEmailData;

    constructor(
        private readonly dialogRef: MatDialogRef<OfferCandidateModalComponent>,
        private readonly toaster: ToasterService,
        private readonly service: SubmittalsService,
        @Inject(MAT_DIALOG_DATA) private readonly data: { submittal: SubmittalExpanded },
        private readonly store: Store,
        private readonly fb: UntypedFormBuilder,
        private readonly _featureManager: LDFeatureManager
    ) {
        super();
    }

    private createForm(): void {
        this.form = this.fb.group({
            suggestedBillRate: [0],
            PONumber: [''],
            note: ['']
        });
    }

    private loadJobDetails(): void {
        this.loading = true;
        this.submittalJobData$
            .pipe(
                tap(([job, emailData]) => {
                    this.submittalEmailData = emailData;
                    this.contractDuration = job.durationWeeks;
                    this.billRate = job.billRate;
                    this.form.patchValue({
                        suggestedBillRate: job.billRate
                    });
                    this.shiftLongFormat = job.longShiftFormat;
                    this.loading = false;
                })
            )
            .subscribe();
        this.store.dispatch(loadJobDetails({ jobId: this.data.submittal.jobId }));
    }

    ngOnInit(): void {
        this._featureManager
            .isEnabled(this.featureFlag.ConnectStandaloneVMSClientOfferFlow)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => (this.standaloneVMSClientOfferFlowEnabled = flagIsEnabled));

        this.createForm();
        this.loadJobDetails();
    }

    onSave() {
        this.submitting = true;
        this.service
            .sendSubmittalOffer({
                submittalId: this.data.submittal.id,
                offerDetails: this.form.get('note').value,
                poNumber: this.standaloneVMSClientOfferFlowEnabled ? null : this.form.get('PONumber').value,
                suggestedBillRate: this.standaloneVMSClientOfferFlowEnabled
                    ? null
                    : this.form.get('suggestedBillRate').value
            })
            .subscribe(
                () => {
                    this.toaster.success(`Offer submitted to ${this.managerName}`);
                    this.submitting = false;
                    this.dialogRef.close(true);
                },
                (error) => {
                    console.error(error);
                    this.toaster.fail('Offer submission failed!');
                    this.submitting = false;
                    this.dialogRef.close(false);
                }
            );
    }

    onClose() {
        this.dialogRef.close(false);
    }

    onNoteItemValidationChanged(isValid: boolean) {
        this.noteItemIsNotValid = !isValid;
    }
}
