import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VendorCandidatesDetailFilesComponent } from 'src/app/admin/vendor-candidates-detail/components/vendor-candidates-detail-files/vendor-candidates-detail-files.component';
import { VendorProfileComponent } from 'src/app/admin/vendor-details/profile/vendor-profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { VendorContactsComponent } from 'src/app/admin/vendor-details/contacts/vendor-contacts.component';
import { VendorFacilitiesComponent } from 'src/app/admin/vendor-details/facilities/vendor-facilities.component';
import { VendorPhonesComponent } from '../admin/vendor-details/profile/vendor-phones/vendor-phones.component';
import { VendorPhoneNumberPipe } from '../admin/vendor-details/profile/pipes/vendor-phone-number.pipe';
import { MaskPipe } from 'ngx-mask';
import { VendorEmailsComponent } from '../admin/vendor-details/profile/vendor-emails/vendor-emails.component';
import { VendorEmailAddressPipe } from '../admin/vendor-details/profile/pipes/vendor-email-address.pipe';
import { VendorJobPermissionsComponent } from '../admin/vendor-details/vendor-job-permissions/vendor-job-permissions.component';
import { ArraySortPipe } from '../shared/pipes/sort.pipe';
import { VendorNotesComponent } from 'src/app/admin/vendor-details/notes/vendor-notes.component';
import { NoteTypeLabelPipe } from 'src/app/admin/vendor-details/pipes/note-type-label.pipe';
import { VendorBankingComponent } from 'src/app/admin/vendor-details/banking/vendor-banking.component';
import { VendorProfileWebsitesComponent } from 'src/app/admin/vendor-details/websites/vendor-profile-websites.component';
import { WebsiteTypeLabelPipe } from 'src/app/admin/vendor-details/pipes/website-type-label.pipe';
import { VendorInsuranceComponent } from 'src/app/admin/vendor-details/insurance/vendor-insurance.component';
import { VendorContactsModule } from 'src/app/admin/vendor-details/contacts/vendor-contacts.module';
import { VendorLocationsComponent } from 'src/app/admin/vendor-details/profile/vendor-locations/vendor-locations.component';
import { VendorProfileCertificationsComponent } from 'src/app/admin/vendor-details/certifications/components/vendor-profile-certifications/vendor-profile-certifications.component';
import { VendorProfileCertificationsGroupComponent } from 'src/app/admin/vendor-details/certifications/components/vendor-profile-certifications-group/vendor-profile-certifications-group.component';
import { VendorProfileCertificationsDetailComponent } from 'src/app/admin/vendor-details/certifications/components/vendor-profile-certifications-detail/vendor-profile-certifications-detail.component';
import { SystemsModule } from 'src/app/systems/systems.module';
import { ProfileTabComponent as AdminProfileTabComponent } from '../admin/vendor-candidates/components/profile-tab/profile-tab.component';
import { FacilityFeatureModule } from '../facilities/facility-feature/facility-feature.module';
import { HelpPageModule } from '../admin/help/help-page.module';
import { FacilitiesSystemsProfileComponent } from 'src/app/facilities/facilities-systems-profile/facilities-systems-profile.component';
import { TopNavComponent } from 'src/app/core/top-nav/top-nav.component';
import { VendorPerdiemSchedulerDialogsModule } from '../vendor-perdiemscheduler/perdiemscheduler-dialogs.module';
import { VendorDetailsFilesModule } from '../admin/vendor-details/files/vendor-details-files.module';
import { ForgotPasswordWrapperComponent } from './forgot-password-dialog/forgot-password-wrapper/forgot-password-wrapper.component';
import { SystemDetailsSharedModule } from 'src/app/facilities/system-details-shared.module';
import { VendorCandidateDetailsComponent } from '../admin/vendor-candidates/components/vendor-candidate-details/vendor-candidate-details.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VendorCandidateDetailsModule } from 'src/app/vendor/vendor-candidate-details/vendor-candidate-details.module';
import { ClinicalSharedModule } from 'src/app/clinical/shared/clinical-shared.module';
import { VendorTagsValidityComponent } from 'src/app/admin/vendor-details/profile/vendor-tags-validity/vendor-tags-validity.component';
import { VendorDetailsComponent } from 'src/app/admin/vendor-details/vendor-details/vendor-details.component';
import { VendorJobRulesPanelComponent } from '../admin/vendor-details/job-rules-panel/job-rules-panel.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        VendorContactsModule,
        SystemsModule,
        FacilityFeatureModule,
        HelpPageModule,
        VendorPerdiemSchedulerDialogsModule,
        VendorDetailsFilesModule,
        SystemDetailsSharedModule,
        ClinicalSharedModule,
        VendorCandidateDetailsModule
    ],
    declarations: [
        VendorProfileComponent,
        VendorContactsComponent,
        VendorInsuranceComponent,
        VendorCandidatesDetailFilesComponent,
        VendorFacilitiesComponent,
        VendorPhonesComponent,
        VendorPhoneNumberPipe,
        VendorEmailsComponent,
        VendorEmailAddressPipe,
        VendorJobPermissionsComponent,
        VendorNotesComponent,
        NoteTypeLabelPipe,
        VendorBankingComponent,
        VendorProfileWebsitesComponent,
        WebsiteTypeLabelPipe,
        VendorLocationsComponent,
        VendorProfileCertificationsComponent,
        VendorProfileCertificationsGroupComponent,
        VendorProfileCertificationsDetailComponent,
        AdminProfileTabComponent,
        TopNavComponent,
        ForgotPasswordWrapperComponent,
        VendorCandidateDetailsComponent,
        SidebarComponent,
        VendorTagsValidityComponent,
        VendorDetailsComponent,
        VendorJobRulesPanelComponent
    ],
    exports: [
        VendorProfileComponent,
        VendorContactsComponent,
        VendorCandidatesDetailFilesComponent,
        VendorFacilitiesComponent,
        VendorPhonesComponent,
        VendorPhoneNumberPipe,
        VendorEmailsComponent,
        VendorEmailAddressPipe,
        VendorJobPermissionsComponent,
        VendorBankingComponent,
        VendorNotesComponent,
        VendorProfileWebsitesComponent,
        VendorBankingComponent,
        VendorJobPermissionsComponent,
        VendorInsuranceComponent,
        VendorLocationsComponent,
        VendorProfileCertificationsComponent,
        VendorProfileCertificationsDetailComponent,
        AdminProfileTabComponent,
        FacilitiesSystemsProfileComponent,
        TopNavComponent,
        ForgotPasswordWrapperComponent,
        VendorCandidateDetailsComponent,
        SidebarComponent,
        VendorTagsValidityComponent,
        VendorDetailsComponent,
        VendorJobRulesPanelComponent
    ],
    providers: [MaskPipe, ArraySortPipe]
})
export class DowngradedComponentsModule {}
