
import { ResourceList } from 'src/app/shared/models/internal-pool/resource-list.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ListItem } from 'src/app/shared/models/list-item';
import { SpecialtyLookup } from 'src/app/shared/models/lookups/specialty-lookup.model';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { InternalPoolState, internalPoolStoreKey } from './internal-pool.state';
import { LoadingTypes } from 'src/app/shared/models';
import { SystemLookup } from 'src/app/timecards/models';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { FilterDescriptor } from '@progress/kendo-data-query';

export const getError = (state: InternalPoolState): string => state.resources.error;
export const getResources = (state: InternalPoolState): PagingToken<ResourceList[]> => state.resources.resources;
export const getCurrentResource = (state: InternalPoolState): Resource => state.details.resource;
export const getResourcesQuery = (state: InternalPoolState): GridSearchQuery => state.resources.query;
export const getProfessionsLookups = (state: InternalPoolState): ListItem[] => state.lookups.professions;
export const getSpecialtiesLookups = (state: InternalPoolState): SpecialtyLookup[] => state.lookups.specialties;
export const getPreferredFacilitiesLookups = (state: InternalPoolState): FacilityLookup[] => state.lookups.facilities;
export const getSystemsLookups = (state: InternalPoolState): SystemLookup[] => state.lookups.systems;
export const getSystemsId = (state: InternalPoolState): number => state.resources.systemId;
export const getPreferredShiftsLookups = (state: InternalPoolState): ListItem[] => state.lookups.preferredShifts;
export const getStatusesLookups = (state: InternalPoolState): ListItem[] => state.lookups.statuses;
export const getLookupsIsLoaded = (state: InternalPoolState): LoadingTypes => state.lookups.loading;
export const getIsResourcesGridLoading = (state: InternalPoolState): boolean =>
    [state.lookups.loading, state.resources.loading, state.details.loading].some(x => x === LoadingTypes.LOADING);
export const getIsResourceSaving = (state: InternalPoolState): boolean =>
    [state.details.saving, state.lookups.loading].some(x => x === LoadingTypes.LOADING);
export const isNyuSystem = (state: InternalPoolState): boolean => state.system.isNyuSystem;

export const selectInternalPoolState = createFeatureSelector<InternalPoolState>(
    internalPoolStoreKey
);

export const selectError = createSelector(
    selectInternalPoolState,
    getError
);

export const selectResources = createSelector(
    selectInternalPoolState,
    getResources
);

export const selectResourcesData = createSelector(
    selectResources,
    state => ({
        data: state.data,
        total: state.total
    })
);

export const selectResourceDetails = createSelector(
    selectInternalPoolState,
    getCurrentResource
);

export const selectResourcesQuery = createSelector(
    selectInternalPoolState,
    getResourcesQuery
);


export const selectPredefinedQuery = createSelector(
    selectResourcesQuery,
    (state: GridSearchQuery) => {
        const target = {
            facilityId: null,
            professionId: null,
            expertiseId: null
        }
        const filters = state.filter.filters as FilterDescriptor[];
        return filters
            .filter(f => f.field as string in target)
            .filter(f => f.value)
            .reduce((group, f) => ({
                ...group,
                [f.field as string]: f.value
            }), target);
    }
)

export const selectResourceLookups = createSelector(
    selectInternalPoolState,
    (state) => state.lookups
);

export const selectProfessionLookups = createSelector(
    selectInternalPoolState,
    getProfessionsLookups
);

export const selectPreferredLocationLookups = createSelector(
    selectInternalPoolState,
    getPreferredFacilitiesLookups
);

export const selectSpecialtiesLookups = createSelector(
    selectInternalPoolState,
    getSpecialtiesLookups
);

export const selectFacilitiesLookups = createSelector(
    selectResourceLookups,
    (state) => state.facilities
);

export const selectSystemsLookups = createSelector(
    selectInternalPoolState,
    getSystemsLookups
);

export const selectPreferredShiftsLookups = createSelector(
    selectInternalPoolState,
    getPreferredShiftsLookups
);

export const selectStatusesLookups = createSelector(
    selectInternalPoolState,
    getStatusesLookups
);

export const selectLookupsIsLoaded = createSelector(
    selectInternalPoolState,
    getLookupsIsLoaded
);

export const selectIsResourcesGridLoading = createSelector(
    selectInternalPoolState,
    getIsResourcesGridLoading
)

export const selectIsResourceSaving = createSelector(
    selectInternalPoolState,
    getIsResourceSaving
)

export const selectIsResourceDetailSaved = createSelector(
    selectInternalPoolState,
    (state) => state.details.saving === LoadingTypes.LOADED
)

export const selectSystemId = createSelector(
    selectInternalPoolState,
    (state) => state.resources ? state.resources.systemId : null
)

export const selectCurrentResource = createSelector(
    selectResourceDetails,
    (currentResource) => currentResource
);

export const selectCurrentResourceType = createSelector(
    selectResourceDetails,
    (currentResource) => currentResource?.irpRecordTypeName
);

export const selectCurrentResourceIsArchived = createSelector(
    selectCurrentResource,
    (state) => state?.isArchived ?? false
);

export const selectCurrentResourceWithSystem = createSelector(
    selectResourceDetails,
    selectFacilitiesLookups,
    selectSystemsLookups,
    (resource, facilities, systems) => {
        if (resource
            && facilities
            && facilities.length)
        {
            const facilityLookup = facilities[0];
            if (facilityLookup) {
                const system = systems.find(s => s.id === facilityLookup.systemId);
                return {
                    ...resource,
                    systemId: system.id,
                    systemName: system.name
                }
            }
        }
        return resource;
    }
);

export const selectIsNyuSystem = createSelector(
    selectInternalPoolState,
    isNyuSystem
);

export const selectResourceReadonlyFacilities = createSelector(
    selectResourceDetails,
    selectFacilitiesLookups,
    (resource, facilities) => {
        const facilitiesSet = new Set(facilities.map(f => f.id));
        return resource?.facilities
            .filter(f => !facilitiesSet.has(f.facilityId))
            .map(f => ({
                id: f.facilityId,
                name: f.facilityName
            })) ?? [];
    }
);

export const selectHasEditableFacilities = createSelector(
    selectResourceDetails,
    selectFacilitiesLookups,
    (resource, facilities): boolean => {
        const facilitiesSet = new Set(facilities.map(f => f.id));
        return resource?.facilities.some(f => facilitiesSet.has(f.facilityId)) ?? false;
    }
);