/**angular */
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

/**ngrx */
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as selectors from 'src/app/admin/store/selectors/vendor-candidates-detail-files.selectors';
import * as actions from 'src/app/admin/store/actions/vendor-candidates-detail-files.actions';
import { first, takeUntil } from 'rxjs/operators';

/**local */
import { VendorCandidatesDetailFilesService } from 'src/app/admin/vendor-candidates-detail/services/vendor-candidates-detail-files.service';

/**kendo */
import { GridDataResult } from '@progress/kendo-angular-grid';

/**shared */
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { VendorCandidateFile } from 'src/app/admin/vendor-candidates-detail/models/vendor-candidate-file.model';
import { FileType } from 'src/app/shared/models/file-type.model';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

@Component({
    selector: 'ayac-vendor-candidates-detail-files',
    templateUrl: './vendor-candidates-detail-files.component.html',
    styleUrls: ['./vendor-candidates-detail-files.component.scss']
})
export class VendorCandidatesDetailFilesComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @ViewChild('fileUpload', { static: false }) fileUpload: any;
    @Input() candidate;

    searchQuery$: Observable<GridSearchQuery>;

    gridData$: Observable<GridDataResult>;

    deletingFileIds$: Observable<number[]>;
    isDeleting$: Observable<boolean>;
    isUploading = false;
    selectedFileType: FileType = null;
    skipUnnecessaryRequestsNewVendorCandidate = false;

    fileTypes$: Observable<FileType[]> = null;

    get isNew(): boolean {
        if (Number.isNaN(Number(this.candidate))) {
            return true;
        }
        return !this.candidate || this.candidate <= 0;
    }

    loading$: Observable<boolean> = this._store.select(selectors.selectVendorCandidateFilesLoading);

    constructor(
        private readonly _store: Store,
        private readonly _vendorCandidatesDetailFilesService: VendorCandidatesDetailFilesService,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.searchQuery$ = this._store.select(selectors.selectVendorCandidateFilesSearchQuery).pipe(first());

        this.gridData$ = this._store.select(selectors.selectVendorCandidateFiles);

        this.fileTypes$ = this._store.select(selectors.selectFileTypes);

        this.deletingFileIds$ = this._store.select(selectors.selectVendorCandidateFilesDeletingFileIds);

        this.loading$ = this._store.select(selectors.selectVendorCandidateFilesLoading);

        this.isDeleting$ = this._store.select(selectors.selectVendorCandidateFileIsDeleting);

        this._ldFeatureManager
            .isEnabled(FeatureFlag.SkipUnnecessaryRequestsNewAdminVendorCandidate)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.skipUnnecessaryRequestsNewVendorCandidate = flagIsEnabled;
            });

        this._store.dispatch(actions.loadFileTypes());

        this._store.dispatch(actions.loadVendorCandidateDetailFiles({ candidateId: this.candidate }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.candidateId?.currentValue) {
            this.refreshGrid();
        }
    }

    refreshGrid(): void {
        if (!this.candidate || (this.isNew && this.skipUnnecessaryRequestsNewVendorCandidate)) {
            return;
        }

        this._store.dispatch(
            actions.loadVendorCandidateDetailFiles({
                candidateId: this.candidate
            })
        );
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this._store.dispatch(
            actions.setVendorCandidateDetailFilesSearchQuery({
                searchQuery: state
            })
        );
        this.refreshGrid();
    }

    onDownloadFile(vendorFile: VendorCandidateFile): void {
        this._vendorCandidatesDetailFilesService.downloadFile(vendorFile);
    }

    onDeleteFile(candidateFile: VendorCandidateFile): void {
        this._store.dispatch(
            actions.deleteVendorCandidateDetailFiles({
                fileIds: [candidateFile.fileId],
                candidateId: this.candidate
            })
        );
    }

    onFileSelected(newFiles: any[]): void {
        this.isUploading = true;
        this._vendorCandidatesDetailFilesService
            .addFiles(this.candidate, newFiles)
            .pipe(takeUntil(this.d$))
            .subscribe(
                (fileIds) => {
                    this.isUploading = false;
                    this.refreshGrid();
                },
                (error: unknown) => {
                    this.isUploading = false;
                }
            );
    }

    uploadFile(fileType: any): void {
        this.selectedFileType = fileType;
        this.fileUpload.nativeElement.click();
    }
}
