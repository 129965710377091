import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'formly-options-input',
    template: `
        <div class="form-wrapper">
            <form [formGroup]="richOptionsForm">
                <mat-form-field>
                    <mat-label>Value</mat-label>
                    <input
                        matInput
                        formControlName="optionValue" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Description</mat-label>
                    <input
                        matInput
                        formControlName="optionDescription" />
                </mat-form-field>
            </form>
            <div>
                <button
                    mat-stroked-button
                    (click)="add()"
                    type="button"
                    [disabled]="
                        !richOptionsForm.get('optionValue').value || !richOptionsForm.get('optionDescription').value
                    ">
                    <mat-icon>add</mat-icon>&nbsp;Add
                </button>
            </div>
        </div>
        <div
            class="options-list"
            *ngIf="{ hasValues: (field.props.hasValues | async) }">
            <ng-container *ngFor="let option of values; let i = index">
                <div>
                    <button
                        mat-stroked-button
                        (click)="delete(option)"
                        color="{{ hasValues ? 'accent' : 'warn' }}"
                        type="button">
                        <ng-container *ngIf="hasValues === false">
                            <mat-icon>clear</mat-icon>&nbsp;&nbsp;
                        </ng-container>
                        <span>{{ option.value }} - {{ option.description }}</span>
                    </button>
                </div>
            </ng-container>
        </div>
    `,
    styleUrls: ['./rich-options-input.component.scss']
})
export class RichOptionsInputComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
    d$ = new Subject<void>();
    values: Array<{ value: string; description: string }> = [];
    hasValues = false;
    richOptionsForm: UntypedFormGroup = new UntypedFormGroup({
        optionValue: new UntypedFormControl(''),
        optionDescription: new UntypedFormControl('')
    });

    ngOnInit() {
        this.richOptionsForm.setParent(this.field.form);
        this.initOptions();
        this.checkFieldHasValues();
    }

    ngOnDestroy(): void {
        this.d$.next();
        this.d$.complete();
    }

    checkFieldHasValues(): void {
        this.field.props['hasValues'].pipe(takeUntil(this.d$)).subscribe((hasValues) => {
            this.hasValues = hasValues;
        });
    }

    initOptions(): void {
        if (typeof this.field.key === 'string' || typeof this.field.key === 'number') {
            this.values = this.model[this.field.key];
        }
    }

    add() {
        const value = {
            value: this.richOptionsForm.get('optionValue').value,
            description: this.richOptionsForm.get('optionDescription').value
        };
        if (!this.values.some((e) => e.value.toLowerCase() === value.value.toLowerCase())) {
            this.values.push(value);
            this.setModelOptions();
            this.richOptionsForm.get('optionValue').setValue('');
            this.richOptionsForm.get('optionDescription').setValue('');
        }
    }

    delete(option: { value: string; description: string }) {
        if (!this.hasValues || !option) {
            this.values = this.values.filter((x) => x.description !== option.description || x.value !== option.value);
            this.setModelOptions();
        }
    }

    private setModelOptions() {
        if (typeof this.field.key === 'string' || typeof this.field.key === 'number') {
            this.model[this.field.key] = this.values;
        }
    }
}
