import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GroupSelection, GroupItem, GroupSelectedValue } from '../../models';
import { MatOption } from '@angular/material/core';
import { debounce, timer, distinctUntilChanged, takeUntil, Subject } from 'rxjs';

@Component({
    selector: 'grid-multifield-multiselect-filter',
    templateUrl: './multifield-select-filter.component.html',
    styleUrls: ['./multifield-select-filter.component.scss']
})
export class MultiFieldSelectFilterComponent
    extends BaseFilterCellComponent
    implements OnChanges, AfterViewInit, OnDestroy {
    @ViewChild('select') select: MatSelect;
    @ViewChildren('groupCheckbox') groupCheckboxes: QueryList<MatCheckbox>;
    @ViewChild('selectAllCheckBox', { static: false }) selectAllCheckBox: MatCheckbox;
    @ViewChildren('groupDivs') groupDivs: QueryList<ElementRef>;
    @ViewChild('searchInput') searchInput: HTMLInputElement;

    @Input()
    data: GroupItem[] = [];

    @Input() field: string;
    @Input() width: number;
    @Input() required = true;
    @Input() defaultText: string;
    @Input() selectAll: string;
    @Input() defaultToAllSelected: boolean;
    @Output() filterChanging = new EventEmitter<{ value: any[]; filter: CompositeFilterDescriptor }>();
    @Input() filter: CompositeFilterDescriptor;
    @Input() textFilter = false;
    @Input() allowSelectAll = true;

    selectedValues: GroupSelectedValue[] = [];
    groupControl = new FormControl();
    filterCtrl = new UntypedFormControl('');
    selectionUpdated = false;

    private readonly _cleanup$ = new Subject();

    constructor(filterService: FilterService) {
        super(filterService);
    }

    get charLimit(): number {
        return this.width ? Math.floor(this.width / 13) : 14;
    }

    get dataItems(): GroupSelectedValue[] {
        return this.data?.flatMap((item) => {
            return item.items.map((t) => {
                return { group: item.name, id: t.id, name: t.name };
            });
        });
    }

    get firstItem(): string {
        return this.groupControl.value.length > 0 ? this.groupControl.value[0].name : null;
    }

    get fieldFilters(): FilterDescriptor[] {
        if (!this.data) {
            return null;
        }

        if (this.field) {
            const filter = this.filter?.filters?.find(
                (f: FilterDescriptor) => f.field === this.field
            ) as unknown as FilterDescriptor;
            return [filter] as FilterDescriptor[];
        } else {
            const groupNames = this.data
                .map((t) => t.name)
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });

            const list = this.filter?.filters
                ?.filter((f: FilterDescriptor) => f.field && f.field !== null && groupNames.includes(f.field as string))
                .map((t) => {
                    return t as FilterDescriptor;
                });

            return list.length > 0 ? list : null;
        }
    }

    get collapsedState(): Map<string, boolean> {
        const map = new Map<string, boolean>();
        if (this.select?.options) {
            this.select.options
                .filter((t) => t.value.group)
                .forEach((t) => {
                    map.set(t.value.group, t._getHostElement().classList.contains('show'));
                });
        }
        return map;
    }

    compareGroupItem(p1: GroupSelectedValue, p2: GroupSelectedValue): boolean {
        if (p1 && p2) {
            return p1.id === p2.id && p1.group === p2.group && p1.name === p2.name;
        }
        return false;
    }

    selectAllGroups(event: MatCheckboxChange) {
        if (event.checked) {
            this.groupControl.setValue(this.dataItems);
        } else {
            this.groupControl.setValue([]);
        }
    }

    selectGroup(event: MatCheckboxChange) {
        const f = this.dataItems.filter((t) => t.group === event.source.value);
        const items =
            this.groupControl.value !== null
                ? this.groupControl.value.filter((t) => t.group !== event.source.value)
                : [];
        if (event.checked) {
            items.push(...f.filter((t) => t.group === event.source.value));
        }

        this.groupControl.setValue(items);
    }

    selectionChange(items: MatSelectChange) {
        const allSelected = items.value.length === this.dataItems.length;
        const noneSelected = items.value.length === 0;

        if (this.selectAllCheckBox) {
            if (allSelected) {
                this.selectAllCheckBox.checked = true;
                this.selectAllCheckBox.indeterminate = false;
            } else if (noneSelected) {
                this.selectAllCheckBox.checked = false;
                this.selectAllCheckBox.indeterminate = false;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.fieldFilters) {
            const items: GroupSelectedValue[] = [];
            this.fieldFilters.forEach((g) => {
                items.push(
                    ...this.dataItems.filter(
                        (x) =>
                            g.value.includes(x.id) &&
                            (this.field !== undefined && this.field !== null ? true : x.group === g.field)
                    )
                );
            });
            this.groupControl.setValue(items);
            this.selectedValues = items;
            this.selectionChange({ value: items, source: null });
        } else if (this.defaultToAllSelected && changes.data?.currentValue) {
            const list = changes.data.currentValue.flatMap((t) => {
                return t.items.map((x) => {
                    return { group: t.name, id: x.id, name: x.name };
                });
            });
            this.groupControl.setValue(list);
            this.selectedValues = list;
            this.groupControl.updateValueAndValidity();
            this.selectionChange({ value: list, source: null });
        }
    }

    ngAfterViewInit() {
        this.select.optionSelectionChanges.subscribe((res) => {
            const optionVal = res.source.value;
            const groupCheckbox: MatCheckbox = this.groupCheckboxes.find((t) => t.value === optionVal.group);
            const total = this.select.options.filter((t) => t.value.group === optionVal.group).length;
            const selectedLength = this.select.options.filter(
                (t) => t.value.group === optionVal.group && t.selected
            ).length;

            if (total === selectedLength) {
                groupCheckbox.checked = true;
                groupCheckbox.indeterminate = false;
            } else {
                groupCheckbox.checked = false;
                groupCheckbox.indeterminate = selectedLength !== 0;
            }

            if (this.selectAllCheckBox) {
                this.selectAllCheckBox.checked = this.groupControl.value.length === this.dataItems.length;
                this.selectAllCheckBox.indeterminate =
                    this.groupControl.value.length > 0 && this.dataItems.length !== this.groupControl.value.length;
            }
        });

        this.filterCtrl.valueChanges
            .pipe(
                debounce(() => timer(500)),
                distinctUntilChanged(),
                takeUntil(this._cleanup$)
            )
            .subscribe((value: string) => {
                this.updateVisibleOptions(value);
            });
    }

    ngOnDestroy(): void {
        this._cleanup$.next(true);
        this._cleanup$.complete();
    }

    panelStateChange(opened: boolean): void {
        if (opened === false) {
            this.onChange(this.groupControl.value);
            this.filterCtrl.setValue('');
        }
    }

    onChange(value: GroupSelectedValue[]): void {
        if (JSON.stringify(value) === JSON.stringify(this.selectedValues)) {
            return; // Values unchanged, do nothing
        }

        this.selectedValues = value;
        if (!this.field) {
            const groupValues: GroupSelection[] = [];

            this.data.forEach((t) => {
                const f = this.groupControl.value
                    .filter((g) => g.group === t.name)
                    .flatMap((s) => {
                        return s.id;
                    });

                const groupFilter: any = this.filter?.filters.find((x) => (x as FilterDescriptor).field === t.name);

                if (groupFilter) {
                    groupFilter.value = f;
                } else {
                    this.filter.filters.push({
                        field: t.name,
                        operator: 'eq',
                        value: f
                    });
                }
                groupValues.push({ name: t.name, values: f });
            });
            this.filterChanging.emit({ value: groupValues, filter: this.filter });
        } else {
            value = value?.flatMap((t) => t.id) ?? [];
            this.filterChanging.emit({ value, filter: this.filter });
        }

        //dummy group call to invoke a single backend query after setting multiple filters
        const fieldName = this.field ?? 'x_group';
        const fieldValue = this.field ? value : '1';
        this.applyFilter(
            value === null
                ? this.removeFilter(fieldName)
                : this.updateFilter({
                      field: fieldName,
                      operator: 'eq',
                      value: fieldValue
                  })
        );
    }

    collapseGroup(groupName: string) {
        this.select.options
            .filter((t) => t.value.group === groupName)
            .forEach((t) => {
                const show = t._getHostElement().classList.contains('show');
                if (show) {
                    this.hideElement(t._getHostElement());
                } else {
                    this.showElement(t._getHostElement());
                }
            });
    }

    matOptionClick(event: MatCheckboxChange, groupOption: MatOption) {
        if (event.checked) {
            groupOption.select();
        } else {
            groupOption.deselect();
        }
    }

    updateVisibleOptions(value: string) {
        if (value === '') {
            this.select.options.forEach((option) => {
                this.showElement(option._getHostElement());
            });
            this.groupDivs.forEach((groupDiv) => {
                this.showElement(groupDiv.nativeElement);
            });
            return;
        }

        this.data.forEach((group) => {
            const groupOption = this.groupCheckboxes.find((option) => option.value === group.name);
            const groupChildren = this.select.options.filter((option) => option.value.group === group.name);
            let groupChildrenVisible = false;
            groupChildren.forEach((option) => {
                const groupValue = option.value.group;
                const optionValue = option.value.name;
                if (
                    groupValue.toLowerCase().includes(value.toLowerCase()) ||
                    optionValue.toLowerCase().includes(value.toLowerCase())
                ) {
                    this.showElement(option._getHostElement());
                    groupChildrenVisible = true;
                } else {
                    this.hideElement(option._getHostElement());
                }
            });
            const groupDiv = this.groupDivs.find((gd) =>
                gd.nativeElement.contains(groupOption._elementRef.nativeElement)
            );
            if (groupChildrenVisible) {
                this.showElement(groupDiv.nativeElement);
            } else {
                this.hideElement(groupDiv.nativeElement);
            }
        });
    }

    private showElement(element: HTMLElement) {
        element.classList.replace('hide', 'show');
    }

    private hideElement(element: HTMLElement) {
        element.classList.replace('show', 'hide');
    }
}
