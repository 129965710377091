import { Injectable, Inject } from '@angular/core';
import { InvoicesResponse, InvoiceLookupsResponse, InvoiceDisputeCreateModel, InvoiceAttachment } from '../models';
import { interpolateParams, interpolateFilteredParams, interpolateUrl } from 'src/app/core/utils';
import { combine } from '../../shared/utilities/route';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { Observable } from 'rxjs';
import { InvoiceDetails } from '../models/invoice-details.model';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

@Injectable()
export class InvoicesRepositoryService {
    private readonly coreApiPath: string;
    private readonly invoicesPath: string;
    private readonly invoicesLookUpsPath: string;
    private readonly invoicesExcelExportPath: string;
    private readonly invoicesDocumentPath: string;
    private readonly createInvoiceDisputePath: string;
    private readonly invoiceLineDisputesExportPath: string;
    private readonly approveDraftInvoicePath: string;
    featureFlags = FeatureFlag;
    usePostForInvoicesGetFiltered = false;
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) _settings: Settings,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        this.coreApiPath = combine(_settings.CORE, 'AyaConnect');
        this.invoicesPath = combine(this.coreApiPath, '/Invoices');
        this.invoicesLookUpsPath = combine(this.invoicesPath, '/Lookups');
        this.invoicesExcelExportPath = combine(this.invoicesPath, '/excel-export');
        this.invoiceLineDisputesExportPath = combine(this.invoicesPath, '/line-disputes-export');
        this.invoicesDocumentPath = combine(this.invoicesPath, '/Document');
        this.createInvoiceDisputePath = combine(this.invoicesPath, `/create-dispute`);
        this.approveDraftInvoicePath = combine(this.invoicesPath, '/approve-invoices');

        this.ldFeatureManager.isEnabled(this.featureFlags.UsePostForInvoicesGetFiltered).subscribe((flagIsEnabled) => {
            if (flagIsEnabled) {
                this.usePostForInvoicesGetFiltered = true;
            }
        });
    }

    getFilteredInvoices(filteredArgs: GridSearchQuery) {
        const url = `${this.invoicesPath}/GetFiltered?ViewTypeId=3`;

        if (this.usePostForInvoicesGetFiltered) {
            return this.http.post<InvoicesResponse>(url, filteredArgs);
        } else {
            const params = interpolateFilteredParams(filteredArgs);

            return this.http.get<InvoicesResponse>(url, { params });
        }
    }

    getInvoiceLookups() {
        const params = interpolateParams({
            viewTypeId: 3
        });
        return this.http.get<InvoiceLookupsResponse>(`${this.invoicesLookUpsPath}`, { params });
    }

    exportInvoices(ids: Array<number>, timeZoneOffset?: number) {
        const params = interpolateParams({
            ids,
            timeZoneOffset
        });

        return this.http.get(`${this.invoicesExcelExportPath}`, { params, responseType: 'blob' });
    }

    exportInvoiceLineItemDisputes(ids: Array<number>, history: boolean) {
        const params = interpolateParams({
            ids,
            history
        });

        return this.http.get(`${this.invoiceLineDisputesExportPath}`, { params, responseType: 'blob' });
    }

    getInvoiceDocument(id: number): Observable<any> {
        const params = interpolateParams({
            id: id
        });

        return this.http.get(`${this.invoicesDocumentPath}`, { params, responseType: 'blob', observe: 'response' });
    }

    getInvoiceDetails(id: number, isBillable: boolean): Observable<InvoiceDetails> {
        if (isBillable) {
            return this.http.get<InvoiceDetails>(`${this.invoicesPath}/billable/${id}`);
        }

        return this.http.get<InvoiceDetails>(`${this.invoicesPath}/${id}`);
    }

    getInvoiceAttachments(id: number): Observable<InvoiceAttachment[]> {
        return this.http.get<InvoiceAttachment[]>(`${this.invoicesPath}/${id}/dispute-attachments`);
    }

    createInvoiceDispute(dispute: InvoiceDisputeCreateModel): Observable<HttpResponse<InvoiceDetails>> {
        return this.http.post<InvoiceDetails>(this.createInvoiceDisputePath, dispute, { observe: 'response' });
    }

    approveDraftInvoices(ids: number[]): Observable<HttpResponse<void>> {
        const requestBody = { ids };
        return this.http.put<void>(this.approveDraftInvoicePath, requestBody, { observe: 'response' });
    }

    getInvoiceAttachmentPreview(invoiceAttachmentId: number): Observable<Blob> {
        return this.http.get(`${this.invoicesPath}/attachments/preview/${invoiceAttachmentId}`, {
            responseType: 'blob'
        });
    }

    getInvoiceAttachmentPreviewTotalPages(invoiceAttachmentId: number): Observable<number> {
        return this.http.get<number>(`${this.invoicesPath}/attachments/pageCount/${invoiceAttachmentId}`);
    }

    downloadFilePreview(invoiceAttachmentId: number): Observable<HttpResponse<Blob>> {
        return this.http.get(`${this.invoicesPath}/attachments/preview/${invoiceAttachmentId}`, {
            responseType: 'blob',
            observe: 'response'
        });
    }

    downloadFile(invoiceAttachmentId: number): Observable<HttpResponse<Blob>> {
        return this.http.get(`${this.invoicesPath}/${invoiceAttachmentId}/download`, {
            responseType: 'blob',
            observe: 'response'
        });
    }
}
