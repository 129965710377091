import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ClinicalService } from 'src/app/clinical/services/clinical.service';
import * as actions from 'src/app/clinical/store/actions/client-units.actions';
import { saveUnitExtraFieldsSuccess, saveUnitFailure } from 'src/app/clinical/store/actions/client-units.actions';
import {
    selectClientUnitsGridSearchQuery,
    selectFacilityId
} from 'src/app/clinical/store/selectors/client-units.selectors';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { SortTypes } from 'src/app/shared/models';
import { DialogRef } from 'src/app/shared/models/dialog.models';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DomainService } from 'src/app/shared/services/domain.service';
import { UnitDescriptionLoadingDialogComponent } from '../../components/clinical-unit-description/modals/loading/loading.component';
import { NyuLookup } from '../../models/nyu-lookup.model';
import { SaveUnitEnum } from '../../models/save-unit.enum';
import { SendLinkModalComponent } from '../../components/clinical-unit-description/modals/send-link-modal/send-link-modal.component';
import { UnitDescriptionEmail } from '../../models/unit.model';
import { Router } from '@angular/router';

@Injectable()
export class ClientUnitsEffects {
    loadingDialog: DialogRef<UnitDescriptionLoadingDialogComponent, boolean>;
    getClientUnits1$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(
                actions.loadClientUnits,
                actions.setClientUnitsSearchQuery,
                actions.updateUnitStatusSuccess,
                actions.deleteUnitSuccess
            ),
            withLatestFrom(this._store.select(selectClientUnitsGridSearchQuery), this._store.select(selectFacilityId)),
            switchMap(([, query, facilityId]) => {
                query.filter = {} as CompositeFilterDescriptor;
                query.filter.filters = [];
                query.filter.filters.push({
                    field: 'facilityId',
                    operator: 'eq',
                    value: facilityId
                });

                const { pagination, sortArgs } = this.prepareParams(query);
                const matchArgs = flattenFilter(query.filter);
                return this._clinicalService.getClientUnits(pagination, sortArgs, matchArgs).pipe(
                    map((result) =>
                        actions.loadClientUnitsSuccess({
                            clientUnits: result
                        })
                    ),
                    catchError((error: unknown) => of(actions.loadClientUnitsFailure({ error })))
                );
            })
        );
    });

    getPhysicianSpecialties$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadBoardCertificationsComponent),
            exhaustMap((action) => {
                return this._clinicalService.getPhysicianSpecialties().pipe(
                    map((result) =>
                        actions.loadPhysicianSpecialtiesSuccess({
                            physicianSpecialties: result
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(actions.loadPhysicianSpecialtiesFailure({ error: err }));
                    })
                );
            })
        );
    });

    getUnitBoardCertifications$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadBoardCertificationsComponent),
            exhaustMap((action) => {
                return this._clinicalService.getUnitBoardCertifications(action.unitId).pipe(
                    map((result) =>
                        actions.loadUnitBoardCertificationsSuccess({
                            boardCertifications: result
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(actions.loadUnitBoardCertificationsFailure({ error: err }));
                    })
                );
            })
        );
    });

    getUnitStateControlledSubstances$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadStateControlledSubstancesComponent),
            exhaustMap((action) => {
                return this._clinicalService.getUnitStateControlledSubstances(action.unitId).pipe(
                    map((result) =>
                        actions.loadUnitStateControlledSubstancesSuccess({
                            stateControlledSubstances: result
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(actions.loadUnitStateControlledSubstancesFailure({ error: err }));
                    })
                );
            })
        );
    });

    getOtherRequirementState$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadOtherRequirementComponent),
            exhaustMap((action) => {
                return this._clinicalService.getOtherRequirements().pipe(
                    map((result) =>
                        actions.loadOtherRequirementsSuccess({
                            otherRequirements: result
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(actions.loadOtherRequirementsFailure({ error: err }));
                    })
                );
            })
        );
    });

    getUnitOtherRequirementState$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadOtherRequirementComponent),
            exhaustMap((action) => {
                return this._clinicalService.getUnitOtherRequirements(action.unitId).pipe(
                    map((result) =>
                        actions.loadUnitOtherRequirementsSuccess({
                            unitOtherRequirements: result
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(actions.loadUnitOtherRequirementsFailure({ error: err }));
                    })
                );
            })
        );
    });

    getUnitStateLicenses$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadStateLicensesComponent),
            exhaustMap((action) => {
                return this._clinicalService.getUnitStateLicenses(action.unitId).pipe(
                    map((result) =>
                        actions.loadUnitStateLicensesSuccess({
                            stateLicenses: result
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(actions.loadUnitStateLicensesFailure({ error: err }));
                    })
                );
            })
        );
    });

    getStates$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadStateLicensesComponent),
            exhaustMap((action) => {
                return this._clinicalService.getStates().pipe(
                    map((result) =>
                        actions.loadStatesSuccess({
                            states: result
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(actions.loadStatesFailure({ error: err }));
                    })
                );
            })
        );
    });

    getDescriptionInfo$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadUnitInfo),
            exhaustMap((action) => {
                return this._clinicalService
                    .getUnitInfo(action.unitId, action.hospId, action.token, action.formId)
                    .pipe(
                        map((result) =>
                            actions.loadUnitInfoSuccess({
                                unitInfo: result
                            })
                        ),
                        catchError((err) => {
                            if (err.status === 410) {
                                this._dialog.dialog.closeAll();
                                this.openExpirationLinkModal(action.unitId, action.token, true, false);
                            }
                            if (err.status === 301) {
                                this._dialog.dialog.closeAll();
                                this.openExpirationLinkModal(action.unitId, action.token, false, true);
                            } else {
                                return of(actions.loadUnitInfoFailure({ error: err }));
                            }
                        })
                    );
            })
        );
    });

    getNyuDepartments$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadNyuDepartments),
            exhaustMap((action) => {
                return this._clinicalService.getNyuDepartments().pipe(
                    map((result) =>
                        actions.loadNyuDepartmentsSuccess({
                            nyuDepartments: this.buildLookupdescription(result)
                        })
                    ),
                    catchError((err) => {
                        return of(actions.loadNyuDepartmentsFailure({ error: err }));
                    })
                );
            })
        );
    });

    getNyuBuildings$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadNyuBuildings),
            exhaustMap((action) => {
                return this._clinicalService.getNyuBuildings().pipe(
                    map((result) =>
                        actions.loadNyuBuildingsSuccess({
                            nyuBuildings: this.buildLookupdescription(result)
                        })
                    ),
                    catchError((err) => {
                        return of(actions.loadNyuBuildingsFailure({ error: err }));
                    })
                );
            })
        );
    });

    getNyuFloors$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadNyuFloors),
            exhaustMap((action) => {
                return this._clinicalService.getNyuFloors(action.buildingId).pipe(
                    map((result) =>
                        actions.loadNyuFloorsSuccess({
                            nyuFloors: this.buildLookupdescription(result)
                        })
                    ),
                    catchError((err) => {
                        return of(actions.loadNyuFloorsFailure({ error: err }));
                    })
                );
            })
        );
    });

    updateUnitStatus$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.updateUnitStatus),
            switchMap((action) =>
                this._clinicalService.updateUnitStatus(action.unitId, action.status).pipe(
                    map(() => actions.updateUnitStatusSuccess()),
                    catchError((err) => {
                        return of(actions.updateUnitStatusFailure({ error: err }));
                    })
                )
            )
        )
    );

    deleteUnit$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.deleteUnit),
            switchMap((action) =>
                this._clinicalService.deleteUnit(action.unitId).pipe(
                    map(() => actions.deleteUnitSuccess()),
                    catchError((err) => {
                        return of(actions.deleteUnitFailure({ error: err }));
                    })
                )
            )
        )
    );

    saveUnitExtraFields$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.saveUnitSuccess),
            switchMap((action) => {
                if (action.shouldSaveExtraFeilds) {
                    return this._clinicalService
                        .saveUnitExtraFields(
                            action.unit.unitId,
                            action.unit.departmentId,
                            action.unit.buildingId,
                            action.unit.floorId
                        )
                        .pipe(
                            map(() =>
                                actions.saveUnitExtraFieldsSuccess({
                                    unit: action.unit,
                                    saveUnitEnum: action.saveUnitEnum,
                                    isAccessSecureUDLinksEnabled: action.isAccessSecureUDLinksEnabled,
                                    isEditMode: action.isEditMode
                                })
                            ),
                            catchError((err) => {
                                this.loadingDialog.close();
                                return of(actions.saveUnitExtraFieldsFailure({ error: err }));
                            })
                        );
                } else {
                    this.loadingDialog.close();
                    return of(
                        actions.saveUnitExtraFieldsSuccess({
                            unit: action.unit,
                            saveUnitEnum: action.saveUnitEnum,
                            isAccessSecureUDLinksEnabled: action.isAccessSecureUDLinksEnabled,
                            isEditMode: action.isEditMode
                        })
                    );
                }
            })
        )
    );

    saveUnit$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.saveUnit),
            exhaustMap((action) => {
                this.loadingDialog = this._dialog.openDialog(UnitDescriptionLoadingDialogComponent, {
                    data: 'Loading required data...',
                    width: '60%',
                    hasBackdrop: true
                });
                return this._clinicalService.saveUnit(action.unit).pipe(
                    tap((response) => (action.unit.id = response.id)),
                    map((response) =>
                        actions.saveUnitSuccess({
                            unit: action.unit,
                            saveUnitEnum: action.saveUnitEnum,
                            shouldSaveExtraFeilds: action.shouldSaveExtraFeilds,
                            isAccessSecureUDLinksEnabled: action.isAccessSecureUDLinksEnabled,
                            isEditMode: action.isEditMode
                        })
                    ),
                    catchError((err) => {
                        this.loadingDialog.close();
                        return of(actions.saveUnitFailure({ error: err }));
                    })
                );
            })
        )
    );

    saveUnitExtraFieldsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(saveUnitExtraFieldsSuccess),
                tap((action) => {
                    if (action.saveUnitEnum === SaveUnitEnum.Accept) {
                        this.onDisplayMail(
                            'Unit Descriptions Confirmation',
                            action.unit.signatureEmail,
                            action.unit.id,
                            action.unit.token
                        );
                        this.goToComplete();
                    }
                    if (action.saveUnitEnum === SaveUnitEnum.Send && action.isEditMode === false) {
                        if (action.isAccessSecureUDLinksEnabled) {
                            this.openSendLinkModal(
                                'Unit Descriptions Form',
                                '',
                                action.unit.id,
                                action.unit.token,
                                false
                            );
                        } else {
                            this.onDisplayMail('Unit Descriptions Form', '', action.unit.id, action.unit.token);
                            this.goToComplete();
                        }
                    } else {
                        this.goToComplete();
                    }
                    this.loadingDialog.close();
                })
            ),
        { dispatch: false }
    );

    saveUnitFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(saveUnitFailure),
                map(() => this.toasterService.fail('Failed to Save Unit.'))
            );
        },
        { dispatch: false }
    );

    public openExpirationLinkModal(unitId: string, token: string, expiredLink: boolean, oldLink: boolean): void {
        const dialogRef = this._dialog.openDialog(SendLinkModalComponent, {
            data: { id: unitId, token: token, expiredLink: expiredLink, oldLink: oldLink },
            width: '500px'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.email && result.link && result.expirationDate) {
                this.sendEmail(result.email, result.link, result.expirationDate, token, result.oldLink);
            } else {
                this._router.navigateByUrl('/login');
            }
        });
    }

    public sendEmail(
        email: string,
        generatedLink: string,
        expirationDate: string,
        token: string,
        oldLink: boolean
    ): void {
        const request: UnitDescriptionEmail = {
            emailFor: email,
            token: token,
            generatedLink: generatedLink,
            expirationDate: expirationDate,
            subject: 'Aya Healthcare Unit Department Description Form',
            oldLink: oldLink
        };

        this._clinicalService.sendExpirationNotification(request).subscribe({
            next: () => {
                this.toasterService.success('Success! A new link has been sent to the original recipient.');

                setTimeout(() => {
                    this._router.navigateByUrl('/login');
                }, 3000);
            }
        });
    }

    public updateUnitUserEmail(email: string, token: string): void {
        const request: UnitDescriptionEmail = {
            emailFor: email,
            token: token,
            generatedLink: '',
            subject: '',
            oldLink: false
        };

        this._clinicalService.updateUserEmail(request).subscribe({
            next: () => {}
        });
    }

    private openSendLinkModal(title: string, to: string, unitId: string, token: string, expiredLink: boolean) {
        const dialogRef = this._dialog.openDialog(SendLinkModalComponent, {
            data: { id: unitId, token: token, expiredLink: expiredLink },
            width: '500px'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.email && result.link && result.expirationDate) {
                this.onDisplayMailWithGeneratedLink(title, result.email, result.link, result.expirationDate);
                this.updateUnitUserEmail(result.email, token);
            }
            this.goToComplete();
        });
    }

    private onDisplayMailWithGeneratedLink(title: string, to: string, generatedLink: string, expirationDate: string) {
        const environment = this._injector.get(DomainService).environment();
        const lotus = 'Lotus Connect';
        const aya = 'Aya Healthcare';
        const subject = (environment === 'LTS' ? lotus : aya) + ' - ' + title;

        const body = `
            Hello,

            Please ensure that this link is only shared with relevant individuals within your organization. Accessing the link will log your email in the edit history.

            UDD Title
            ${generatedLink}

            This link will expire in 14 days on ${expirationDate}.
        `;

        const encodedBody = encodeURIComponent(body);
        const mailTo = `mailto:${encodeURIComponent(to)}?subject=${encodeURIComponent(subject)}&body=${encodedBody}`;
        const linkElement = this._document.createElement('a');

        linkElement.setAttribute('href', mailTo);
        this._document.body.appendChild(linkElement);
        linkElement.click();
        this._document.body.removeChild(linkElement);
    }

    private onDisplayMail(title: string, to: string, id: string, token: string) {
        const hostName = `${this._window.location.protocol}//${this._window.location.host}/#/`;
        const address = to || '';
        const lotus = 'Lotus Connect';
        const aya = 'Aya Healthcare';
        const environment = this._injector.get(DomainService).environment();

        const subject = (environment === 'LTS' ? lotus : aya) + ` - ${title}`;
        const body = `${hostName}units?formId=${id}&contact=1&token=${token}`;

        const mailTo = `mailto:${encodeURIComponent(address)}?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(body)}`;

        const link = this._document.createElement('a');
        link.setAttribute('href', mailTo);
        this._document.body.appendChild(link);
        link.click();
        this._document.body.removeChild(link);
    }

    private goToComplete() {
        const url = `#/units-complete`;
        this._window.open(url, '_self');
    }

    private buildLookupdescription(nyuLookupList: NyuLookup[]): NyuLookup[] {
        nyuLookupList.forEach((item) => {
            item.description = `${item.code} - ${item.description}`;
        });
        return nyuLookupList;
    }

    private prepareParams(query: GridSearchQuery): { pagination: any; sortArgs: any } {
        const sortCondition =
            query.sort && query.sort.length
                ? query.sort.map((q) => {
                      return q;
                  })
                : query.sort;

        const pagination = {
            pageSize: query.take,
            skip: query.skip
        };

        const sortArgs = {
            sortField: sortCondition && sortCondition[0].field,
            sortType: sortCondition && (sortCondition[0].dir as SortTypes)
        };

        return { pagination, sortArgs };
    }

    constructor(
        private readonly _actions$: Actions,
        private readonly _clinicalService: ClinicalService,
        private readonly _store: Store,
        @Inject('Window') private readonly _window: Window,
        protected toasterService: ToasterService,
        private readonly _dialog: DialogService,
        private readonly _injector: Injector,
        private readonly _router: Router,
        @Inject(DOCUMENT) private readonly _document: Document
    ) {}
}
