import { createAction, props } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ContractRequirements } from 'src/app/shared/models/candidate';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ShiftListItem } from 'src/app/shifts/models/shift-list-item.model';

export const getHasContractAtPerDiemHospital = createAction(
    '[VENDOR CANDIDATES DETAILS COMPONENT] Get Whether Candidate Has Contract at Per Diem Hospital',
    props<{ candidateId: number }>()
);
export const getHasContractAtPerDiemHospitalSuccess = createAction(
    '[VENDOR CANDIDATES SERVICE] Get Whether Candidate Has Contract at Per Diem Hospital Success',
    props<{ hasContract: boolean }>()
);
export const getHasContractAtPerDiemHospitalFailure = createAction(
    '[VENDOR CANDIDATES SERVICE] Get Whether Candidate Has Contract at Per Diem Hospital Failure',
    props<{ error: unknown }>()
);

// FILES TAB
export const loadVendorCandidateFileList = createAction(
    '[LoadVendorCandidateFileList] Load Vendor Candidate File List',
    props<{ request: GridSearchQuery; candidateId: number }>()
);
export const loadVendorCandidateFileListSuccess = createAction(
    '[LoadVendorCandidateFileList] Load Vendor Candidate File List Success',
    props<{ response: GridDataResult; candidateId: number }>()
);
export const loadVendorCandidateFileListFailure = createAction(
    '[LoadVendorCandidateFileList] Load Vendor Candidate File List Failure',
    props<{ error: unknown }>()
);
export const deleteVendorCandidateFiles = createAction(
    '[deleteVendorCandidateFiles] Delete Vendor Candidate Files',
    props<{ candidateId: number; fileIds: number[] }>()
);
export const deleteVendorCandidateFilesSuccess = createAction(
    '[deleteVendorCandidateFiles] Delete Vendor Candidate Files Success',
    props<{ success: boolean }>()
);

// SHIFTS TAB
export const loadCurrentShiftList = createAction('[VENDOR CANDIDATES SHIFT TAB] Load Current Shift List');
export const loadCurrentShiftListSuccess = createAction(
    '[VENDOR CANDIDATES SHIFT TAB] Load Current Shift List Success',
    props<{ shifts: PagingToken<ShiftListItem[]> }>()
);
export const loadCurrentShiftListFailure = createAction(
    '[VENDOR CANDIDATES SHIFT TAB] Load Current Shift List Failure',
    props<{ error: unknown }>()
);
export const setCurrentShiftListSearchQuery = createAction(
    '[VENDOR CANDIDATES SHIFT TAB] Set Current Shift Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);
export const clearCurrentShiftList = createAction('[VENDOR CANDIDATES SHIFT TAB] Clear Current Shift List');

export const loadAvailableShiftList = createAction('[VENDOR CANDIDATES SHIFT TAB] Load Available Shift List');
export const loadAvailableShiftListSuccess = createAction(
    '[VENDOR CANDIDATES SHIFT TAB] Load Available Shift List Success',
    props<{ shifts: PagingToken<ShiftListItem[]> }>()
);
export const loadAvailableShiftListFailure = createAction(
    '[VENDOR CANDIDATES SHIFT TAB] Load Available Shift List Failure',
    props<{ error: unknown }>()
);
export const setAvailableShiftListSearchQuery = createAction(
    '[VENDOR CANDIDATES SHIFT TAB] Set Available Shift Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);
export const clearAvailableShiftList = createAction('[VENDOR CANDIDATES SHIFT TAB] Clear Available Shift List');

// CONTRACT REQUIREMENTS TAB
export const getContractRequirements = createAction(
    '[VENDOR CANDIDATE DETAILS COMPONENT] Get Contract Requirements',
    props<{ candidateId: number }>()
);
export const getContractRequirementsSuccess = createAction(
    '[CONTRACT SERVICE] Get Contract Requirements Successful',
    props<{ contracts: ContractRequirements }>()
);
export const getContractRequirementsFail = createAction(
    '[CONTRACT SERVICE] Get Contract Requirements Failed',
    props<{ error: unknown }>()
);

export const exportContractRequirements = createAction(
    '[EXPORT CONTRACT REQUIREMENTS] Export Contract Requirements',
    props<{ candidateId: number; candidateName: string }>()
);
export const exportContractRequirementsSuccess = createAction(
    '[EXPORT CONTRACT REQUIREMENTS] Export Contract Requirements Successful',
    props<{ result: Blob }>()
);
export const exportContractRequirementsFail = createAction(
    '[EXPORT CONTRACT REQUIREMENTS] Export Contract Requirements Failed',
    props<{ error: unknown }>()
);

export const checkCandidateFiles = createAction(
    '[VENDOR CANDIDATE SUBMITTAL DETAILS] Check Candidate Files',
    props<{ candidateId: number; submittalId: string; jobId: number; accountManagerId: number }>()
);

export const checkCandidateFilesSuccess = createAction(
    '[VENDOR CANDIDATE SUBMITTAL DETAILS] Check Candidate Files Success',
    props<{ success: boolean; submittalId: string; candidateId: number; jobId: number; accountManagerId: number }>()
);

export const checkCandidateFilesFailure = createAction(
    '[VENDOR CANDIDATE SUBMITTAL DETAILS] Check Candidate Files Failure',
    props<{ error: unknown }>()
);
