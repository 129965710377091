import { Component, OnInit, inject } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ContractGroupDefinition } from 'src/app/shared/models/custom-fields/contract-group-definition.model';
import { CustomFieldService } from 'src/app/shared/services/custom-field.service';
import { catchError, exhaustMap, takeUntil, tap } from 'rxjs/operators';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { BehaviorSubject, EMPTY, Observable, Subject, of } from 'rxjs';
import { CustomFieldValueType } from 'src/app/shared/models/custom-fields/custom-field-value-type.enum';
import { ToasterService } from 'src/app/core/services';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';

@Component({
    selector: 'ayac-custom-field-details',
    templateUrl: './custom-field-details.component.html',
    styleUrls: ['./custom-field-details.component.scss']
})
export class CustomFieldDetailsComponent extends UnsubscribeOnDestroy implements OnInit {
    fieldDefinition: ContractGroupDefinition;
    isSaving = false;
    isNew = true;
    readonly _hasValues = new BehaviorSubject(false);
    hasValues$ = this._hasValues.asObservable();
    contractGroupId: number | null = null;
    readonly featureFlag = FeatureFlag;
    dropdownOptions: ContractGroupDefinition[] = [];

    saveSubject$: Subject<boolean> = new Subject();
    submitSave$ = this.submit();

    customFieldOptions = [
        { value: 0, label: 'Text' },
        { value: 1, label: 'Number' },
        { value: 2, label: 'Date' },
        { value: 3, label: 'Dropdown' }
    ];

    customFielsAdded = [
        { value: 4, label: 'Rich Dropdown' },
        { value: 5, label: 'Child Dropdown' }
    ];

    // will be replaced with api call to get list of lobs
    linesOfBusiness = [
        { value: null, label: 'None' },
        { value: 9, label: 'Access Control' },
        { value: 10, label: 'Interim Leadership' },
        { value: 3, label: 'Locums' },
        { value: 4, label: 'Non-Clinical' },
        { value: 11, label: 'Payroll Services' },
        { value: 1, label: 'Per Diem' },
        { value: 6, label: 'Permanent' },
        { value: 2, label: 'Travel' },
        { value: 7, label: 'WFD-C' },
        { value: 8, label: 'WFD-NC' }
    ];

    //#region Formly Setup
    form = new UntypedFormGroup({});

    model = {
        type: 0,
        label: '',
        entityType: null,
        synchronization: 0,
        lineOfBusiness: null,
        sortOrder: 0,
        options: [],
        required: false,
        descriptionOnly: false,
        richOptions: [],
        parentChildOptions: [],
        parentDropdownDefinitionId: 0,
        helpText: ''
    };

    originalFields: FormlyFieldConfig[] = [
        {
            key: 'type',
            type: 'select',
            props: {
                label: 'Value Type',
                required: true,
                options: this.customFieldOptions
            },
            expressionProperties: {
                'props.disabled': this.hasValues$
            }
        },
        {
            key: 'label',
            type: 'input',
            id: 'custom-field-label-input',
            props: {
                label: 'Label',
                required: true,
                maxLength: 150,
                description: 'Max Length 150 characters'
            },
            validators: {
                name: {
                    expression: (c: AbstractControl) => /^[^.:]*$/.test(c.value),
                    message: () => `Name cannot contain periods "." or colons ":" characters.`
                }
            }
        },
        {
            key: 'entityType',
            type: 'select',
            props: {
                label: 'Module',
                required: true,
                options: [
                    { value: 0, label: 'Job Details' },
                    { value: 1, label: 'Worker Profile' },
                    { value: 2, label: 'Job Template' }
                ]
            },
            expressionProperties: {
                'props.disabled': this.hasValues$
            }
        },
        {
            key: 'synchronization',
            type: 'select',
            props: {
                label: 'Synchronization',
                required: true,
                options: [
                    { value: 0, label: 'None' },
                    { value: 1, label: 'Synchronized' }
                ]
            },
            expressionProperties: {
                // 'props.disabled': this.hasValues$,
            }
        },
        {
            key: 'lineOfBusiness',
            type: 'select',
            props: {
                label: 'Line of Business',
                required: false,
                options: this.linesOfBusiness
            },
            expressionProperties: {
                // 'props.disabled': this.hasValues$,
            }
        },
        {
            key: 'required',
            type: 'checkbox',
            defaultValue: false,
            props: {
                label: 'Required'
            }
        },
        {
            key: 'descriptionOnly',
            type: 'checkbox',
            defaultValue: false,
            props: {
                label: 'Description Only'
            },
            expressionProperties: {
                hide: `model.type !== ${CustomFieldValueType.RichDropdown} && model.type !== ${CustomFieldValueType.ChildDropdown}`
            }
        },
        {
            key: 'sortOrder',
            type: 'number',
            defaultValue: false,
            props: {
                label: 'Sort Order'
            }
        },
        {
            key: 'options',
            type: 'options-input',
            props: {
                label: 'Option Value',
                hasValues: this.hasValues$,
                required: true // Handled by hooks > onInit
            },
            expressionProperties: {
                hide: `model.type !== ${CustomFieldValueType.Dropdown}`
            },
            hooks: {
                // This ensures that the form cannot be saved if there are no options
                onInit: (field) => {
                    field.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((changes) => {
                        if (this.model.type !== CustomFieldValueType.Dropdown) {
                            return;
                        }
                        // If there are no options, then disable saving!
                        if (this.model.options.length) {
                            this.form.get('options').setErrors(null);
                        } else {
                            if (!this.form.get('options')) {
                                return;
                            }
                            this.form.get('options').setErrors({ required: true });
                        }
                    });
                }
            }
        },
        {
            key: 'richOptions',
            type: 'rich-options-input',
            props: {
                label: 'Option Value',
                hasValues: this.hasValues$,
                required: true // Handled by hooks > onInit
            },
            expressionProperties: {
                hide: `model.type !== ${CustomFieldValueType.RichDropdown}`
            },
            hooks: {
                // This ensures that the form cannot be saved if there are no options
                onInit: (field) => {
                    field.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((changes) => {
                        if (this.model.type !== CustomFieldValueType.RichDropdown) {
                            return;
                        }
                        // If there are no richOptions, then disable saving!
                        if (this.model?.richOptions?.length) {
                            this.form.get('richOptions')?.setErrors(null);
                        } else {
                            if (!this.form.get('richOptions')) {
                                return;
                            }
                            this.form.get('richOptions')?.setErrors({ required: true });
                        }
                    });
                }
            }
        },
        {
            key: 'childDropdown',
            type: 'child-dropdown-input',
            props: {
                label: 'Option Value',
                hasValues: this.hasValues$,
                required: true, // Handled by hooks > onInit,
                options: of()
            },
            expressionProperties: {
                hide: `model.type !== ${CustomFieldValueType.ChildDropdown}`
            },
            hooks: {
                // This ensures that the form cannot be saved if there are no options
                onInit: (field) => {
                    field.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((changes) => {
                        if (this.model.type !== CustomFieldValueType.ChildDropdown) {
                            return;
                        }
                        // If there are no childDropdownOptions, then disable saving!
                        if (this.model.parentChildOptions?.length) {
                            this.form.get('childDropdown')?.setErrors(null);
                        } else {
                            if (!this.form.get('childDropdown')) {
                                return;
                            }
                            this.form.get('childDropdown')?.setErrors({ required: true });
                        }
                    });
                }
            }
        }
    ];

    fields: FormlyFieldConfig[] = [];

    newFields: FormlyFieldConfig[] = [
        {
            key: 'helpText',
            type: 'textarea',
            props: {
                label: 'Help Text',
                placeholder: 'Describe Field',
                maxLength: 250,
                rows: 4,
                description: 'Max Length 250 characters'
            }
        }
    ];

    options: FormlyFormOptions = {
        formState: {
            selectOptionsData: {}
        }
    };

    //#endregion

    private featureManager = inject(LDFeatureManager);

    constructor(
        private readonly _cfService: CustomFieldService,
        private readonly _route: ActivatedRoute,
        private readonly _location: Location,
        private readonly _permissionsService: PermissionsService,
        private readonly toaster: ToasterService
    ) {
        super();
    }

    ngOnInit() {
        const handleNewFields = (field: FormlyFieldConfig, isEnabled: boolean): void => {
            const fieldSet = new Set(this.originalFields);
            const handle = isEnabled ? () => fieldSet.add(field) : () => fieldSet.delete(field);
            handle();
            this.fields = Array.from(fieldSet);
        };

        this.featureManager
            .isEnabled(FeatureFlag.EnableCustomFieldsHelpText)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                handleNewFields(this.newFields[0], flagIsEnabled);
            });

        if (this._permissionsService.customFieldsAdmin('edit')) {
            this.initField();
        }

        const handleFeatureFlag = (type: { value: number; label: string }, isEnabled: boolean): void => {
            const customFieldOptionsSet = new Set(this.customFieldOptions);
            const handle = isEnabled ? () => customFieldOptionsSet.add(type) : () => customFieldOptionsSet.delete(type);
            handle();
            this.customFieldOptions = Array.from(customFieldOptionsSet).sort((a, b) => a.value - b.value);
            this.fields[0].props.options = this.customFieldOptions;
        };

        this.featureManager
            .isEnabled(FeatureFlag.EnableRichDropdownCustomField)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                handleFeatureFlag(this.customFielsAdded[0], flagIsEnabled);
            });

        this.featureManager
            .isEnabled(FeatureFlag.EnableCascadingDropdownCustomField)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                handleFeatureFlag(this.customFielsAdded[1], flagIsEnabled);
            });
    }

    initField(): void {
        this._route.data.pipe(takeUntil(this.d$)).subscribe((result) => {
            this.contractGroupId = result.data.contractGroupId;
            this.isNew = result.data.isNew;

            if (!this.isNew) {
                this.checkFieldHasValues(result.data.field);

                this.fieldDefinition = result.data.field as ContractGroupDefinition;
                this.model.label = this.fieldDefinition.name;
                this.model.entityType = this.fieldDefinition.entityType;
                this.model.type = this.fieldDefinition.valueType;
                this.model.synchronization = this.fieldDefinition.synchronization;
                this.model.lineOfBusiness = this.fieldDefinition.lineOfBusiness;
                this.model.required = this.fieldDefinition.required;
                this.model.descriptionOnly = this.fieldDefinition.descriptionOnly;
                this.model.sortOrder = this.fieldDefinition.sortOrder;
                this.model.parentDropdownDefinitionId = this.fieldDefinition.parentDropdownDefinitionId;
                this.fieldDefinition.options && (this.model.options = this.fieldDefinition.options);
                this.fieldDefinition.richOptions && (this.model.richOptions = this.fieldDefinition.richOptions);
                this.fieldDefinition.parentChildOptions &&
                    (this.model.parentChildOptions = this.fieldDefinition.parentChildOptions);
                this.model.helpText = this.fieldDefinition.helpText;
            }
        });
    }

    checkFieldHasValues(field: ContractGroupDefinition): void {
        this._cfService
            .checkFieldHasValues(field.id)
            .pipe(takeUntil(this.d$))
            .subscribe((hasValues) => {
                this._hasValues.next(hasValues);
            });
    }

    submit(): Observable<number> {
        return this.saveSubject$.pipe(
            tap(() => (this.isSaving = true)),
            exhaustMap(() => {
                this.setDefinition();
                return this._cfService.saveFieldDefinition(this.fieldDefinition, this.contractGroupId).pipe(
                    catchError((err) => {
                        const error = err?.message || 'Error saving custom field';
                        this.toaster.fail(error);
                        this.isSaving = false;
                        return EMPTY;
                    })
                );
            }),
            tap(() => this.back(null))
        );
    }

    setDefinition(): void {
        const setFieldDefinition = this.isNew
            ? () => {
                  this.fieldDefinition = {
                      contractGroupId: this.contractGroupId,
                      name: this.model.label,
                      valueType: this.model.type,
                      entityType: this.model.entityType,
                      synchronization: this.model.synchronization,
                      lineOfBusiness: this.model.lineOfBusiness,
                      required: this.model.required,
                      sortOrder: this.model.sortOrder,
                      parentDropdownDefinitionId: this.model.parentDropdownDefinitionId,
                      helpText: this.model.helpText
                  };
              }
            : () => {
                  this.fieldDefinition.name = this.model.label;
                  this.fieldDefinition.valueType = this.model.type;
                  this.fieldDefinition.entityType = this.model.entityType;
                  this.fieldDefinition.synchronization = this.model.synchronization;
                  this.fieldDefinition.lineOfBusiness = this.model.lineOfBusiness;
                  this.fieldDefinition.required = this.model.required;
                  this.fieldDefinition.sortOrder = this.model.sortOrder;
                  this.fieldDefinition.parentDropdownDefinitionId = this.model.parentDropdownDefinitionId;
                  this.fieldDefinition.helpText = this.model.helpText;
              };

        setFieldDefinition();

        if (
            this.model.type === CustomFieldValueType.RichDropdown ||
            this.model.type === CustomFieldValueType.ChildDropdown
        ) {
            this.fieldDefinition.descriptionOnly = this.model.descriptionOnly;
        }
        this.model.options?.length && (this.fieldDefinition.options = this.model.options);
        this.model.richOptions?.length && (this.fieldDefinition.richOptions = this.model.richOptions);
        this.model.parentChildOptions?.length &&
            (this.fieldDefinition.parentChildOptions = this.model.parentChildOptions);
    }

    back(event: Event): void {
        if (event) {
            event.preventDefault();
        }
        this._location.back();
    }
}
