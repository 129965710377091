import { MatDialogRef } from '@angular/material/dialog';
import { createAction, props } from '@ngrx/store';
import { CreateNewRoleDialogComponent } from '../../dialogs/create-new-role-dialog.component';
import { Role, UpdateRole } from '../../models';

export const COMPONENT_SOURCE = '[ROLE PERMISSIONS COMPONENT]';
export const API_SOURCE = '[ROLE PERMISSIONS SERVICE API]';

export const getRoles = createAction(`${COMPONENT_SOURCE} Get Roles`);
export const getRolesSuccess = createAction(`${API_SOURCE} Get Roles Success`, props<{ roles: Role[] }>());
export const getRolesFailure = createAction(`${API_SOURCE} Get Roles Failure`, props<{ error: unknown }>());

export const resetRoles = createAction(`${COMPONENT_SOURCE} Reset Roles`, props<{ id: number }>());

export const getRolePermissions = createAction(`${COMPONENT_SOURCE} Get Role Permissions`, props<{ id: number }>());
export const getRolePermissionsSuccess = createAction(
    `${API_SOURCE} Get Role Permissions Success`,
    props<{ roles: Role[] }>()
);
export const getRolePermissionsFailure = createAction(
    `${API_SOURCE} Get Role Permissions Failure`,
    props<{ error: unknown }>()
);

export const updateRole = createAction(`${COMPONENT_SOURCE} Update Role`, props<{ updatedRole: UpdateRole }>());
export const updateRoleSuccess = createAction(`${API_SOURCE} Update Role Success`);
export const updateRoleFailure = createAction(`${API_SOURCE} Update Role Failure`, props<{ error: unknown }>());

export const deleteRole = createAction(`${COMPONENT_SOURCE} Delete Role`, props<{ id: number }>());
export const deleteRoleSuccess = createAction(`${API_SOURCE} Delete Role Success`, props<{ roles: Role[] }>());
export const deleteRoleFailure = createAction(`${API_SOURCE} Delete Role Failure`, props<{ error: unknown }>());

export const createRole = createAction(
    `${COMPONENT_SOURCE} Create New Role`,
    props<{ name: string; dialog: MatDialogRef<CreateNewRoleDialogComponent> }>()
);
export const createRoleSuccess = createAction(`${API_SOURCE} Create New Role Success`, props<{ roles: Role[] }>());
export const createRoleFailure = createAction(`${API_SOURCE} Create New Role Failure`, props<{ error: unknown }>());
