import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

export interface IDialogOptions<TDialogData> {
    data?: TDialogData;
    width?: string;
    height?: string;
    hasBackdrop?: boolean;
    disableClose?: boolean;
}

export interface IConfirmationDialogOptions extends IDialogOptions<IConfirmationDialogData> {}

export interface IConfirmationDialogData {
    title: string;
    text: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    isWarn?: boolean;
    isHtml?: boolean;
    isDeleteConfirmation?: boolean;
    username?: string;
}

export interface IConfirmationReasonDialogOptions {
    title: string;
    text: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    isWarn?: boolean;
    isHtml?: boolean;

    isInputRequired?: boolean;
    inputLabel?: string;
    inputPlaceholder?: string;
    inputMaxLength?: number;
}

export class DialogRef<T, R> {
    constructor(private dialogRef: MatDialogRef<T, R>) {}

    public asAwaitable = () => this.dialogRef.afterClosed().pipe(first()).toPromise();

    public afterClosed = () => this.dialogRef.afterClosed();

    public afterOpened = () => this.dialogRef.afterOpened();

    public beforeClosed = () => this.dialogRef.beforeClosed();

    public close = (dialogResult?: R) => this.dialogRef.close(dialogResult);
}
