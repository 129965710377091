export enum AyaWannaConnectFeatureFlags {
    ConnectClientDisplayNewSubmittalStatus = 'CONNECT_CLIENT_DISPLAY_NEW_SUBMITTAL_STATUS',
    ReplaceCurrentHelpSectionWithPendoResourceCenter = 'REPLACE_CURRENT_HELP_SECTION_WITH_PENDO_RESOURCE_CENTER',
    ExtJobApprovalsCreateExtRuleUnitFix = 'EXT_JOB_APPROVALS_CREATE_EXT_RULE_UNIT_FIX',
    TimecardDisplayBillRateInformation = 'TIMECARD_DISPLAY_BILL_RATE_INFORMATION',
    ClientEmailSubscriptions = 'CONNECT_CLIENT_EMAIL_SUBSCRIPTIONS',
    ConnectClientAdminSettingsEnabled = 'CONNECT_CLIENT_ADMIN_SETTINGS_ENABLED',
    ConnectInvoicingShowWorkWeek = 'CONNECT_INVOICING_SHOW_WORK_WEEK',
    ConnectTimecardListShowBillGross = 'CONNECT_TIMECARD_LIST_SHOW_BILL_GROSS',
    ConnectInvoicingTemplateMethodCallPipeFix = 'CONNECT_INVOICING_TEMPLATE_METHOD_CALL_PIPE_FIX',
    DisplayFacilityNameAndAddress = 'DISPLAY_FACILITY_NAME_AND_ADDRESS',
    BillableInvoiceAvailableCanSubscribe = 'BILLABLE_INVOICE_AVAILABLE_CAN_SUBSCRIBE',
    InvoiceDisputeOpenCanSubscribe = 'INVOICE_DISPUTE_OPEN_CAN_SUBSCRIPTION',
    InvoiceDisputeCommentAddedCanSubscribe = 'INVOICE_DISPUTE_COMMENT_ADDED_CAN_SUBSCRIPTION',
    InvoiceDisputeResolvedCanSubscribe = 'INVOICE_DISPUTE_RESOLVED_CAN_SUBSCRIPTION',
    BillableInvoiceApprovedCanSubscribe = 'BILLABLE_INVOICE_APPROVED_CAN_SUBSCRIPTION',
    JobsApprovedRejectedClosedCanSubscribe = 'JOBS_APPROVED_REJECTED_CLOSED_CAN_SUBSCRIBE',
    JobDetailsEditedCanSubscribe = 'JOB_DETAILS_EDITED_CAN_SUBSCRIBE',
    JobApprovalNeededCanSubscribe = 'JOB_APPROVAL_NEEDED_CAN_SUBSCRIBE',
    JobApprovalNeededReminder24HCanSubscribe = 'JOB_APPROVAL_NEEDED_REMINDER_24H_CAN_SUBSCRIBE',
    TimecardLiveEdit = 'CLIENT_TIMECARD_LIVE_EDIT',
    UpdateTimecardBillDetailsOnTimecardSave = 'UPDATE_TIMECARD_BILL_DETAILS_ON_TIMECARD_SAVE',
    ClientConnectCompliance = 'CLIENT_CONNECT_COMPLIANCE',
    VendorConnectCompliance = 'VENDOR_CONNECT_COMPLIANCE',
    EnhancedDocumentExport = 'CLIENT_CONNECT_ENHANCED_DOCUMENT_EXPORT',
    LimitingProfessionsAndSpecialtiesPerFacility = 'LIMITING_PROFESSIONS_AND_SPECIALTIES_PER_FACILITY',
}
