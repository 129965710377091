import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GridDataResult } from 'src/app/shared/grid/models/grid-data-result.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes } from 'src/app/shared/models';
import { VendorCandidateState, vendorCandidateDetailsStoreKey } from '../state/vendor-candidate-details.state';

export const selectFeature = createFeatureSelector<VendorCandidateState>(vendorCandidateDetailsStoreKey);

export const selectLoading = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.candidateDetailsState.loadingState
);

export const selectHasContractAtPerDiemHospital = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.candidateDetailsState.hasContractAtPerDiemHospital
);

export const selectContractsLoading = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.contractRequirementsState.loadingState
);

export const selectContractRequirements = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.contractRequirementsState.contractRequirements
);

export const selectVendorCandidateFileListRequest = createSelector(
    selectFeature,
    (state: VendorCandidateState): GridSearchQuery => state.candidateFileListState.request
);

export const selectVendorCandidateFileListResponse = createSelector(
    selectFeature,
    (state: VendorCandidateState): GridDataResult => state.candidateFileListState.response
);

export const selectVendorCandidateFileListIsLoading = createSelector(
    selectFeature,
    (state: VendorCandidateState): boolean => state.candidateFileListState.isLoading
);

export const selectVendorCandidateFileIsDeleting = createSelector(
    selectFeature,
    (state: VendorCandidateState): boolean => state.candidateFileListState.isDeleting
);

export const selectVendorCandidateDeletingFileIds = createSelector(
    selectFeature,
    (state: VendorCandidateState): number[] => state.candidateFileListState.deletingFileIds
);

export const selectVendorCandidateFileDeleteSuccess = createSelector(
    selectFeature,
    (state: VendorCandidateState): boolean => state.candidateFileListState.isDeleteSuccess
);

export const selectCurrentShiftList = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.currentShiftListState.shifts
);

export const selectCurrentShiftListIsLoading = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.currentShiftListState.loadingState === LoadingTypes.LOADING
);

export const selectCurrentShiftListQuery = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.currentShiftListState.gridSearchQuery
);

export const selectCurrentShiftListView = createSelector(selectCurrentShiftList, (state) => {
    return {
        data: state.data,
        total: state.total
    };
});

export const selectAvailableShiftList = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.availableShiftListState.shifts
);

export const selectAvailableShiftListIsLoading = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.availableShiftListState.loadingState === LoadingTypes.LOADING
);

export const selectAvailableShiftListQuery = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.availableShiftListState.gridSearchQuery
);

export const selectAvailableShiftListView = createSelector(selectAvailableShiftList, (state) => {
    return {
        data: state.data,
        total: state.total
    };
});

export const selectFileSize = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.candidateFileListState.totalFileSize
);
