import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { resizeObservable } from 'src/app/shared/utilities/resize-observable';

@Component({
    selector: 'ayac-limit-lines-text',
    templateUrl: './limit-lines-text.component.html',
    styleUrls: ['./limit-lines-text.component.scss'],
})
export class LimitLinesTextComponent extends UnsubscribeOnDestroy implements AfterViewInit {
    @Input() data: string[];
    @Input() limit: number = 2;
    @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
    @HostBinding('style.maxHeight') hostHeight?;

    maxItems: number = 0;
    private containerElement;
    public text = '';

    constructor(private renderer: Renderer2) {
        super();
    }

    ngAfterViewInit(): void {
        this.calculateAndUpdateLines();

        resizeObservable(this.wrapper.nativeElement)
            .pipe(
                takeUntil(this.d$),
                debounceTime(100),
                distinctUntilChanged((previous: any, current: any) => {
                    const previousWidth = previous[0].contentRect.width;
                    const currentWidth = current[0].contentRect.width;
                    return currentWidth == previousWidth
                })
            )
            .subscribe(() => {
                this.calculateAndUpdateLines();
            })
    }

    public updateText() {
        let text = this.data.slice(0, this.maxItems).join(', ');
        const moreCount = this.data.length - this.maxItems;
        if (moreCount > 0) {
            text += `, +${moreCount} more`;
        }
        this.text = text;
    }

    public calculateAndUpdateLines() {
        this.removeContainer();
        this.createContainer();

        const moreLabel = `, +${this.data.length} more`;
        const moreElement = this.renderer.createText(moreLabel);
        this.renderer.appendChild(this.containerElement, moreElement);

        const lineHeight = this.containerHeight;
        this.hostHeight = `${lineHeight * 2}px`;

        this.maxItems = 0;
        for (let t of this.data) {
            const element = this.renderer.createText(`, ${t}`);
            this.insertIntoContainer(element, moreElement);

            const lines = Math.floor(this.containerHeight / lineHeight);

            if (lines > this.limit) {
                break;
            }
            this.maxItems++;
        }

        this.removeContainer();

        this.updateText();
    }

    private createContainer() {
        this.containerElement = this.renderer.createElement('div');
        this.renderer.setStyle(this.containerElement, 'visibility', 'hidden');
        this.renderer.setStyle(this.containerElement, 'position', 'relative');
        this.renderer.setStyle(this.containerElement, 'z-index', '-1');
        
        this.renderer.appendChild(this.wrapper.nativeElement, this.containerElement);
    }

    private removeContainer() {
        if (this.containerElement) {
            this.renderer.removeChild(this.wrapper.nativeElement, this.containerElement);
        }
    }

    private insertIntoContainer(element, beforeElement) {
        this.renderer.insertBefore(this.containerElement, element, beforeElement);
    }

    private get containerHeight(): number {
        return this.containerElement.offsetHeight;
    }
}
