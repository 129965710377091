import { SubmittalStatusLookup } from 'src/app/shared/models/submittals/lookups/submittal-status-lookup.model';
import { FacilityLookup } from 'src/app/shared/models/submittals/lookups/facility-lookup.model';
import { SystemLookup } from 'src/app/shared/models/submittals/lookups/system-lookup.model';
import { UnitLookup } from 'src/app/shared/models/submittals/lookups/unit-lookup.model';
import { LoadingTypes } from 'src/app/shared/models';
import {
    loadSubmittalsLookupsSecurity,
    loadSubmittalsLookupsSecuritySuccess,
    loadSubmittalsLookupsSecurityFail,
    loadSubmittalsLookupsStatuses,
    loadSubmittalsLookupsStatusesSuccess,
    mapStatus,
    loadSubmittalDeclineReasonsSuccess,
    loadIrpSubmittalStatusesSuccess
} from 'src/app/submittals/store/submittals.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { SubmittalStatusMappedLookup } from 'src/app/shared/models/submittals/lookups/submittal-status-mapped-lookup.model';
import { PortalHeaderBadge } from 'src/app/shared/models/page-header-badge.model';
import { SubmittalDeclineReasons } from 'src/app/shared/models/submittals/lookups/submittal-decline-reasons.model';
import { SubmittalStatuses } from 'src/app/shared/models/submittals';

export interface SubmittalLookupsState {
    facilities: FacilityLookup[];
    systems: SystemLookup[];
    units: UnitLookup[];
    statuses: SubmittalStatusLookup[];
    mappedStatuses: SubmittalStatusMappedLookup[];
    currentStatus: SubmittalStatusMappedLookup;
    statusBadge: PortalHeaderBadge;
    submittalDetailsDropdownStatuses: SubmittalStatusMappedLookup[];
    loadingSecurity: LoadingTypes;
    loadingStatuses: LoadingTypes;
    declineReasons: SubmittalDeclineReasons[];
    irpStatuses: SubmittalStatusLookup[];
    irpSubmittalDetailsDropdownStatuses: SubmittalStatusLookup[];
}

const initialState: SubmittalLookupsState = {
    facilities: [],
    systems: [],
    units: [],
    statuses: [],
    mappedStatuses: [],
    submittalDetailsDropdownStatuses: [],
    currentStatus: null,
    statusBadge: null,
    loadingSecurity: LoadingTypes.INIT,
    loadingStatuses: LoadingTypes.INIT,
    declineReasons: [],
    irpStatuses: [],
    irpSubmittalDetailsDropdownStatuses: []
};

export const submittalsLookupsReducer = (reducerState: SubmittalLookupsState, reducerAction: Action) => {
    return createReducer(
        initialState,
        on(
            loadSubmittalsLookupsSecurity,
            (state): SubmittalLookupsState => ({
                ...state,
                loadingSecurity: LoadingTypes.LOADING
            })
        ),
        on(
            loadSubmittalsLookupsSecuritySuccess,
            (state, action): SubmittalLookupsState => ({
                ...state,
                facilities: action.facilities,
                systems: action.systems,
                units: action.units,
                loadingSecurity: LoadingTypes.LOADED
            })
        ),
        on(
            loadSubmittalsLookupsSecurityFail,
            (state): SubmittalLookupsState => ({
                ...state,
                loadingSecurity: LoadingTypes.LOADED
            })
        ),
        on(
            loadSubmittalsLookupsStatuses,
            (state): SubmittalLookupsState => ({
                ...state,
                loadingStatuses: LoadingTypes.LOADING
            })
        ),
        on(loadSubmittalsLookupsStatusesSuccess, (state, action): SubmittalLookupsState => {
            const mappedStatuses = action.statuses.map((status) => mapStatusLookup(status));

            const uniqueStatuses: SubmittalStatusMappedLookup[] = [];
            const map = new Map();
            mappedStatuses.forEach((status) => {
                if (!map.has(status.mappedLookup.id)) {
                    map.set(status.mappedLookup.id, true);
                    uniqueStatuses.push({
                        id: status.mappedLookup.id,
                        name: status.mappedLookup.name,
                        tooltip: status.mappedLookup.tooltip,
                        subStatuses: status.mappedLookup.subStatuses
                    });
                }
            });

            const submittalDetailsDropdownStatuses = uniqueStatuses.filter(
                (status) =>
                    status.name === 'More Information Needed' ||
                    status.name === 'Pending Review' ||
                    status.name === 'Qualified' ||
                    status.name === 'Interview' ||
                    status.name === 'Under Review by Hiring Manager' ||
                    status.name === 'Waiting on Approval' ||
                    status.name === 'Additional Review Needed'
            );

            return {
                ...state,
                statuses: action.statuses,
                mappedStatuses: uniqueStatuses,
                submittalDetailsDropdownStatuses,
                loadingStatuses: LoadingTypes.LOADED
            };
        }),
        on(loadIrpSubmittalStatusesSuccess, (state, action): SubmittalLookupsState => {
            const mappedStatuses = action.irpStatuses.map((status) => mapStatusLookup(status));

            const uniqueStatuses: SubmittalStatusLookup[] = [];
            const map = new Map();
            mappedStatuses.forEach((status) => {
                if (!map.has(status.mappedLookup.id)) {
                    map.set(status.mappedLookup.id, true);
                    uniqueStatuses.push({
                        id: status.mappedLookup.id,
                        name: status.mappedLookup.name,
                        tooltip: status.mappedLookup.tooltip,
                        subStatuses: status.mappedLookup.subStatuses
                    });
                }
            });

            const submittalDetailsDropdownStatuses = uniqueStatuses.filter(
                (status) =>
                    status.name === 'Pending Review' || status.name === 'Qualified' || status.name === 'Interview'
            );

            return {
                ...state,
                irpStatuses: action.irpStatuses,
                irpSubmittalDetailsDropdownStatuses: submittalDetailsDropdownStatuses,
                loadingStatuses: LoadingTypes.LOADED
            };
        }),
        on(
            loadSubmittalsLookupsSecurityFail,
            (state): SubmittalLookupsState => ({
                ...state,
                loadingStatuses: LoadingTypes.LOADED
            })
        ),
        on(mapStatus, (state, action): SubmittalLookupsState => {
            const lookupStatus = state.statuses.find((s) => s.id === action.id);
            const status = mapStatusLookup(lookupStatus);

            return {
                ...state,
                currentStatus: status.mappedLookup,
                statusBadge: status.statusBadge
            };
        }),
        on(
            loadSubmittalDeclineReasonsSuccess,
            (state, action): SubmittalLookupsState => ({
                ...state,
                declineReasons: action.declineReasons
            })
        )
    )(reducerState, reducerAction);
};

export const reducer = (state: SubmittalLookupsState | undefined, action: Action) => {
    return submittalsLookupsReducer(state, action);
};

const mapStatusLookup = (status: SubmittalStatusLookup) => {
    const mappedLookup: SubmittalStatusMappedLookup = {
        id: status.id,
        name: status.name,
        tooltip: status.tooltip ?? status.name,
        subStatuses: status.subStatuses
    };

    const statusBadge: PortalHeaderBadge = {
        displayText: status.name,
        backgroundColor: null
    };

    switch (status.name) {
        case 'Pending Review':
            statusBadge.backgroundColor = '#f3be42';
            break;
        case 'Qualified':
            statusBadge.backgroundColor = '#48927a';
            break;
        case 'Interview':
            statusBadge.backgroundColor = '#e67a3a';
            break;
        case 'Offer':
            statusBadge.backgroundColor = '#4690b0';
            break;
        case 'Declined':
            statusBadge.backgroundColor = '#e04f4f';
            break;
        default:
            statusBadge.backgroundColor = '#000000';
    }

    return { mappedLookup, statusBadge };
};
