import { Injectable, Injector, Inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import {
    map,
    exhaustMap,
    catchError,
    tap,
    switchMap,
    withLatestFrom,
    filter,
    mergeMap,
    take,
    debounceTime
} from 'rxjs/operators';
import {
    CoreActionTypes,
    LoginSuccess,
    LoginFail,
    Logout,
    LoadPermissions,
    LoadMenuSuccess,
    LoadMenuFail,
    ResetPassword,
    ResetPasswordSuccess,
    ResetPasswordFail,
    LoadFeaturedLogoFail,
    LoadFeaturedLogoSuccess,
    LoadFeaturedLogo,
    LoadSystemIdSuccess,
    LoadSystemIdFail,
    LoadSystemFieldsSuccess,
    LoadSystemFieldsFail,
    LoadCustomSystemFieldsSuccess,
    LoadCustomSystemFieldsFail,
    LoadCustomSystemFields,
    UpdateMenu,
    ForgotPassword,
    ForgotPasswordSuccess,
    ForgotPasswordFail,
    RequestPortalUserInfoSuccess,
    RequestPortalUserInfoFailure,
    GetTermsAndConditionsSuccess,
    GetTermsAndConditionsFailure,
    AcceptTermsAndConditionsSuccess,
    AcceptTermsAndConditionsFailure,
    PasswordResetCore,
    PasswordResetCoreSuccess,
    PasswordResetCoreFailure,
    GetSecurityListSuccess,
    GetSecurityListFailure,
    RequestPortalUserInfo,
    LoadUserProfiles,
    LoadUserProfilesSuccess,
    RecordLoginAttemptSuccess,
    RecordLoginAttemptFailure,
    RecordLoginAttempt,
    AppendMenu
} from '../core.actions';
import { getFeaturedLogoSystemId, selectUserProfiles, selectUserTokenTerms } from 'src/app/core/state/index';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AuthService as UniversalAuthService } from '@auth0/auth0-angular';
import { CoreRepositoryService } from '../../services/core-repository.service';
import { ToasterService } from '../../services';
import { DomainService } from 'src/app/shared/services/domain.service';
import { Store } from '@ngrx/store';
import { CustomFieldService } from '../../../shared/services/custom-field.service';
import { InternalPoolAuthService } from 'src/app/internal-pool/services/internal-pool-auth.service';
import { CoreStoreFacade } from 'src/app/core/state/core-store.facade';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { MenuItem } from 'src/app/core/models';
import { UserProfile } from 'src/app/shared/models/account/user-profile.model';
import { AyaKiddingMeFeatureFlags } from 'src/app/shared/models/enums/aya-kidding-me-feature-flags.enum';
import { ColorThemeService } from '../../theme/theme.service';

// a variable called pendo is created after the Pendo snippet loads in index.html
declare let pendo: any;
@Injectable()
export class CoreEffects {
    universalLogin$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.UniversalLogin),
            mergeMap(() => [new LoginSuccess(), new LoadPermissions(), new LoadUserProfiles(true)])
        );
    });

    passwordResetCore$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.PasswordResetCore),
            exhaustMap((action: PasswordResetCore) => {
                return this._authService.getResetTokenCore(action.username, action.password).pipe(
                    map(() => new PasswordResetCoreSuccess()),
                    catchError((error) => of(new PasswordResetCoreFailure(error)))
                );
            })
        );
    });

    resetPassword$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.PasswordResetCoreFailure),
                map(() => this._router.navigateByUrl('/resetpassword'))
            );
        },
        { dispatch: false }
    );

    getTermsAndConditions$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.GetTermsAndConditions, CoreActionTypes.RequestPortalInfoSuccess),
            exhaustMap(() => {
                return this._authService.getTermsAndConditions().pipe(
                    map((response) => new GetTermsAndConditionsSuccess(response)),
                    catchError((error) => of(new GetTermsAndConditionsFailure({ error })))
                );
            })
        );
    });

    showTermsAndConditions$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.GetTermsAndConditionsSuccess),
                map((action: GetTermsAndConditionsSuccess) => {
                    if (action.response) {
                        this._router.navigate(['terms-and-conditions']);
                    }
                })
            );
        },
        { dispatch: false }
    );

    acceptTermsAndConditions$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.AcceptTermsAndConditions),
            exhaustMap(() => {
                return this._authService.acceptTermsAndConditions().pipe(
                    map(() => new AcceptTermsAndConditionsSuccess()),
                    catchError((error) => of(new AcceptTermsAndConditionsFailure({ error })))
                );
            })
        );
    });

    acceptedTerms$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.AcceptTermsAndConditionsSuccess),
                concatLatestFrom(() => [this._store.select(selectUserTokenTerms)]),
                map(([, value]) => {
                    if (!value.termsAccepted || !value.user) {
                        return;
                    }
                    const defaultURL =
                        value.user && value.user.defaultURL && value.user.defaultURL.length > 0
                            ? value.user.defaultURL.replace(/^sar\./, 'vendor.')
                            : 'client/contacts';
                    this._router.navigateByUrl(defaultURL);
                })
            );
        },
        { dispatch: false }
    );

    requestPortalUserInfo$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.RequestPortalInfo),
            exhaustMap(() => {
                return this._authService.requestPortalUserInfo().pipe(
                    map((response) => new RequestPortalUserInfoSuccess(response)),
                    tap((action: RequestPortalUserInfoSuccess) => {
                        this._authService.validateAndStoreAccessData(JSON.stringify(action.response));

                        const identityService = this._injector.get(IdentityService);

                        if (identityService.isSignedIn()) {
                            this._featureManager.changeUser(this._identityService.userIdentity);
                            this._coreStoreFacade.tryLoadMenu();
                            this._coreStoreFacade.loadClientSharedData();
                            this._coreStoreFacade.loadSystemFields();
                        }
                    }),
                    catchError(() =>
                        of(
                            new RequestPortalUserInfoFailure(
                                new HttpErrorResponse({
                                    error: { error_description: 'User is inactive. Failed to login.', code: 401 }
                                })
                            )
                        )
                    )
                );
            })
        );
    });

    failedToGetPortalInfo$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.RequestPortalInfoFailure),
                map(() => this._router.navigate(['signin']))
            );
        },
        { dispatch: false }
    );

    getSecurityList$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.RequestPortalInfoSuccess),
            exhaustMap(() => {
                return this._authService.getSecurityList().pipe(
                    map((response) => new GetSecurityListSuccess(response)),
                    catchError((error) => of(new GetSecurityListFailure(error)))
                );
            })
        );
    });

    saveSecurityList$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.GetSecurityListSuccess),
                map((action: GetSecurityListSuccess) => {
                    this._identityService.saveSecurityList(action.list);
                })
            );
        },
        { dispatch: false }
    );

    loginSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoginSuccess),
            map(() => new RequestPortalUserInfo())
        );
    });

    logout$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.Logout),
                map((action: Logout) => action.ssoLogout),
                concatLatestFrom(() => [
                    this._universalAuthService.isAuthenticated$,
                    this._store.select(selectUserProfiles)
                ]),
                tap(([ssoLogout, isUniversalAuthToken, profiles]) => {
                    this._authService.logout();
                    if (isUniversalAuthToken || ssoLogout) {
                        this._resolveImpersonation(profiles)
                            .pipe(
                                switchMap(() => this._universalAuthService.user$),
                                take(1)
                            )
                            .subscribe((user) => {
                                this._window.localStorage.clear();
                                this._universalAuthService.logout({
                                    logoutParams: {
                                        federated: user?.federated_logout ?? false
                                    }
                                });
                            });
                    } else {
                        this._window.localStorage.clear();
                        const signOutUrl = this.getSignOutUrl(window.location.hostname);
                        if (signOutUrl) {
                            window.location.href = signOutUrl;
                        } else {
                            this._router.navigate(['signin']);
                        }
                    }
                })
            );
        },
        { dispatch: false }
    );

    loadMenu$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadMenu),
            switchMap(() => {
                const applicationId = 7;
                return this._coreRepository.getMenu(applicationId).pipe(
                    map((response) => new LoadMenuSuccess(response)),
                    catchError((error) => of(new LoadMenuFail(error)))
                );
            })
        );
    });

    loadMenuFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.LoadMenuFail),
                map((action: LoadMenuFail) => action.errorMessage),
                tap((error) => {
                    console.error(error);
                })
            );
        },
        { dispatch: false }
    );

    changePassword$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.ResetPassword),
            switchMap((action: ResetPassword) => {
                return this._authService.resetPassword(action.currentPassword, action.newPassword).pipe(
                    map((response: any) => {
                        if (response.code === 200) {
                            return new ResetPasswordSuccess();
                        } else if (response.code === 401) {
                            return new ResetPasswordFail('Not changed. Old password is incorrect');
                        } else {
                            return new ResetPasswordFail('Not changed. Error happened');
                        }
                    }),
                    catchError((error) => of(new ResetPasswordFail(error)))
                );
            })
        );
    });

    changePasswordFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.ResetPasswordFail),
                map((action: ResetPasswordFail) => action.errorMessage),
                tap((error) => {
                    this._toaster.fail(error);
                })
            );
        },
        { dispatch: false }
    );

    changePasswordSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.ResetPasswordSuccess),
                tap(() => {
                    this._toaster.success('Password changed');
                })
            );
        },
        { dispatch: false }
    );

    forgotPassword$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.ForgotPassword),
            map((action: ForgotPassword) => action),
            concatLatestFrom(() => [
                this._featureManager.isEnabled(AyaKiddingMeFeatureFlags.ConnectResetPasswordEspFinding)
            ]),
            exhaustMap(([action, flag]) => {
                return this._authService.forgotPassword(action.username, action.brand).pipe(
                    map((response) => {
                        this._dialog.getDialogById(action.dialogId)?.close(true);
                        return new ForgotPasswordSuccess(
                            flag ? response : 'Thank you! You will get an email with a link to reset your password.'
                        );
                    }),
                    catchError((error) => {
                        return of(new ForgotPasswordFail(error, action.dialogId));
                    })
                );
            })
        );
    });

    forgotPasswordSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.ForgotPasswordSuccess),
                tap((action: ForgotPasswordSuccess) => {
                    this._toaster.success(action.message);
                })
            );
        },
        { dispatch: false }
    );

    forgotPasswordFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.ForgotPasswordFail),
                tap((action: ForgotPasswordFail) => {
                    if (action.errorMessage instanceof HttpErrorResponse) {
                        if (action.errorMessage.status === 200) {
                            this._dialog.getDialogById(action.dialogId)?.close(true);
                            this._toaster.success(
                                'Thank you! You will get an email with a link to reset your password.'
                            );
                        } else {
                            this._toaster.fail('Reset Password Error');
                        }
                    }
                })
            );
        },
        { dispatch: false }
    );

    LoadFeaturedLogoWithSystemId$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadFeaturedLogoWithSystemId),
            switchMap(() =>
                this._coreStoreFacade.getClientSharedData().pipe(
                    mergeMap((response) => [
                        new LoadSystemIdSuccess(response.systemId),
                        new LoadFeaturedLogo(),
                        new LoadCustomSystemFields(response.systemId)
                    ]),
                    catchError((error) => of(new LoadSystemIdFail(error)))
                )
            )
        );
    });

    loadSystemFields$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadSystemFields),
            switchMap(() =>
                this._coreStoreFacade.getClientSharedData().pipe(
                    map((response) => {
                        const formFields = response.systemFields
                            .reduce((fields, field) => [...fields, ...field.split(',')], [])
                            .map((field) => field.trim())
                            .filter((field, index, fields) => fields.indexOf(field) === index)
                            .map((field) => {
                                const [, moduleName, fieldName] = field.split('.');
                                return { moduleName, fieldName };
                            });

                        return new LoadSystemFieldsSuccess(formFields);
                    }),
                    catchError((error) => of(new LoadSystemFieldsFail(error)))
                )
            )
        );
    });

    loadCustomSystemFields$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadCustomSystemFields),
            switchMap((action: LoadCustomSystemFields) => {
                return this._customFieldService.getCustomSystemFields(action.systemId).pipe(
                    map((response) => {
                        return new LoadCustomSystemFieldsSuccess(response);
                    }),
                    catchError((error) => of(new LoadCustomSystemFieldsFail(error)))
                );
            })
        );
    });

    loadFeaturedLogo$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadFeaturedLogo),
            withLatestFrom(this._store.select(getFeaturedLogoSystemId)),
            switchMap(([, systemId]) => {
                return this._coreRepository.getClientFeaturedLogo(systemId).pipe(
                    map((response) => new LoadFeaturedLogoSuccess(response)),
                    catchError((error) => of(new LoadFeaturedLogoFail(error)))
                );
            })
        );
    });

    loadFeaturedLogoFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.LoadFeaturedLogoFail),
                map((action: LoadFeaturedLogoFail) => action.errorMessage),
                tap((error) => {
                    console.error(error);
                })
            );
        },
        { dispatch: false }
    );

    IRPMenu$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadMenuSuccess),
            filter(() => {
                const auth = this._injector.get(InternalPoolAuthService);
                return auth.canViewInternalPool();
            }),
            map((action: LoadMenuSuccess) => {
                const response = action.menu.map((m) => {
                    if (m.name === 'Per Diem') {
                        return {
                            ...m,
                            name: 'IRP'
                        };
                    }
                    return m;
                });
                return new UpdateMenu(response);
            })
        );
    });

    updateShiftsBranding$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadMenuSuccess),
            map((action: LoadMenuSuccess) => {
                const updateGroups = [
                    {
                        targetName: 'Per Diem',
                        update: {
                            name: 'SHIFTS',
                            icon: 'svg_shifts_logo'
                        }
                    }
                ];
                const updateItems = [
                    {
                        targetName: 'Shifts',
                        update: {
                            icon: 'event',
                            name: 'Schedule',
                            path: 'client/shiftsschedule'
                        }
                    },
                    {
                        targetName: 'Internal Pool',
                        update: {
                            icon: 'badge'
                        }
                    },
                    {
                        targetName: 'Local Pool',
                        update: {
                            icon: 'person_pin'
                        }
                    }
                ];

                const response = action.menu.map((group) => {
                    const update = updateGroups.find((item) => item.targetName == group.name)?.update;
                    if (update) {
                        group = {
                            ...group,
                            ...update
                        };
                    }

                    if (group.children) {
                        group.children = group.children.map((child) => {
                            const update = updateItems.find((item) => item.targetName == child.name)?.update;

                            if (update) {
                                child = {
                                    ...child,
                                    ...update
                                };
                            }
                            return child;
                        });
                    }

                    return group;
                });

                return new UpdateMenu(response);
            })
        );
    });

    addShiftsMarketingPage$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadMenuSuccess),
            filter(() => this._identityService.inScope('client', 'shifts_welcome')),
            filter((action: LoadMenuSuccess) => {
                const targetMenu = ['Per Diem', 'SHIFTS'];
                const hasShifts = action.menu.some((item) => targetMenu.includes(item.name));

                return !hasShifts;
            }),
            debounceTime(1),
            map((action: LoadMenuSuccess) => {
                const shiftMarketingMenuItem: MenuItem = {
                    id: 7,
                    applicationId: 7,
                    name: 'SHIFTS',
                    icon: 'svg_shifts_logo',
                    path: 'client/perdiemscheduler/landing',
                    children: null,
                    queryParams: null,
                    menuItemOrder: 7
                };

                return new AppendMenu(shiftMarketingMenuItem);
            })
        );
    });

    loginAttemptFail$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoginFail),
            map((action: LoginFail) => action.username),
            exhaustMap((username) => {
                return of(new RecordLoginAttempt(false, username));
            })
        );
    });

    loginAttemptSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoginSuccess),
            exhaustMap(() => {
                return of(new RecordLoginAttempt(true));
            })
        );
    });

    recordLoginAttempt$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.RecordLoginAttempt),
            switchMap((action: RecordLoginAttempt) => {
                return this._authService.recordLoginAttempt(action.isSuccessfulLogin, action.username).pipe(
                    map(() => new RecordLoginAttemptSuccess()),
                    catchError((error) => of(new RecordLoginAttemptFailure({ error })))
                );
            })
        );
    });

    loadUserProfiles$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadUserProfiles),
            map((action: LoadUserProfiles) => action.enforce),
            withLatestFrom(this._store.select(selectUserProfiles)),
            switchMap(([enforce, profiles]) => {
                if (!enforce && profiles && profiles.length) {
                    return of(new LoadUserProfilesSuccess(profiles));
                }
                this._identityService.removeProfileChangedListener();

                return this._universalAuthService.isAuthenticated$.pipe(
                    take(1),
                    exhaustMap((auth) => {
                        if (!auth) {
                            return of([]);
                        }
                        return this._identityService.getProfiles();
                    }),
                    map((response) => {
                        if (response && response.length > 1) {
                            const activeProfile = response.find((p) => p.isDefaultProfile)?.userId;
                            if (activeProfile) {
                                this._identityService.listenProfileChanged(activeProfile);
                            }
                        }
                        return new LoadUserProfilesSuccess(response);
                    })
                );
            })
        );
    });

    loadUserProfilesFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.LoadUserProfilesFail),
                map((action: ResetPasswordFail) => action.errorMessage),
                tap((error) => {
                    this._toaster.fail('The user profiles were failed to load');
                })
            );
        },
        { dispatch: false }
    );

    loadColorTheme$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(CoreActionTypes.LoginSuccess),
                tap(() => {
                    this._colorThemeService.loadColorTheme(true).subscribe();
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _authService: AuthService,
        private readonly _router: Router,
        private readonly _coreRepository: CoreRepositoryService,
        private readonly _coreStoreFacade: CoreStoreFacade,
        private readonly _customFieldService: CustomFieldService,
        private readonly _store: Store,
        private readonly _toaster: ToasterService,
        private readonly _injector: Injector,
        private readonly _identityService: IdentityService,
        private readonly _dialog: MatDialog,
        private readonly _universalAuthService: UniversalAuthService,
        private readonly _featureManager: LDFeatureManager,
        private readonly _colorThemeService: ColorThemeService,
        @Inject('Window') private readonly _window: Window
    ) {}

    getSignOutUrl(hostName: string): string {
        const domain = this._injector.get(DomainService);
        const signOutUrl = domain.signOutUrl();
        const intSignOutUrl = domain.intSignOutUrl();
        const hotSignOutUrl = domain.getValue('HOT_SIGN_OUT_URL');
        const devSignOutUrl = domain.getValue('DEV_SIGN_OUT_URL');
        const prodValue = domain.getValue('PROD_CORE_URL');
        const intValue = domain.getValue('INT_CORE_URL');
        const hotValue = domain.getValue('HOT_CORE_URL');
        const devValue = domain.getValue('DEV_CORE_URL');

        switch (hostName) {
            case prodValue:
                return signOutUrl ? signOutUrl : '';
            case intValue:
                return intSignOutUrl ? intSignOutUrl : '';
            case hotValue:
                return hotSignOutUrl ? hotSignOutUrl : '';
            case devValue:
                //case localValue: // only uncomment to test unless you want to go to dev wordpress site when logging out locally
                return devSignOutUrl ? devSignOutUrl : '';
            default:
                return '';
        }
    }

    private _resolveImpersonation(profiles: UserProfile[]): Observable<any> {
        if (profiles && profiles.some((p) => p.isImpersonated && p.isDefaultProfile)) {
            const mainProfileUserId = profiles.find((p) => p.isMain)?.userId;
            if (mainProfileUserId) {
                return this._identityService.stopImpersonation(mainProfileUserId);
            }
        }
        return of({});
    }
}
