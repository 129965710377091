import { Pipe, PipeTransform } from '@angular/core';
import { FeatureSettingItem, FeatureInputTypes } from '../models/feature-features.models';

@Pipe({
    name: 'featureSettingHint',
    pure: true
})
export class FeatureSettingHintPipe implements PipeTransform {
    transform(setting: FeatureSettingItem, allowHideMax = false): string {
        var text = [];

        switch (setting.valueTypeId) {
            case FeatureInputTypes.DECIMAL:
            case FeatureInputTypes.INTEGER:
            case FeatureInputTypes.STRING:
                if (setting.min != null) {
                    text.push(`Minimum value is ${setting.min}.`);
                }
                if (setting.max != null && (!allowHideMax || !setting.hideMaxValue)) {
                    text.push(`Maximum value is ${setting.max}.`);
                }
                break;
            case FeatureInputTypes.DATETIME:
                const format = new Intl.DateTimeFormat('en-US');
                if (setting.min != null) {
                    text.push(`Minimum date is ${format.format(new Date(setting.min))}.`);
                }
                if (setting.max != null && (!allowHideMax || !setting.hideMaxValue)) {
                    text.push(`Maximum date is ${format.format(new Date(setting.max))}.`);
                }
                break;
        }

        return text.join(' ');
    }
}
