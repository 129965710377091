import dayjs from 'dayjs';

export class SubmittalHelper {
    static calculateContractDaysLength(from: Date, to: Date): number {
        const startDate = dayjs(from);
        const endDate = dayjs(to);
        return endDate.diff(startDate, 'day') + 1;
    }

    static calculateContractWeeksLength(from: Date, to: Date): number {
        const daysLength = SubmittalHelper.calculateContractDaysLength(from, to);
        let weeksLength = Math.floor(daysLength / 7);
        if (daysLength % 7 > 2) {
            weeksLength++;
        }
        return weeksLength;
    }

    static calculateContractEndDateByWeeksLength(from: Date, weeksLength: number): Date {
        let endDate = dayjs(from).add(weeksLength * 7, 'day');
        const endDay = endDate.day();
        if (endDay !== 6) {
            if (endDay > 3) {
                const daysToBeAdded = Math.max(0, 6 - endDay);
                endDate = endDate.add(daysToBeAdded, 'day');
            } else {
                const daysToBeAdded = -1 * Math.max(0, 7 - (6 - endDay));
                endDate = endDate.add(daysToBeAdded, 'day');
            }
        }
        return endDate.toDate();
    }

    static calculateContractEndDateByDaysLength(from: Date, daysLength: number): Date {
        return dayjs(from)
            .add(daysLength - 1, 'day')
            .toDate();
    }
}
