export interface VendorJobEmploymentType {
    vendorId: number;
    employmentTypeId: number;
}

export interface VendorJobProfession {
    vendorId: number;
    professionId: number;
}

export interface VendorJobProfessionType {
    vendorId: number;
    professionTypeId: number;
}

export enum JobViewTypes {
    AyaEmployeeView = 2,
}
