import { ListItem } from 'src/app/shared/models/list-item';
import { LoadingTypes } from 'src/app/shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import * as shiftsActions from '../actions';
import { SystemLookup } from 'src/app/shared/models/lookups/system-lookup.model';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { UnitLookup } from 'src/app/shared/models/lookups/unit-lookup.model';
import { SpecialtyLookup } from 'src/app/shared/models/lookups/specialty-lookup.model';
import { ShiftDetailsType, ClientShiftVisibility, ShiftReleaseType } from 'src/app/shared/models/shifts';
import { ShiftStatusLookup } from '../../models/shift-status-lookup.model';

const shiftsDetails: ListItem[] = [
    { id: ShiftDetailsType.View, name: "View" },
    { id: ShiftDetailsType.Edit, name: "Edit" },
    { id: ShiftDetailsType.CancelledByFacility, name: "Cancelled By Facility" }    
];

const shiftsVisibility: ListItem[] = [
    { id: ClientShiftVisibility.All, name: "All" },
    { id: ClientShiftVisibility.Agency, name: "Agency" },
    { id: ClientShiftVisibility.InternalPoolOnly, name: "Internal" }
];

const shiftReleases: ListItem[] = [
    { id: ShiftReleaseType.core, name: "Core" },
    { id: ShiftReleaseType.float, name: "Float" },
    { id: ShiftReleaseType.agency, name: "Agency" },
]
export interface ShiftsLookupsState {
	error: string;
	systems: SystemLookup[];
	facilities: FacilityLookup[];
    units: UnitLookup[];
    professions: ListItem[];
    shiftsDetails: ListItem[];
    shiftsVisibility: ListItem[];
    specialties: SpecialtyLookup[];
    statuses: ShiftStatusLookup[];
    states: ListItem[];
    unions: ListItem[];
    submittalStatuses: ListItem[];
    availabilityStatuses: ListItem[];
    epicSkills: ListItem[];
    languageSkillLevels: ListItem[];
    languages: ListItem[];
    shiftTemplates: ListItem[];
    resourceTypes: ListItem[];
    loading: LoadingTypes;
    templatesLoading: LoadingTypes;
    releases: ListItem[];
}

export const initialLookupsState: ShiftsLookupsState = {
    error: null,
    systems: [],
	facilities: [],
    units: [],
    shiftsDetails: shiftsDetails,
    shiftsVisibility: shiftsVisibility,
    professions: [],
    specialties: [],
    statuses: [],
    states: [],
    unions: [],
    submittalStatuses: [],
    availabilityStatuses: [],
    epicSkills: [],
    languageSkillLevels: [],
    languages: [],
    shiftTemplates: [],
    resourceTypes: [],
    loading: LoadingTypes.INIT,
    templatesLoading: LoadingTypes.INIT,
    releases: shiftReleases,
}

export function shiftsLookupsReducer(state: ShiftsLookupsState, action: Action) {
	return createReducer(
		initialLookupsState,
		on(shiftsActions.loadShiftsLookupsFromServer, (state, action) => (
			{
                ...state,
                loading: LoadingTypes.LOADING
			}
		)),

        on(shiftsActions.loadShiftsLookupsSuccess, (state, action) => (
            {
                ...state,
                systems: action.systems,
                facilities: action.facilities,
                units: action.units,
                professions: action.professions,
                specialties: action.specialties,
                statuses: action.statuses,
                states: action.states,
                unions: action.unions,
                submittalStatuses: action.submittalStatuses,
                resourceTypes: action.resourceTypes,
                loading: LoadingTypes.LOADED,
			}
		)),

		on(shiftsActions.loadShiftsLookupsFail, (state, action) => (
			{
                ...state,
                loading: LoadingTypes.FAIL
			}
        )),

        on(shiftsActions.loadShiftsLookupsLocal, (state, action) => (
			{
                ...state
			}
        )),

        on(shiftsActions.loadShiftTemplateLookups, (state, action) => (
			{
                ...state,
                templatesLoading: LoadingTypes.LOADING
			}
        )),

        on(shiftsActions.loadShiftTemplateLookupsFail, (state, action) => (
			{
                ...state,
                templatesLoading: LoadingTypes.FAIL
			}
        )),

        on(shiftsActions.loadShiftTemplateLookupsSuccess, (state, action) => (
			{
                ...state,
                shiftTemplates: action.shiftTemplates,
                templatesLoading: LoadingTypes.LOADED
			}
        )),

	)(state, action);
}

export function reducer(state: ShiftsLookupsState | undefined, action: Action) {
	return shiftsLookupsReducer(state, action);
}
