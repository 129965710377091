import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    VendorCandidateState,
    vendorCandidateDetailsStoreKey
} from 'src/app/vendor/vendor-candidate-details/store/state/vendor-candidate-details.state';

const selectFeature = createFeatureSelector<VendorCandidateState>(vendorCandidateDetailsStoreKey);

export const selectDocumentUploadPreviewLoading = createSelector(
    selectFeature,
    (state: VendorCandidateState) =>
        state.documentUpload.documentPreviewUploadLoading === LoadingTypes.LOADING ||
        state.documentUpload.documentPreviewDownloadLoading === LoadingTypes.LOADING ||
        state.documentUpload.saveDocumentMetadataLoading === LoadingTypes.LOADING ||
        state.documentUpload.metadataFieldsLoading === LoadingTypes.LOADING ||
        state.documentUpload.filteredMetadataFieldsLoading === LoadingTypes.LOADING ||
        state.documentUpload.setDocumentAttributesLoading === LoadingTypes.LOADING ||
        state.documentUpload.deleteDocumentLoading === LoadingTypes.LOADING
);

export const selectDocumentUploadPreviewSuccess = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.documentPreviewUploadLoading === LoadingTypes.LOADED
);

export const selectDocumentUploadPreviewFail = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.documentPreviewUploadLoading === LoadingTypes.FAIL
);

export const selectDocumentUploadData = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload
);

export const selectDocumentUploadDocTypeId = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.docTypeId
);

export const selectDocumentUploadCandidateID = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.candidateId
);

export const selectCandidateDocumentId = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.candidateDocumentId
);

export const selectCandidateDocumentPreviewFile = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.file
);

export const selectDocumentPreviewDownloadSuccess = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.documentPreviewDownloadLoading === LoadingTypes.LOADED
);

export const selectDocumentPreviewDownloadFail = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.documentPreviewDownloadLoading === LoadingTypes.FAIL
);

export const selectAreMetadataFieldsLoaded = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.metadataFieldsLoading === LoadingTypes.LOADED
);

export const selectDocumentUploadMetadataFields = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.documentAttributeFields
);

export const selectDocumentUploadMetadataAttributes = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.documentAttributes
);

export const selectDocumentReviewerExpirationDate = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.docReviewerExpirationDate
);

export const selectSaveDocumentMetadataCalled = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.saveDocumentMetadataCalled
);

export const selectIsMetadataFormValid = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.isValid
);

export const selectDocumentUploadContractId = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.contractId
);

export const selectDocumentDeleteCalled = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.deleteDocumentCalled
);

export const selectDocumentDeleteLoading = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.deleteDocumentLoading
);

export const selectSaveDocumentMetadataLoadedSuccess = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.saveDocumentMetadataLoading === LoadingTypes.LOADED
);

export const selectDocumentDeletedSuccess = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.deleteDocumentLoading === LoadingTypes.LOADED
);

export const selectDeleteDocumentCalledWithNavigation = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.deleteDocumentCalledWithNavigation
);

export const selectDeleteDocumentCalledWithNavigationSuccess = createSelector(
    selectFeature,
    (state: VendorCandidateState) =>
        state.documentUpload.deleteDocumentCalledWithNavigation &&
        state.documentUpload.deleteDocumentCalledWithNavigationSuccess
);

export const selectCandidateId = createSelector(
    selectFeature,
    (state: VendorCandidateState) => state.documentUpload.candidateId
);
