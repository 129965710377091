import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubmittalOfferDetails, SubmittalOfferDetailsUpdateRequest } from 'src/app/shared/models/submittals';
import { SubmittalHelper } from 'src/app/submittals/shared/submittal-helper';

@Component({
    selector: 'ayac-submittal-offer-details',
    templateUrl: './submittal-offer-details.component.html',
    styleUrls: ['./submittal-offer-details.component.scss']
})
export class SubmittalOfferDetailsComponent {
    @Input() offer: SubmittalOfferDetails;
    @Input() offerInEditMode = false;
    @Input() collectOfferDetailsUpdateRequest = false;
    @Output() saveOffer = new EventEmitter<SubmittalOfferDetailsUpdateRequest>();
    @Output() canUpdate = new EventEmitter<boolean>();

    get daysLength() {
        return SubmittalHelper.calculateContractDaysLength(
            new Date(this.offer.startDate),
            new Date(this.offer.endDate)
        );
    }

    get weeksLength() {
        return SubmittalHelper.calculateContractWeeksLength(
            new Date(this.offer.startDate),
            new Date(this.offer.endDate)
        );
    }

    get submittalOfferDetailsUpdateRequest() {
        const request: SubmittalOfferDetailsUpdateRequest = {
            offerId: this.offer.offerId,
            startDate: this.offer.startDate,
            endDate: this.offer.endDate,
            isRtoApproved: this.offer.requestedTimeOffIsApproved,
            rtoDates: this.offer.rtoDates ? this.offer.rtoDates : []
        };
        return request;
    }

    saveOfferUpdate(request: SubmittalOfferDetailsUpdateRequest) {
        this.saveOffer.emit(request);
    }

    canUpdateOffer(status: boolean) {
        this.canUpdate.emit(status);
    }
}
