import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InvoicesState, invoicesStoreKey } from './invoices.state';
import { LoadingTypes } from 'src/app/shared/models';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { FileAttachmentPreview } from 'src/app/shared/components/file-attachments-preview/file-attachments-preview.component';

export const selectFeature = createFeatureSelector<InvoicesState>(invoicesStoreKey);

export const selectInvoices = createSelector(selectFeature, (state) => state.list);

export const selectInvoicesIsLoading = createSelector(selectFeature, (state) =>
    [
        state.list.loading,
        state.lookups.loading,
        state.document.loading,
        state.export.loading,
        state.approveDraft.loading
    ].some((a) => a === LoadingTypes.LOADING)
);

export const selectInvoicesView = createSelector(selectInvoices, (state) => ({
    data: state.invoices,
    total: state.invoicesTotal
}));

export const selectInvoicesQuery = createSelector(selectFeature, (state) => state.query);

export const selectInvoicesQueryFilters = createSelector(selectFeature, (state) => flattenFilter(state.query.filter));

export const selectSystemsFilterLookups = createSelector(selectFeature, (state) => {
    return state.lookups.systems === null
        ? []
        : state.lookups.systems.map((f) => ({
              id: f.name,
              name: f.name
          }));
});

export const selectFacilitiesFilterLookups = createSelector(selectFeature, (state) => {
    return state.lookups === null ? [] : state.lookups.facilities;
});

export const selectUnitsLookups = createSelector(selectFeature, (state) => {
    return state.lookups === null ? [] : state.lookups.units;
});

export const selectDisputeReasonLookups = createSelector(selectFeature, (state) => {
    return state.lookups.disputeReasons;
});

export const selectLookups = createSelector(selectFeature, (state) => {
    return state.lookups.units.length === 0 ? null : state.lookups;
});

export const selectDisputeStatusLookups = createSelector(selectFeature, (state) => {
    return state.lookups.disputeStatuses;
});

export const selectCaregiverListingTypeLookups = createSelector(selectFeature, (state) => {
    return state.lookups.caregiverListingTypes;
});

export const selectInvoiceDetails = createSelector(selectFeature, (state) => state.details.invoicesDetails);

export const selectInvoiceDetailsIsLoading = createSelector(
    selectFeature,
    (state) => state.details.loading === LoadingTypes.LOADING
);

export const selectInvoiceAttachmentsIsLoading = createSelector(
    selectFeature,
    (state) => state.attachments.loading === LoadingTypes.LOADING
);

export const selectCommonInvoiceIsLoading = createSelector(
    selectFeature,
    (state) =>
        state.details.loading === LoadingTypes.LOADING ||
        state.export.loading === LoadingTypes.LOADING ||
        state.approveDraft.loading === LoadingTypes.LOADING
);

export const selectInvoiceDetailsError = createSelector(selectFeature, (state) => state.details.error);

export const selectApproveDraftInvoicesError = createSelector(selectFeature, (state) => state.approveDraft.error);

export const selectApproveDraftInvoicesInProgress = createSelector(
    selectFeature,
    (state) => state.approveDraft.loading === LoadingTypes.LOADING
);

export const selectApproveDraftInvoicesFailed = createSelector(
    selectFeature,
    (state) => state.approveDraft.loading === LoadingTypes.FAIL
);

export const selectApproveDraftInvoicesSuccess = createSelector(
    selectFeature,
    (state) => state.approveDraft.loading === LoadingTypes.LOADED
);

export const selectInvoiceAttachments = createSelector(
    selectFeature,
    (state) => state.attachments?.invoicesAttachments
);

export const selectInvoiceFileAttachment = createSelector(
    selectFeature,
    (state) => state.attachmentsEnhanced.invoiceAttachmentPreview
);

export const selectInvoiceFileAttachments = createSelector(
    selectFeature,
    (state) => state.attachmentsEnhanced.invoiceFileAttachments
);

export const selectHasInvoiceFileAttachments = createSelector(
    selectFeature,
    (state) => state.attachmentsEnhanced.invoiceFileAttachments.length > 0
);

export const selectInvoiceAttachmentsTotalPages = createSelector(
    selectFeature,
    (state) => state.attachmentsEnhanced.totalPages
);

export const selectHasInvoiceAttachments = createSelector(selectFeature, (state) => {
    const hasAttachments = state.attachmentsEnhanced?.invoiceFileAttachments?.length > 0;
    return hasAttachments;
});

export const selectInvoiceFileAttachmentPreview = createSelector(
    selectInvoiceFileAttachment,
    selectInvoiceAttachmentsTotalPages,
    (preview, totalPages) => {
        const filePreview: FileAttachmentPreview = {
            blob: preview,
            totalPages: totalPages
        };

        return filePreview;
    }
);
