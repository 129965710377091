import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomainService } from 'src/app/shared/services/domain.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import * as internalPoolSelectors from '../store/selectors';
import * as internalPoolActions from '../store/actions';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { map, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { InternalPoolAuthService } from '../services/internal-pool-auth.service';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { CombinedForms } from 'src/app/shared/utilities';
import { PersonalDetailsSectionComponent } from '../personal-details-section/personal-details-section.component';
import { ProfessionalDetailsSectionComponent } from '../professional-details-section/professional-details-section.component';
import { ShiftsAppSectionComponent } from '../shifts-app-section/shifts-app-section.component';
import { ResourceEdit } from 'src/app/shared/models/internal-pool/resource-edit.model';
import { NotesSectionComponent } from '../notes-section/notes-section.component';
import { DocumentsSectionComponent } from '../documents-section/documents-section.component';
import { FilesToUpload } from 'src/app/shared/models/attachment';
import * as moment from 'moment';
import { PreferredShift } from 'src/app/internal-pool/models';
import { FeatureFlag } from '../../shared/models/enums/feature-flag.enum';

@Component({
    selector: 'ayac-add-resource',
    templateUrl: './add-resource.component.html',
    styleUrls: ['./add-resource.component.scss']
})
export class AddResourceComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    /** Observable */
    isLoading$: Observable<boolean>;
    resource$: Observable<Resource>;
    title$: Observable<string>;
    hasEditableFacilities$: Observable<boolean>;
    canEditResource$: Observable<boolean>;
    /** Security */
    canEditResource: boolean;

    environment: string;
    id: number;
    professionId: number;
    isEdit: boolean;
    isSaving$: Observable<boolean>;
    isArchived$: Observable<boolean>;
    resourceType$: Observable<string>;
    systemId: number;
    featureFlag = FeatureFlag;

    @ViewChild(PersonalDetailsSectionComponent, { static: true })
    personalDetailsSection: PersonalDetailsSectionComponent;

    @ViewChild(ProfessionalDetailsSectionComponent, { static: true })
    professionalDetailsSection: ProfessionalDetailsSectionComponent;

    @ViewChild(NotesSectionComponent, { static: true })
    notesSection: NotesSectionComponent;

    @ViewChild(ShiftsAppSectionComponent, { static: true })
    shiftsAppSection: ShiftsAppSectionComponent;

    @ViewChild(DocumentsSectionComponent, { static: true })
    documentsSection: DocumentsSectionComponent;

    private isNyuSystem$: Observable<boolean>;
    private isNyuSystem: boolean;

    private readonly tabs = ['profile', 'assignments'];
    selectedTabIndex = 0;

    constructor(
        private readonly store: Store<{}>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dialog: DialogService,
        private readonly authService: InternalPoolAuthService,
        domainService: DomainService
    ) {
        super();
        this.environment = domainService.environment();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.store.dispatch(internalPoolActions.clearResourceDetails());
        this.store.dispatch(internalPoolActions.clearResourceDocuments());
        this.store.dispatch(internalPoolActions.clearResourceDocumentPreview());
        this.store.dispatch(internalPoolActions.clearResourceAssignments());
    }

    ngOnInit() {
        this.id = +this.route.snapshot.paramMap.get('id');
        this.isEdit = this.id === 0;
        this.store.dispatch(internalPoolActions.loadSystemIdWithLookupsAndResourceDetails({ id: this.id }));
        this.canEditResource = this.authService.canEditResource();
        this.isLoading$ = this.store.pipe(select(internalPoolSelectors.selectIsResourcesGridLoading));
        this.isSaving$ = this.store.pipe(select(internalPoolSelectors.selectIsResourceSaving));
        this.resource$ = this.store.select(internalPoolSelectors.selectCurrentResource);
        this.isArchived$ = this.store.select(internalPoolSelectors.selectCurrentResourceIsArchived);
        this.resourceType$ = this.store.select(internalPoolSelectors.selectCurrentResourceType);
        this.hasEditableFacilities$ = this.store.select(internalPoolSelectors.selectHasEditableFacilities);

        this.title$ = this.resource$.pipe(
            map((resource) => {
                if (resource && resource.id > 0) {
                    const specialty = resource.expertises.map((e) => e.expertiseName).join(', ');
                    const title = [resource.firstName, resource.lastName];
                    if (resource.professionName || specialty) {
                        title.push('-');
                    }
                    if (specialty !== resource.professionName) {
                        title.push(specialty);
                    }
                    title.push(resource.professionName);

                    return title.join(' ');
                }
                return 'Add Worker';
            })
        );

        this.store.pipe(select(internalPoolSelectors.selectSystemId), takeUntil(this.d$)).subscribe((systemId) => {
            this.systemId = +systemId;
            this.loadDetail(systemId, this.id);
        });

        this.store.dispatch(internalPoolActions.loadIsNyuSystem());
        this.isNyuSystem$ = this.store.select(internalPoolSelectors.selectIsNyuSystem);
        this.store
            .pipe(select(internalPoolSelectors.selectIsNyuSystem), takeUntil(this.d$))
            .subscribe((isNyuSystem) => (this.isNyuSystem = isNyuSystem));

        this.canEditResource$ = combineLatest([
            this.hasEditableFacilities$,
            this.isNyuSystem$
        ]).pipe(map(([hasEditableFacilities, isNyu]) => {
            if (isNyu) {
                return true
            }
            return hasEditableFacilities;
        }));

        const tab = this.route.snapshot.queryParamMap.get('tab');
        this.selectedTabIndex = this.tabs.includes(tab) ? this.tabs.indexOf(tab) : 0;
    }

    loadDetail(systemId: number, id: number) {
        if (systemId && id) {
            this.store.dispatch(internalPoolActions.loadPreviousAssignments({ id }));
            this.store.dispatch(internalPoolActions.loadCurrentResourceAssignments({ id }));
            this.store.dispatch(internalPoolActions.loadResourceDocuments({ resourceId: id }));
        }
    }

    onSave(): void {
        if (!this.forms.isValid) {
            this.forms.markAllAsTouched();
            return;
        }
        const value: ResourceEdit = this.forms.value;

        const preferredShifts: Array<PreferredShift> = [];

        if (value && value.preferredShifts) {
            value.preferredShifts.forEach((element) => {
                const startTime = element.startTime
                    ? `${moment(element.startTime).format('YYYY-MM-DD')}T${moment(element.startTime).format(
                          'HH:mm:[00Z]'
                      )}`
                    : null;

                preferredShifts.push({
                    day: element.day,
                    shiftHours: element.shiftHours,
                    startTime: startTime
                });
            });
        }
        const resource = {
            ...value,
            languageSkills: value.languageSkills.filter((l) => l.languageId),
            relatedPersons: value.relatedPersons.filter((l) => l.name),
            EpicSkillsId: value.epicSkills,
            preferredShifts: preferredShifts,
            preferredLocationIds: value.preferredLocationIds,
            customFields: value.customFields
        };
        if (this.id) {
            this.store.dispatch(
                internalPoolActions.updateResource({
                    reload: true,
                    resource,
                    systemId: this.systemId,
                    isNyuSystem: this.isNyuSystem
                })
            );
        } else {
            this.store.dispatch(
                internalPoolActions.addResource({
                    reload: true,
                    resource,
                    systemId: this.systemId,
                    documents: this.documents,
                    isNyuSystem: this.isNyuSystem
                })
            );
        }
    }

    async onCancel() {
        if (this.id === 0) {
            this.routeToInternalPool();
            return;
        }

        if (this.forms.isDirty) {
            const isConfirmed = await this.openCancelDialog();
            if (!isConfirmed) {
                return;
            }
        }
        this.isEdit = false;
    }

    openCancelDialog(): Promise<boolean> {
        return this.dialog.openConfirmationDialog({
            data: {
                title: 'Unsaved Changes Will Be Lost',
                text: 'You have unsaved changes on this page. If you leave before saving, your changes will be lost.'
            }
        } as IConfirmationDialogOptions);
    }

    routeToInternalPool() {
        this.router.navigate(['/client', 'internalpool']);
    }

    onEdit() {
        this.isEdit = true;
    }

    get forms() {
        return new CombinedForms([
            this.personalDetailsSection.form,
            this.notesSection.form,
            this.professionalDetailsSection.form,
            this.shiftsAppSection.form
        ]);
    }

    get documents(): FilesToUpload[] {
        return (
            this.documentsSection &&
            this.documentsSection.attachmentsComponent &&
            this.documentsSection.attachmentsComponent.document.files
        );
    }

    get isNew() {
        return this.id === 0;
    }

    resendEmailInvitation() {
        if (this.id > 0) {
            this.store.dispatch(internalPoolActions.resendInvitationEmail({ id: this.id }));
        }
    }

    onArchive() {
        this.store.dispatch(internalPoolActions.archiveWorkerConfirmation({ id: this.id }));
    }

    onUnarchive() {
        this.store.dispatch(
            internalPoolActions.unarchiveWorkerConfirmation({
                id: this.id,
                refreshDetails: true,
                navigateBack: false
            })
        );
    }

    updateTabRoute(index: number) {
        const queryParams = { tab: this.tabs[index] };
        this.selectedTabIndex = index;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        });
    }

    get canArchiveResource() {
        return this.authService.canArchiveResource();
    }
}
