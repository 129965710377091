import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'ayac-text-area-with-counter',
    templateUrl: './text-area-with-counter.component.html',
    styleUrls: ['./text-area-with-counter.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TextAreaWithCounterComponent
        }
    ]
})
export class TextAreaWithCounterComponent
    implements ControlValueAccessor {
    @Input() dataQaId = 'text-area-with-counter';
    @Input() maxLength = 1000;
    @Input() rowsCount = 5;
    @Input() placeholder = '';
    @Input() displayBorderBottomOnly = false;

    @Output() formItemValidationChanged = new EventEmitter<any>();

    model: string = '';

    noteLength = 0;
    touched = false;
    disabled = false;
    isValid = false;

    onChange = (obj: string) => { };
    onTouched = () => { };

    updateModel(newValue: any): void {
        this.markAsTouched();
        if (!this.disabled) {
            this.model = newValue;
            this.onChange(this.model);
            this.setFormItemIsNotValid();
        }
    }

    writeValue(model: string) {
        this.model = model;
        this.setFormItemIsNotValid();
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    private setFormItemIsNotValid(): void {
        const model = this.model ? this.model : '';
        const isValid = model.length <= this.maxLength;

        if (isValid !== this.isValid) {
            this.isValid = isValid;
            this.formItemValidationChanged.emit(isValid);
        }
    }
}
