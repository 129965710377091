export enum SvtFeatureFlags {
    UsePostForClientReports = 'SVT-354-Update-Connect-Report-Endpoints-To-Post',
    UsePostForPerDiemLocalPoolGetFiltered = 'SVT-905-Convert-AyaConnect-PerDiemLocalPool-GetFiltered-to-POST',
    UsePostForPerDiemSchedulerGetCandidates = 'SVT-921-UPDATE_PERDIEM_ENDPOINT_GET_CANDIDATES_TO_POST',
    SVT_900_CONNECT_USE_NEW_VENDOR_FILES_ENDPOINT = 'SVT-900-CONNECT_USE_NEW_VENDOR_FILES_ENDPOINT',
    UsePostForgetUsersWithPermissions = 'SVT-893-UPDATE_GET_USERS_WITH_PERMISSIONS_TO_POST',
    UsePostForInvoicesGetFiltered = 'SVT-904-Connect-Use-Post-For-Invoices-GetFiltered',
    UsePostForAddressWalkScore = 'SVT-1028-Connect-Use-Post-For-Address-walk-score',
    UsePostForAddressNearbyAmenities = 'SVT-1029-Connect-Use-Post-For-Address-nearby-amenities',
    UsePostForAddressRestEstimate = 'SVT-1030-Connect-Use-Post-For-Address-rent-estimate'
}
