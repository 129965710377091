import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as internalPoolSelectors from '../store/selectors';

@Component({
    selector: 'ayac-archive-worker-button',
    templateUrl: './archive-worker-button.component.html',
    styleUrls: ['./archive-worker-button.component.scss']
})
export class ArchiveWorkerButtonComponent implements OnInit {
    hasAssignment$: Observable<boolean>;
    disabledReason$: Observable<string>;
    assignmentsLoading$: Observable<boolean>;
    canArchive$: Observable<boolean>;
    disabledText = 'This clinician is assigned to shift(s). Please cancel/reassign shift(s) before you Archive the worker';
    loadingText = 'Assignments loading';

    @Output('click') click = new EventEmitter();

    constructor(
        private readonly store: Store
    ) { }

    ngOnInit(): void {
        this.hasAssignment$ = this.store.select(internalPoolSelectors.selectHasCurrentAssignment);
        this.assignmentsLoading$ = this.store.select(internalPoolSelectors.selectCurrentAssignmentsLoading);

        this.disabledReason$ = combineLatest([
            this.hasAssignment$,
            this.assignmentsLoading$
        ]).pipe(
            map(([hasAssignment, loading]) => {
                if (loading) {
                    return this.loadingText;
                }
                if (hasAssignment) {
                    return this.disabledText;
                }
                return null;
            })
        );

        this.canArchive$ = combineLatest([
            this.hasAssignment$,
            this.assignmentsLoading$
        ]).pipe(
            map(([hasAssignment, loading]) => !loading && !hasAssignment)
        )
    }

    onClick() {
        this.click.emit();
    }

}
