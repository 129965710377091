import { ListItem } from 'src/app/shared/models/list-item';
import { LoadingTypes } from 'src/app/shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { SpecialtyLookup } from 'src/app/shared/models/lookups/specialty-lookup.model';
import { loadResourcesLookupsSuccess, loadResourcesLookupsFail, loadResourcesLookupsFromServer, loadResourcesLookupsLocal } from '../actions/resources.actions';
import { SystemLookup } from 'src/app/timecards/models';
import { ResourceTypes } from 'src/app/internal-pool/models';

const preferredShifts: ListItem[] = [
    { id: 1, name: "Day" }, 
    { id: 2, name: "Evening" }, 
    { id: 3, name: "Night" }
];

const statuses: ListItem[] = [
    { id: 1, name: "Active" }, 
    { id: 0, name: "Inactive" }
];

const epicSkills: ListItem[] = [
    {id: 1, name: 'Intreg100'},
    {id: 2, name: 'Amb200'}
];

const resourceTypes: ListItem[] = [
    {id: ResourceTypes.Float, name: 'Float'},
    {id: ResourceTypes.Core, name: 'Core'}
];

export interface ResourcesLookupsState {
	error: string;
    professions: ListItem[];
    specialties: SpecialtyLookup[];
    facilities: FacilityLookup[];
    systems: SystemLookup[];
    statuses: ListItem[];
    preferredShifts: ListItem[];
    availabilities: ListItem[];
    epicSkills: ListItem[];
    languages: ListItem[];
    languageSkills: ListItem[];
    resourceTypes: ListItem[];
    loading: LoadingTypes;
}

export const initialResourcesLookupsState: ResourcesLookupsState = {
    error: null,
	facilities: [],
    professions: [],
    specialties: [],
    systems: [],
    statuses: statuses,
    preferredShifts: preferredShifts,
    availabilities: [],
    epicSkills: epicSkills,
    languages: [],
    languageSkills: [],
    resourceTypes: resourceTypes,
    loading: LoadingTypes.INIT
}

const reducer = createReducer(
		initialResourcesLookupsState,
		on(loadResourcesLookupsFromServer, (state, action) => (
			{
                ...state,
                loading: LoadingTypes.LOADING
			}
		)),
		on(loadResourcesLookupsSuccess, (state, action) => (
			{
				...state,
                facilities: action.facilities,
                professions: action.professions,
                specialties: action.specialties,
                systems: action.systems,
                languageSkills: action.languageSkillLevels,
                languages: action.languages,
                availabilities: action.availabilityStatuses,
                loading: LoadingTypes.LOADED,
			}
		)),
		on(loadResourcesLookupsFail, (state, action) => (
			{
                ...state,
                loading: LoadingTypes.FAIL
			}
        )),
		on(loadResourcesLookupsLocal, (state, action) => (
			{
                ...state
			}
        ))
	);

export function resourcesLookupsReducer(state: ResourcesLookupsState | undefined, action: Action) {
	return reducer(state, action);
}
