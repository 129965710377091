import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ArraySortPipe } from 'src/app/shared/pipes/sort.pipe';
import { JobSettings, JobSettingsSaveRequest } from '../models/job-settings.model';
import { JobSettingLookups, LookupItem } from '../models/lookups.model';
import { JobViewTypes } from '../models/vendor-job.model';
import { VendorJobPermissionService } from '../services/vendor-job-permission.service';

@Component({
    selector: 'ayac-vendor-job-permissions',
    templateUrl: './vendor-job-permissions.component.html',
    styleUrls: ['./vendor-job-permissions.component.scss']
})
export class VendorJobPermissionsComponent extends UnsubscribeOnDestroy implements OnChanges {
    get employmentTypeIdsControl(): AbstractControl | undefined {
        return this.form.get('employmentTypeIds');
    }

    get professionTypesFormArray(): UntypedFormArray | undefined {
        const result = this.form.get('professionTypes');
        if (!result) {
            return undefined;
        }
        return result as UntypedFormArray;
    }

    form: UntypedFormGroup;
    isSaving = false;

    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

    @Input() lookups?: JobSettingLookups;
    @Input() jobSettings?: JobSettings;
    @Input() vendorId: number;
    @Output() onSaveError = new EventEmitter<string>();
    @Output() onSaveSuccess = new EventEmitter();

    @Output() saveJobPermissions = new EventEmitter();
    featureFlags = FeatureFlag;

    constructor(
        private readonly jobPermissionService: VendorJobPermissionService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly sortPipe: ArraySortPipe,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
        this.form = this.formBuilder.group({
            employmentTypeIds: this.formBuilder.control([]),
            professionTypes: this.formBuilder.array([])
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.jobSettings?.currentValue || changes.lookups?.currentValue) {
            this.refreshProfessionControls();
        }
    }

    addProfessionType(
        professionTypeLookup: LookupItem,
        professionLookups: LookupItem[],
        selectedProfessionTypes: number[],
        selectedProfessions: number[]
    ): void {
        const form = this.formBuilder.group({
            professionTypeId: this.formBuilder.control(professionTypeLookup.id),
            professionTypeName: this.formBuilder.control(professionTypeLookup.name),
            isChecked: this.formBuilder.control(selectedProfessionTypes.indexOf(professionTypeLookup.id) > -1),
            professions: this.formBuilder.array([]),
            isExpanded: this.formBuilder.control(false),
            areSomeProfessionsChecked: this.formBuilder.control(false)
        });

        const sortedProfessions: LookupItem[] = this.sortPipe.transform(professionLookups, 'sort');
        sortedProfessions.forEach((profession) => {
            (form.get('professions') as UntypedFormArray).push(
                this.formBuilder.group({
                    id: this.formBuilder.control(profession.id),
                    name: this.formBuilder.control(profession.name),
                    abbreviation: this.formBuilder.control(profession.abbreviation),
                    isChecked: this.formBuilder.control(selectedProfessions.indexOf(profession.id) > -1)
                })
            );
        });

        this.refreshIntermidiateProfessionTypeState(form);

        this.professionTypesFormArray?.push(form);
    }

    refreshProfessionControls(): void {
        if (!this.lookups || !this.jobSettings) {
            return;
        }

        const employmentTypeIds: number[] = [];
        this.jobSettings?.vendorJobEmploymentTypes?.forEach((employmentType) => {
            employmentTypeIds.push(employmentType.employmentTypeId);
        });
        this.employmentTypeIdsControl.setValue(employmentTypeIds);

        const selectedProfessionTypes: number[] =
            this.jobSettings?.vendorJobProfessionTypes?.map((x) => x.professionTypeId) ?? [];
        const selectedProfessions: number[] = this.jobSettings?.vendorJobProfessions?.map((x) => x.professionId) ?? [];

        this.professionTypesFormArray?.clear();
        this.lookups.professionTypes.forEach((professionType) => {
            this.addProfessionType(
                professionType,
                this.lookups.professions.filter((x) => x.typeId === professionType.id),
                selectedProfessionTypes,
                selectedProfessions
            );
        });
    }

    professionTypeCheckChange(isChecked: boolean, professionTypesForm: UntypedFormGroup): void {
        const professionsFormArray = professionTypesForm.get('professions') as UntypedFormArray;
        professionsFormArray.controls.forEach((professionsForm) => {
            professionsForm.get('isChecked').setValue(isChecked);
        });

        this.refreshIntermidiateProfessionTypeState(professionTypesForm);
    }

    refreshIntermidiateProfessionTypeState(professionTypesForm: UntypedFormGroup): void {
        const professionsFormArray = professionTypesForm.get('professions') as UntypedFormArray;
        if (professionsFormArray.controls.length === 0) {
            professionTypesForm.get('areSomeProfessionsChecked').setValue(false);
            return;
        }

        const checkedCount = professionsFormArray.controls.filter((x) => x.get('isChecked').value === true).length;

        const areSomeChecked = checkedCount > 0 && checkedCount < professionsFormArray.controls.length;

        professionTypesForm.get('areSomeProfessionsChecked').setValue(areSomeChecked);

        const areAllChecked = checkedCount === professionsFormArray.controls.length;
        professionTypesForm.get('isChecked').setValue(areAllChecked);
    }

    getModel(): JobSettingsSaveRequest {
        const result: JobSettingsSaveRequest = {
            vendorId: this.vendorId,
            viewTypeId: JobViewTypes.AyaEmployeeView,
            settings: {
                vendorJobEmploymentTypes: [],
                vendorJobProfessions: [],
                vendorJobProfessionTypes: []
            }
        };

        const employmentTypeIds: number[] = this.employmentTypeIdsControl.value;
        employmentTypeIds.forEach((id) => {
            result.settings.vendorJobEmploymentTypes.push({
                vendorId: this.vendorId,
                employmentTypeId: id
            });
        });

        this.professionTypesFormArray.controls.forEach((professionTypeForm: UntypedFormGroup) => {
            if (professionTypeForm.get('isChecked').value) {
                result.settings.vendorJobProfessionTypes.push({
                    vendorId: this.vendorId,
                    professionTypeId: professionTypeForm.get('professionTypeId').value
                });
            }

            const professionFormArray: UntypedFormArray = professionTypeForm.get('professions') as UntypedFormArray;

            professionFormArray.controls.forEach((professionForm: UntypedFormGroup) => {
                if (!professionForm.get('isChecked').value) {
                    return;
                }

                result.settings.vendorJobProfessions.push({
                    vendorId: this.vendorId,
                    professionId: professionForm.get('id').value
                });
            });
        });

        return result;
    }

    save(): void {
        this.saveJobPermissions.emit(this.getModel());
    }
}
