import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import dayjs from 'dayjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';
import { ToasterService } from 'src/app/core/services';
import { SubmittalHelper } from 'src/app/submittals/shared/submittal-helper';
import { SubmittalOfferDetailsUpdateRequest } from 'src/app/shared/models/submittals';
import { RequestTimeOffPipe } from 'src/app/shared/pipes/request-time-off.pipe';

type DateChangeType = 'startDateChanged' | 'endDateChanged' | 'weeksLengthChanged' | 'daysLengthChanged';

const RtoApprovalValidator: ValidatorFn = (formControl: FormControl) => {
    if (!formControl.parent) {
        return null;
    }
    const rtoDates = formControl.parent.get('rtoDates').value;
    const isRtoApproved = formControl.value;
    return !rtoDates.length && isRtoApproved ? { rtoApproval: true } : null;
};

@Component({
    selector: 'ayac-update-offer-details-form',
    templateUrl: './update-offer-details-form.component.html',
    styleUrls: ['./update-offer-details-form.component.scss'],
    providers: [RequestTimeOffPipe]
})
export class UpdateOfferDetailsFormComponent extends UnsubscribeOnDestroy implements OnInit {
    @Output() saveOffer = new EventEmitter<SubmittalOfferDetailsUpdateRequest>();
    @Output() canUpdate = new EventEmitter<boolean>();
    @Input() offer: SubmittalOfferDetailsUpdateRequest;

    minStartDate = new Date();
    minEndDate: Date;

    offerForm: FormGroup;

    private readonly collectOfferDetailsUpdateRequest$ = new Subject<boolean>();

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _toasterService: ToasterService,
        private readonly _rtoDatesFormat: RequestTimeOffPipe
    ) {
        super();
    }

    @Input()
    set collectOfferDetailsUpdateRequest(value: boolean) {
        this.collectOfferDetailsUpdateRequest$.next(value);
    }

    rtoDatesFormat = (date: Date[]) => {
        return this._rtoDatesFormat.transform(date, 'MM/DD/YYYY');
    };

    ngOnInit(): void {
        this.updateMinEndDate(this.offer.startDate);
        this.buildForm();
        this.collectOfferDetailsUpdateRequest$.pipe(takeUntil(this.d$)).subscribe(() => {
            const startDate = Array.isArray(this.offerForm.get('startDate').value)
                ? this.offerForm.get('startDate').value[0]
                : this.offerForm.get('startDate').value;
            const endDate = Array.isArray(this.offerForm.get('endDate').value)
                ? this.offerForm.get('endDate').value[0]
                : this.offerForm.get('endDate').value;
            this.saveOffer.emit({ ...this.offerForm.value, startDate, endDate });
        });
    }

    updateMinEndDate(date: Date): void {
        this.minEndDate = dayjs(date).subtract(1, 'day').toDate();
    }

    calculateContractDates(changeType: DateChangeType): void {
        const locale = 'en-US';
        const dateFormat = 'M/d/yyyy';
        const startDateControl = this.offerForm.get('startDate');
        const endDateControl = this.offerForm.get('endDate');
        const weeksLengthControl = this.offerForm.get('weeksLength');
        const daysLengthControl = this.offerForm.get('daysLength');

        switch (changeType) {
            case 'startDateChanged':
            case 'weeksLengthChanged': {
                if (!startDateControl.value?.length || !weeksLengthControl.value) {
                    return;
                }
                const endDate = SubmittalHelper.calculateContractEndDateByWeeksLength(
                    new Date(startDateControl.value),
                    weeksLengthControl.value
                );
                const daysLength = SubmittalHelper.calculateContractDaysLength(
                    new Date(startDateControl.value),
                    endDate
                );
                endDateControl.patchValue(endDate);
                daysLengthControl.patchValue(daysLength);

                if (changeType === 'startDateChanged') {
                    const newStartDate = Array.isArray(startDateControl.value)
                        ? startDateControl.value[0]
                        : startDateControl.value;
                    this.updateMinEndDate(newStartDate);
                }

                const changedField = changeType === 'startDateChanged' ? 'Start Date' : 'Contract Weeks Length';
                this._toasterService.info(
                    `Your change to the ${changedField} updated the offer End Date to ${formatDate(
                        endDate,
                        dateFormat,
                        locale
                    )}`
                );
                break;
            }

            case 'endDateChanged': {
                if (!startDateControl.value?.length || !endDateControl.value?.length) {
                    return;
                }
                const weeksLength = SubmittalHelper.calculateContractWeeksLength(
                    new Date(startDateControl.value),
                    new Date(endDateControl.value)
                );
                const daysLength = SubmittalHelper.calculateContractDaysLength(
                    new Date(startDateControl.value),
                    new Date(endDateControl.value)
                );
                weeksLengthControl.patchValue(weeksLength);
                daysLengthControl.patchValue(daysLength);

                this._toasterService.info(
                    `Your change to the End Date updated the offer End Date to ${formatDate(
                        new Date(endDateControl.value),
                        dateFormat,
                        locale
                    )}`
                );
                break;
            }

            case 'daysLengthChanged': {
                if (!startDateControl.value?.length || !daysLengthControl.value) {
                    return;
                }
                const endDate = SubmittalHelper.calculateContractEndDateByDaysLength(
                    new Date(startDateControl.value),
                    daysLengthControl.value
                );
                const weeksLength = SubmittalHelper.calculateContractWeeksLength(
                    new Date(startDateControl.value),
                    endDate
                );
                endDateControl.patchValue(endDate);
                weeksLengthControl.patchValue(weeksLength);

                this._toasterService.info(`Your change to the Contract Days Length
                    updated the offer End Date to ${formatDate(endDate, dateFormat, locale)}
                    and Contract Weeks Length to ${weeksLength}`);
                break;
            }
        }
    }

    hasError = (controlName: string, errorName: string) => {
        return this.offerForm.controls[controlName].hasError(errorName);
    };

    private buildForm(): void {
        this.offerForm = this._fb.group({
            offerId: [this.offer.offerId],
            startDate: [this.offer.startDate],
            endDate: [this.offer.endDate],
            weeksLength: [
                SubmittalHelper.calculateContractWeeksLength(
                    new Date(this.offer.startDate),
                    new Date(this.offer.endDate)
                ),
                [Validators.required]
            ],
            daysLength: [
                SubmittalHelper.calculateContractDaysLength(
                    new Date(this.offer.startDate),
                    new Date(this.offer.endDate)
                ),
                [Validators.required]
            ],
            isRtoApproved: [this.offer.isRtoApproved, [RtoApprovalValidator]],
            rtoDates: [this.offer.rtoDates]
        });

        this.offerForm
            .get('startDate')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe(() => this.calculateContractDates('startDateChanged'));

        this.offerForm
            .get('endDate')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe(() => this.calculateContractDates('endDateChanged'));

        this.offerForm
            .get('rtoDates')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe(() => this.offerForm.get('isRtoApproved').updateValueAndValidity());

        this.offerForm.statusChanges
            .pipe(takeUntil(this.d$))
            .subscribe((status) => this.canUpdate.emit(status === 'VALID'));

        this.offerForm.updateValueAndValidity();
    }
}
