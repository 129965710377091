import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmationDialogData } from 'src/app/shared/models/dialog.models';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: 'confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogData
    ) {}

    close(isConfirmed: boolean = false): void {
        this.dialogRef.close(isConfirmed);
    }

    get confirmButtonText(): string {
        return this.data && this.data.confirmButtonText ? this.data.confirmButtonText : 'Confirm';
    }

    get cancelButtonText(): string {
        return this.data && this.data.cancelButtonText ? this.data.cancelButtonText : 'Cancel';
    }

    get confirmButtonColor(): string {
        return this.data && this.data.isWarn ? 'warn' : 'accent';
    }

    get isDeleteConfirmation(): boolean {
        return this.data && this.data.isDeleteConfirmation ? true : false;
    }
}
