import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { VendorProfileService } from 'src/app/admin/vendor-details/profile/vendor-profile.service';
import { catchError, exhaustMap, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { vendorProfileActions } from 'src/app/admin/store/actions/vendor-profile.actions';
import { Store } from '@ngrx/store';
import { selectVendorSpecificContractLineTypes } from 'src/app/admin/store/selectors/vendor-profile.selectors';
import { VendorJobPermissionService } from '../../vendor-details/services';
import { ToasterService } from 'src/app/core/services';
import { VendorService } from '../../vendor-details/profile/services/vendor.service';

const VENDOR_CONTACT_PERMISSIONS = ['submittals_offerDetail', 'dashboard', 'dashboard_offers'];

@Injectable()
export class VendorProfileEffects {
    getVendorProfileContractTypes$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.loadContractLineTypes, vendorProfileActions.vendorProfileComponentLoaded),
            exhaustMap(() => {
                return this._vendorProfileService.getContractLineTypes().pipe(
                    map((result) => vendorProfileActions.loadContractLineTypesSuccess({ lineTypes: result })),
                    catchError((error: unknown) => of(vendorProfileActions.loadContractLineTypesFailure({ error })))
                );
            })
        );
    });

    getVendorSpecificContractTypesByVendorId$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.loadContractLineTypesByVendorId),
            exhaustMap((action) => {
                return this._vendorProfileService.getContractLineTypesByVendorId(action.vendorId).pipe(
                    map((result) => vendorProfileActions.loadContractLineTypesByVendorIdSuccess({ types: result })),
                    catchError((error: unknown) =>
                        of(vendorProfileActions.loadContractLineTypesbyVendorIdFailure({ error }))
                    )
                );
            })
        );
    });

    updateVendorSpecificContractTypes$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.updateContractLineTypesByVendorId),
            withLatestFrom(this._store.select(selectVendorSpecificContractLineTypes)),
            exhaustMap(([action, types]) => {
                return this._vendorProfileService.updateContractLineTypesByVendorId(action.vendorId, types).pipe(
                    map(() => vendorProfileActions.updateContractLineTypesByVendorIdSuccess()),
                    catchError((error: unknown) =>
                        of(vendorProfileActions.updateContractLineTypesByVendorIdFailure({ error }))
                    )
                );
            })
        );
    });

    updateVendorTypeTags$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.updateVendorTypeTags),
            exhaustMap((action) => {
                return this._vendorProfileService.updateVendorTypeTags(action.vendorId, action.tagIds).pipe(
                    map(() => vendorProfileActions.updateVendorTypeTagsSuccess({ vendorId: action.vendorId })),
                    catchError((error: unknown) => of(vendorProfileActions.updateVendorTypeTagsFailure({ error })))
                );
            })
        );
    });

    getVendorDetails$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.loadVendorDetails),
            exhaustMap((action) => {
                return this._vendorProfileService.getVendorDetails(action.vendorId).pipe(
                    map((result) => vendorProfileActions.loadVendorDetailsSuccess({ details: result })),
                    catchError((error: unknown) => of(vendorProfileActions.loadVendorDetailsFailure({ error })))
                );
            })
        );
    });

    getVendorBusinessTypes$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.loadVendorBusinessTypes, vendorProfileActions.vendorProfileComponentLoaded),
            exhaustMap(() => {
                return this._vendorProfileService.getVendorBusinessTypes().pipe(
                    map((result) => vendorProfileActions.loadVendorBusinessTypesSuccess({ businessTypes: result })),
                    catchError((error: unknown) => of(vendorProfileActions.loadVendorBusinessTypesFailure({ error })))
                );
            })
        );
    });

    getVendorTypes$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.loadVendorTypes, vendorProfileActions.vendorProfileComponentLoaded),
            exhaustMap(() => {
                return this._vendorProfileService.getVendorTypes().pipe(
                    map((types) => vendorProfileActions.loadVendorTypesSuccess({ vendorTypes: types })),
                    catchError((error: unknown) => of(vendorProfileActions.loadVendorTypesFailure({ error })))
                );
            })
        );
    });

    getSelectedVendorTypeTagsByVendorId$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(
                vendorProfileActions.loadSelectedVendorTypesWithVendorId,
                vendorProfileActions.updateVendorTypeTagsSuccess
            ),
            exhaustMap((action) => {
                return this._vendorProfileService.getSelectedVendorTypes(action.vendorId).pipe(
                    map((types) =>
                        vendorProfileActions.loadSelectedVendorTypesWithVendorIdSuccess({ selectedVendorTypes: types })
                    ),
                    catchError((error: unknown) =>
                        of(vendorProfileActions.loadSelectedVendorTypesWithVendorIdFailure({ error }))
                    )
                );
            })
        );
    });

    addOfferPermissionsToVendorContacts$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.addVendorContactsPermissions),
            exhaustMap((action) => {
                return this._vendorProfileService
                    .addPermissionsToVendorContacts(action.vendorId, VENDOR_CONTACT_PERMISSIONS)
                    .pipe(
                        map(() => vendorProfileActions.addVendorContactsPermissionsSuccess()),
                        catchError((error: unknown) =>
                            of(vendorProfileActions.addVendorContactsPermissionsFailure({ error }))
                        )
                    );
            })
        );
    });

    removeOfferPermissionsToVendorContacts$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.removeVendorContactsPermissions),
            exhaustMap((action) => {
                return this._vendorProfileService
                    .removePermissionsToVendorContacts(action.vendorId, VENDOR_CONTACT_PERMISSIONS)
                    .pipe(
                        map(() => vendorProfileActions.removeVendorContactsPermissionsSuccess()),
                        catchError((error: unknown) =>
                            of(vendorProfileActions.removeVendorContactsPermissionsFailure({ error }))
                        )
                    );
            })
        );
    });

    // DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
    getVendorJobLookups$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.loadJobLookups),
            exhaustMap((action) => {
                return this.vendorService.getJobLookups().pipe(
                    map((result) => vendorProfileActions.loadJobLookupsSuccess({ result })),
                    catchError((error: unknown) => of(vendorProfileActions.loadJobLookupsFailure({ error })))
                );
            })
        );
    });

    // DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
    getVendorJobPermissions$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.loadJobPermissions, vendorProfileActions.saveJobPermissionsSuccess),
            exhaustMap((action) => {
                return this.vendorService.getJobPermissions(action.vendorId).pipe(
                    map((result) => vendorProfileActions.loadJobPermissionsSuccess({ result })),
                    catchError((error: unknown) => of(vendorProfileActions.loadJobPermissionsFailure({ error })))
                );
            })
        );
    });

    // DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
    saveJobPermissions$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileActions.saveJobPermissions),
            exhaustMap((action) =>
                this.vendorJobPermissionService.saveJobSettings(action.perms).pipe(
                    switchMap(() =>
                        of(vendorProfileActions.saveJobPermissionsSuccess({ vendorId: action.perms.vendorId }))
                    ),
                    catchError((error: unknown) => of(vendorProfileActions.saveJobPermissionsFailure({ error })))
                )
            )
        );
    });

    // DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
    saveJobPermissionsFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(vendorProfileActions.saveJobPermissionsFailure),
                map(() => this._toaster.fail('Permissions have not been updated'))
            );
        },
        { dispatch: false }
    );

    updateContractLineTypesByVendorIdFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(vendorProfileActions.updateContractLineTypesByVendorIdFailure),
                map(() =>
                    this._toaster.fail(
                        'Error updating contract lines for vendor. Please refresh the page and try again'
                    )
                )
            );
        },
        { dispatch: false }
    );

    // DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
    saveJobPermissionsToast$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(vendorProfileActions.saveJobPermissionsSuccess),
                map(() => this._toaster.success('Permissions have been updated'))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _vendorProfileService: VendorProfileService,
        private readonly _store: Store,
        private readonly _toaster: ToasterService,
        private readonly vendorJobPermissionService: VendorJobPermissionService,
        private readonly vendorService: VendorService
    ) {}
}
