import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomainService } from '../../services/domain.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationPermissions } from "../../models/enums/application-permissions.enum";
import { IdentityService } from "../../services/identity.service";

@Component({
  selector: 'ayac-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss']
})
export class WelcomeDialogComponent implements OnInit {
  email$: Observable<string>;
  hasTerminologyPermission = false;

  constructor(
    private readonly dialogRef: MatDialogRef<WelcomeDialogComponent>,
    private readonly domain: DomainService,
    private readonly _identityService: IdentityService
  ) {

    this.email$ = of(this.domain.environment())
      .pipe(map((env) => {
        switch(env) {
          case 'AYA':
            return 'ClientConnectSupport@ayahealthcare.com';
          case 'LTS':
            return 'ClientConnectSupport@lotusworkforce.com';
          default:
            return 'ClientConnectSupport@ayahealthcare.com';
        }
      }))
  }

  ngOnInit(): void {
    this.hasTerminologyPermission = this._identityService.hasSecurityPermission(
      ApplicationPermissions.LocumsTerminology
    );
  }

  dismiss() {
    this.dialogRef.close();
  }

}
