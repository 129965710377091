import { createAction, props } from '@ngrx/store';
import { Facility, Inbox, InboxStats, Message, Thread } from 'src/app/client/message-center/models';
import { ThreadState } from '../../message-center/models/thread-state.model';

export const getInbox = createAction(
    '[MESSAGE CENTER COMPONENT] Get Inbox',
    props<{ page: number; pageSize: number; onlyShowUnread?: boolean }>()
);
export const getInboxSuccess = createAction('[MESSAGE CENTER SERVICE] Get Inbox Success', props<{ inbox: Inbox }>());
export const getInboxFailure = createAction('[MESSAGE CENTER SERVICE] Get Inbox Failure', props<{ error: unknown }>());

export const getInboxStatistics = createAction('[MESSAGE CENTER COMPONENT] Get Inbox Statistics');
export const getInboxStatisticsSuccess = createAction('[MESSAGE CENTER SERVICE] Get Inbox Statistics Success', props<{ inboxStats: InboxStats }>());
export const getInboxStatisticsFailure = createAction('[MESSAGE CENTER SERVICE] Get Inbox Statistics Failure', props<{ error: unknown }>());

export const refreshThreadList = createAction(
    '[MESSAGE CENTER COMPONENT] Refresh Inbox',
    props<{ page: number; pageSize: number; onlyShowUnread?: boolean }>()
);
export const refreshThreadListSuccess = createAction(
    '[MESSAGE CENTER SERVICE] Refresh Inbox Success',
    props<{ inbox: Inbox }>()
);
export const refreshThreadListFailure = createAction(
    '[MESSAGE CENTER SERVICE] Refresh Inbox Failure',
    props<{ error: unknown }>()
);

export const getUnreadThreadsCount = createAction('[TOP NAV COMPONENT] Get Unread Thread Count');
export const getUnreadThreadsCountForDash = createAction('[DASHBOARD COMPONENT] Get Unread Thread Count');
export const getUnreadThreadsCountSuccess = createAction(
    '[MESSAGE CENTER SERVICE] Get Unread Thread Count Success',
    props<{ unreadThreads: number }>()
);
export const getUnreadThreadsCountFailure = createAction(
    '[MESSAGE CENTER SERVICE] Get Unread Thread Count Failure',
    props<{ error: unknown }>()
);

export const getFacilities = createAction('[MESSAGE CENTER COMPONENT] Get List of Facilities');
export const getFacilitiesSuccess = createAction(
    '[MESSAGE CENTER SERVICE] Get List of Facilities Success',
    props<{ facilities: Facility[] }>()
);
export const getFacilitiesFailure = createAction(
    '[MESSAGE CENTER SERVICE] Get List of Facilities Failure',
    props<{ error: unknown }>()
);

export const getThread = createAction(
    '[MESSAGE CENTER COMPONENT] Get Thread',
    props<{ threadKey: string; showAsRead: boolean }>()
);
export const getThreadSuccess = createAction(
    '[MESSAGE CENTER SERVICE] Get Thread Success',
    props<{ thread: Thread; showAsRead: boolean }>()
);
export const getThreadFailure = createAction(
    '[MESSAGE CENTER SERVICE] Get Thread Failure',
    props<{ error: unknown }>()
);
export const getThreadState = createAction(
    '[MESSAGE CENTER COMPONENT] Get Thread State',
    props<{ thread: Thread }>()
);
export const getThreadStateSuccess = createAction(
    '[MESSAGE CENTER SERVICE] Get Thread State Success',
    props<{ threadState: ThreadState }>()
);
export const getThreadStateFailure = createAction(
    '[MESSAGE CENTER SERVICE] Get Thread State Failure',
    props<{ error: unknown }>()
);
export const sendReplyToThread = createAction(
    '[MESSAGE CENTER COMPONENT] Reply to Thread',
    props<{ facilityId: number | null; threadKey: string; message: string }>()
);
export const sendReplyToThreadSuccess = createAction(
    '[MESSAGE CENTER SERVICE] Reply to Thread Success',
    props<{ facilityId: number | null; threadKey: string; message: Message }>()
);

export const sendReplyToThreadFailure = createAction(
    '[MESSAGE CENTER SERVICE] Reply to Thread Failure',
    props<{ facilityId: number | null; threadKey: string; message: Message }>()
);

export const markThreadAsUnread = createAction(
    '[MESSAGE CENTER COMPONENT] Mark Thread as Unread',
    props<{ facilityId: number | null; threadKey: string }>()
);
export const markThreadAsUnreadSuccess = createAction(
    '[MESSAGE CENTER COMPONENT] Mark Thread as Unread Success',
    props<{ threadKey: string }>()
);
export const markThreadAsUnreadFailure = createAction(
    '[MESSAGE CENTER COMPONENT] Mark Thread as Unread Failure',
    props<{ error: unknown }>()
);

export const upsertSharedMailbox = createAction(
    '[MESSAGE CENTER COMPONENT] Create/update Shared Mailbox',
    props<{ sharedMailboxKey: string; facilityId: number }>()
);
export const upsertSharedMailboxSuccess = createAction('[MESSAGE CENTER SERVICE] Create/update Shared Mailbox Success');
export const upsertSharedMailboxFailure = createAction(
    '[MESSAGE CENTER SERVICE] Create/update Shared Mailbox Failure',
    props<{ error: unknown }>()
);

export const upsertUserInfo = createAction(
    '[MESSAGE CENTER COMPONENT] Create/update user information in communication hub',
    props<{ sharedMailboxKey: string; facilityId: number }>()
);
export const upsertUserInfoSuccess = createAction(
    '[MESSAGE CENTER SERVICE] Create/update user information in communication hub Success',
    props<{ facilities: Facility[] }>()
);
export const upsertUserInfoFailure = createAction(
    '[MESSAGE CENTER SERVICE] Create/update user information in communication hub Failure',
    props<{ error: unknown }>()
);

export const eventThreadHasNewMessage = createAction(
    '[HUB CONNECTION SERVICE] Event Received Has New Message',
    props<{ threadKey: string }>()
);
export const eventThreadHasNewMessageSuccess = createAction(
    '[HUB CONNECTION SERVICE] Event Received Has New Message Success',
    props<{ thread: Thread }>()
);

export const eventThreadMarkedAsUnread = createAction(
    '[HUB CONNECTION SERVICE] Event Received Thread Marked As Unread Success',
    props<{ threadKey: string }>()
);

export const signalREventError = createAction(
    '[HUB CONNECTION SERVICE] Event Received Error',
    props<{ error: unknown }>()
);

export const toggleOnlyShowUnread = createAction(
    '[MESSAGE CENTER COMPONENT] Toggle only show unread threads',
    props<{ page: number; pageSize: number; onlyShowUnread: boolean }>()
);

export const openNewlyComposedThread = createAction(
    '[NEW MESSAGE MODAL COMPONENT] Open newly composed thread',
    props<{ page: number; pageSize: number; onlyShowUnread: boolean }>()
);

export const closeThread = createAction('[MESSAGE CENTER COMPONENT] Close Thread');
