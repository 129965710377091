import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { BackgroundHistoryFormComponent } from 'src/app/shared/forms/background-history-form/background-history-form.component';
import { CandidateProfileFormComponent } from 'src/app/shared/forms/candidate-profile-form/candidate-profile-form.component';
import { CertificationsFormComponent } from 'src/app/shared/forms/certifications-form/certifications-form.component';
import { ExpertiseFormComponent } from 'src/app/shared/forms/expertise-form/expertise-form.component';
import { LicensesFormComponent } from 'src/app/shared/forms/licenses-form/licenses-form.component';
import { VendorContact } from 'src/app/shared/models';
import { PhoneFormComponent } from 'src/app/shared/forms/phone-form/phone-form.component';
import { ListItem } from 'src/app/shared/models/list-item';
import { isEmptyOrSpaces } from 'src/app/shared/utilities/is-empty';
import { BackgroundHistory, Certification, Expertise, StateLicense } from 'src/app/shared/models/candidate';
import { CandidateProfileTabDetails, VendorCandidate } from '../../vendor-candidate.model';
import { VendorCandidateService } from '../../vendor-candidate.service';
import { CandidateService } from 'src/app/shared/services/candidate.service';
import { Phone } from 'src/app/shared/models/candidate/phone.model';
import { FeatureFlag, FeatureFlags } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';

@Component({
    selector: 'ayac-admin-vendor-candidate-profile-tab',
    templateUrl: './profile-tab.component.html',
    styleUrls: ['./profile-tab.component.scss']
})
export class ProfileTabComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild(CandidateProfileFormComponent, { static: false })
    candidateProfileForm: CandidateProfileFormComponent;

    @ViewChild(CertificationsFormComponent, { static: false })
    certificationsForm: CertificationsFormComponent;

    @ViewChild(ExpertiseFormComponent, { static: false })
    expertiseForm: ExpertiseFormComponent;

    @ViewChild(BackgroundHistoryFormComponent, { static: false })
    backgroundHistoryForm: BackgroundHistoryFormComponent;

    @ViewChild(LicensesFormComponent, { static: false })
    licensesForm: LicensesFormComponent;

    @ViewChild(PhoneFormComponent, { static: false })
    phonesForm: PhoneFormComponent;

    isLicensureFormValid = false;

    @Output() valueChanged = new EventEmitter<CandidateProfileTabDetails>();
    @Output() candidateLoaded = new EventEmitter<CandidateProfileTabDetails>();

    @Input() saveCalled?: EventEmitter<void>;

    @Input() set vendorId(vendorId: number) {
        if (!vendorId) {
            return;
        }

        this.refreshVendorContacts(vendorId);
    }

    @Output() onIsSavingChanged = new EventEmitter<boolean>();
    @Output() onSaveSuccess = new EventEmitter<{
        candidateId: number;
        isNew: boolean;
    }>();

    @Output() onSaveError = new EventEmitter<any>();
    @Output() onIsFormValidChanged = new EventEmitter<boolean>(true);
    form: UntypedFormGroup;
    candidate?: VendorCandidate;
    additionalValidation = false;
    needsPhoneNumber = false;
    certifications$!: Observable<ListItem[]>;
    vendorContacts: VendorContact[] = [];
    vendors$!: Observable<ListItem[]>;
    private _getVendorContactsSubscription: Subscription;
    private _savedCalledSubscription: Subscription;
    phoneTypes$: Observable<ListItem[]>;
    otherCandidateInfo$: Observable<any>;
    private obfuscateVendorCandidatesSsnId = false;
    private skipUnnecessaryRequestsNewVendorCandidate = false;

    private _candidateId?: number;
    @Input()
    set candidateId(candidateId: number) {
        this._candidateId = candidateId;
        if (!candidateId) {
            return;
        }

        this._lDFeatureManager
            .isEnabled(FeatureFlag.SkipUnnecessaryRequestsNewAdminVendorCandidate)
            .pipe(takeUntil(this.d$))
            .subscribe({
                next: (isEnabled: boolean) => {
                    this.skipUnnecessaryRequestsNewVendorCandidate = isEnabled;
                },
                error: () => {}
            });

        forkJoin({
            candidate:
                this.isNew && this.skipUnnecessaryRequestsNewVendorCandidate
                    ? of(null as VendorCandidate)
                    : this._vendorCandidateService.getVendorCandidate(candidateId),
            phones:
                this.isNew && this.skipUnnecessaryRequestsNewVendorCandidate
                    ? of([])
                    : this._vendorCandidateService.getPhones(candidateId),
            expertiseList:
                this.isNew && this.skipUnnecessaryRequestsNewVendorCandidate
                    ? of([])
                    : this._vendorCandidateService.getCandidateExpertiseList(candidateId),
            certificationList:
                this.isNew && this.skipUnnecessaryRequestsNewVendorCandidate
                    ? of([])
                    : this._vendorCandidateService.getCandidateCertifications(candidateId),
            backgroundHistory: this._vendorCandidateService.getBackgroundHistories(candidateId),
            stateLicenses:
                this.isNew && this.skipUnnecessaryRequestsNewVendorCandidate
                    ? of([])
                    : this._vendorCandidateService.getStateLicenses(candidateId)
        })
            .pipe(first())
            .subscribe((result) => {
                this.candidate = result.candidate;
                this.populate(
                    result.candidate,
                    result.phones,
                    result.expertiseList,
                    result.certificationList,
                    result.backgroundHistory,
                    result.stateLicenses
                );
            });
    }

    get candidateId(): number | undefined {
        return this._candidateId;
    }

    get isNew(): boolean {
        return !this.candidateId || this.candidateId <= 0;
    }

    @Input() jobId: number;

    featureFlags = FeatureFlags;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _vendorCandidateService: VendorCandidateService,
        private readonly _route: ActivatedRoute,
        private readonly _candidateService: CandidateService,
        private readonly _lDFeatureManager: LDFeatureManager
    ) {
        super();
        this.form = this._formBuilder.group({});
    }

    ngOnInit(): void {
        this.phoneTypes$ = this._candidateService.getPhoneTypes();

        this._route.queryParams.pipe(takeUntil(this.d$)).subscribe((params) => {
            this.additionalValidation = params.additionalReqs ? JSON.parse(params.additionalReqs) : false;
        });

        this.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((changeValue) => {
            this.onIsFormValidChanged.emit(this.form.valid);
        });

        this._lDFeatureManager
            .isEnabled(FeatureFlag.ObfuscateVendorCandidatesSsnId)
            .pipe(takeUntil(this.d$))
            .subscribe({
                next: (isEnabled: boolean) => {
                    this.obfuscateVendorCandidatesSsnId = isEnabled;
                },
                error: () => {}
            });

        this._savedCalledSubscription?.unsubscribe();

        this._savedCalledSubscription = this.saveCalled
            ?.pipe(
                tap((saveCalled) => this.onIsSavingChanged.emit(true)),
                switchMap((saveCalled) => {
                    const candidate = this.candidateProfileForm.getCandidateForUpdate();
                    candidate.professionId = this.expertiseForm.getSelectedProfession();
                    return this.isNew
                        ? this._vendorCandidateService.createCandidate(candidate)
                        : this._vendorCandidateService.updateCandidate(this.candidateId, candidate);
                }),
                switchMap((savedCandidate) =>
                    forkJoin({
                        saveCertificationsResult: this._vendorCandidateService.saveCertifications(
                            savedCandidate.id,
                            this.certificationsForm.getCertifications()
                        ),
                        saveExpertiseResult: this._vendorCandidateService.saveExpertise(
                            savedCandidate.id,
                            this.expertiseForm.getExpertiseList()
                        ),
                        saveBackgroundHistoryResult: this._vendorCandidateService.saveBackgroundHistory(
                            savedCandidate.id,
                            this.backgroundHistoryForm.getBackgroundHistory()
                        ),
                        saveLicenseResult: this._vendorCandidateService.saveLicenses(
                            savedCandidate.id,
                            this.licensesForm.getLicenses()
                        ),
                        savePhonesresult: this._vendorCandidateService.savePhone(
                            savedCandidate.id,
                            this.phonesForm.getPhones()
                        ),
                        savedCandidate: of(savedCandidate)
                    })
                ),
                catchError(
                    (
                        error: unknown,
                        erroredObservable$: Observable<{
                            saveCertificationsResult: Certification[];
                            saveExpertiseResult: void;
                            saveBackgroundHistoryResult: BackgroundHistory[];
                            saveLicenseResult: StateLicense[];
                            savePhonesresult: void;
                            savedCandidate: VendorCandidate;
                        }>
                    ) => {
                        this.onSaveError.emit(error);
                        return erroredObservable$;
                    }
                ),
                takeUntil(this.d$)
            )
            .subscribe((saveResult) => {
                this.onIsSavingChanged.emit(false);
                this.onSaveSuccess.emit({
                    candidateId: saveResult.savedCandidate.id,
                    isNew: this.isNew
                });
            });

        this.certifications$ = this._vendorCandidateService.getCertifications();
        this.vendors$ = this._vendorCandidateService
            .getVendors()
            .pipe(map((vendors) => vendors.filter((x) => !isEmptyOrSpaces(x.name))));
    }

    getOtherInfo() {
        this.otherCandidateInfo$ = this._vendorCandidateService.getOtherInfo(
            this.obfuscateVendorCandidatesSsnId ? this.candidate.ssnId : `${this.candidate.id}`
        );
    }

    populate(
        candidate: VendorCandidate,
        phoneList: Phone[],
        expertiseList: Expertise[],
        certificationList: Certification[],
        backgroundHistory: BackgroundHistory[],
        licenses: StateLicense[]
    ): void {
        this.candidateProfileForm.populate(candidate);
        this.expertiseForm.populate(candidate?.professionId, expertiseList);
        this.certificationsForm.populate(certificationList);
        this.backgroundHistoryForm.populate(backgroundHistory);
        this.licensesForm.populate(licenses);
        this.phonesForm.populate(phoneList);
        this.candidateLoaded.emit(this.getModel());
    }

    onValueChanged(): void {
        this.valueChanged.emit(this.getModel());
    }

    getModel(): CandidateProfileTabDetails {
        return {
            candidate: this.candidateProfileForm.getCandidate(),
            phones: this.phonesForm.getPhones(),
            expertise: this.expertiseForm.getExpertiseList(),
            certifications: this.certificationsForm.getCertifications(),
            backgroundHistories: this.backgroundHistoryForm.getBackgroundHistory(),
            licenses: this.licensesForm.getLicenses()
        };
    }

    refreshVendorContacts(vendorId: number): void {
        this._getVendorContactsSubscription?.unsubscribe();
        if (!vendorId) {
            return;
        }
        this._getVendorContactsSubscription = this._vendorCandidateService
            .getVendorContacts(vendorId)
            .pipe(first())
            .subscribe((vendorContacts) => (this.vendorContacts = vendorContacts));
    }
}
