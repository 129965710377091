import { IdentityService } from 'src/app/shared/services/identity.service';
import { Injectable } from '@angular/core';
import { ShiftsClientPermissions } from 'src/app/shared/models/shifts/enums/shifts-client-permissions.enum';
import { InternalPoolClientPermissions } from 'src/app/shared/models/enums/client-permissions.enum';
@Injectable({
    providedIn: 'root'
})
export class ShiftsAuthService {

    constructor(private readonly identityService: IdentityService){}

    canEditShift() : boolean {
        return this.identityService.inScope("client", ShiftsClientPermissions.ShiftEdit);
    }

    canAddShift() : boolean {
        return this.identityService.inScope("client", ShiftsClientPermissions.ShiftAdd);
    }

    canBulkAddShift() : boolean {
        return this.identityService.inScope("client", ShiftsClientPermissions.ShiftAddBulk);
    }

    canViewShiftDetails() : boolean {
        return this.identityService.inScope("client", ShiftsClientPermissions.ShiftDetails);
    }
    canViewInternalPool(): boolean {
        return this.identityService.inScope("client", InternalPoolClientPermissions.InternalPoolView)
    }

    canViewAttachments() {
        return this.identityService.inScope('client', ShiftsClientPermissions.AttachmentsView);
    }

    canEditAttachments() {
        return this.identityService.inScope('client', ShiftsClientPermissions.AttachmentsEdit);
    }

    canEditBlockBooking() {
        return this.identityService.inScope('client', InternalPoolClientPermissions.BlockSeriesEdit);
    }

    canManageApprovals() {
        return this.identityService.inScope('shifts', ShiftsClientPermissions.Approve);
    }

    canViewShiftsSubmittal() {
        return this.identityService.inScope('client', InternalPoolClientPermissions.ShiftSubmittalView);
     }

    canEditShiftsSubmittal() {
        return this.identityService.inScope('client', InternalPoolClientPermissions.ShiftSubmittalEdit);
    }

    canLoadShiftsSubmittal() {
        return this.canViewShiftsSubmittal() || this.canEditShiftsSubmittal();
    }

     canViewBlockBooking() {
        return this.identityService.inScope('client', InternalPoolClientPermissions.BlockSeriesView);
    }

    canRelease() {
        return this.identityService.inScope('client', ShiftsClientPermissions.ShiftsRelease);
    }

    canViewManageTemplates() {
        return this.identityService.inScope('client', ShiftsClientPermissions.ManageTemplatesView);
    }
    
    canEditManageTemplates() {
        return this.identityService.inScope('client', ShiftsClientPermissions.ManageTemplatesEdit);
    }

    canViewGlobalResourceFulfillment() {
        return this.identityService.inScope('client', InternalPoolClientPermissions.ResourceFulfillmentView);
    }

    canEditGlobalResourceFulfillment() {
        return this.identityService.inScope('client', InternalPoolClientPermissions.ResourceFulfillmentEdit);
    }

    canViewLocalResourceFulfillment() {
        return this.identityService.inScope('client', InternalPoolClientPermissions.ResourceFulfillmentShiftView);
    }

    canEditLocalResourceFulfillment() {
        return this.identityService.inScope('client', InternalPoolClientPermissions.ResourceFulfillmentShiftEdit);
    }
}
