export enum ConnexusFeatureFlags {
    VendorCandidateDobValidation = 'VENDOR_CANDIDATE_DOB_VALIDATION',
    ManageFeaturesContractedDateCol = 'MANAGE_FEATURES_CONTRACTED_DATE_COL',
    ObfuscateVendorCandidatesSsnId = 'obfuscate-vendor-candidates-ssn-id',
    VendorJobsDetailsPayWhenPaidIndicator = 'vendor-jobs-details-pay-when-paid-indicator',
    VendorSubmittalsDetailsPayWhenPaidIndicator = 'vendor-submittals-details-pay-when-paid-indicator',
    VendorCandidatesAllowMultipleSelectionsOnPreferredShiftField = 'vendor-candidates-allow-multiple-selections-on-preferred-shift-field',
    OnlyShowDetailsOfJobsWhichAreAvailableOnJobsGrid = 'only-show-details-of-jobs-which-are-available-on-jobs-grid',
    EnableDebounceTimeInGridTextFilters = 'enable-debounce-time-on-grid-text-filters',
    RemoveVendorHelpPrivacyPolicy = 'REMOVE_VENDOR_HELP_PRIVACY_POLICY',
    SystemContactsSearchRole = 'system-contacts-search-role',
    ProtectSocialSecurityNumberVendorCandidate = 'ProtectSocialSecurityNumberVendorCandidate',
    RemoveVendorJobPermissionsSection = 'RemoveVendorJobPermissionsSection',
    ChangeJobReleaseSettingsCheckboxValueOnlyWhenClickOnCheckbox = 'ChangeJobReleaseSettingsCheckboxValueOnlyWhenClickOnCheckbox',
    DisplayPreferredShiftInVendorSubmittals = 'display-preferred-shift-in-vendor-submittals',
    DisplaySubmittalLevelPreferredShiftsAndAvailableDateInSubmittalsDetails = 'display-submittal-level-preferred-shifts-and-available-date-in-submittals-details',
    VendorProfileJobRulesPanel = 'VENDOR_PROFILE_JOB_RULES_PANEL',
    VendorJobRuleExport = 'VendorJobRuleExport',
    ConnectJobRulesGridAdditionalFields = 'ConnectJobRulesGridAdditionalFields',
    JobRulesVendorTrimFrontEnd = 'JOB_RULES_VENDOR_TRIM_FRONT_END',
    MoveUnionCodeToTheRightSideOfJobsGrid = 'MoveUnionCodeToTheRightSideOfJobsGrid',
    PinDefaultRuleAdminVendorJobRule = 'PIN_DEFAULT_RULE_ADMIN_VENDOR_JOB_RULE',
    DisplayContactAssociatedToCandidateProfile = 'DisplayContactAssociatedToCandidateProfile',
    VendorCandidatePhoneSaveEnable = 'VENDOR_CANDIDATE_PHONE_SAVE_ENABLE',
    VendorCandidatesRTOField = 'PN_CANDIDATES_RTO_FIELD',
    VendorSubmittalRTOField = 'VENDOR_SUBMITTAL_INCLUDE_RTO',
    SkipUnnecessaryRequestsNewVendorCandidate = 'SkipUnnecessaryRequestsNewVendorCandidate',
    SkipUnnecessaryRequestsNewAdminVendorCandidate = 'SkipUnnecessaryRequestsNewAdminVendorCandidate',
    VendorAvailableDatePreferredShift = 'VendorAvailableDatePreferredShift',
    DisplayRTOClientSubmittals = 'DisplayRTOClientSubmittals',
    UseJobStartDateAsCandidateAvailableDate = 'UseJobStartDateAsCandidateAvailableDate',
    ExistingVendorSubmittalEditRTO = 'EXISTING_SUBMITTAL_EDIT_RTO',
    UseAyaConnectToCallUnitDescriptionApis = 'UseAyaConnectToCallUnitDescriptionApis',
}
