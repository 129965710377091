import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    SystemLookup,
    FacilityLookup,
    InvoiceDisputeStatusLookup,
    InvoiceDisputeReasonLookup,
    UnitLookup
} from '../../models';
import { InvoiceCaregiverListingTypeLookup } from '../../models/lookups/invoice-caregiver-listing-types.lookup';
import {
    loadInvoicesLookups,
    loadInvoicesLookupsSuccess,
    loadInvoicesLookupsFail
} from '../invoices.actions';

export interface InvoicesLookupsState {
    facilities?: Array<FacilityLookup>;
    systems?: Array<SystemLookup>;
    disputeStatuses?: Array<InvoiceDisputeStatusLookup>;
    disputeReasons?: Array<InvoiceDisputeReasonLookup>;
    caregiverListingTypes?: Array<InvoiceCaregiverListingTypeLookup>;
    units?: Array<UnitLookup>;    
    loading: LoadingTypes;
}

const initialState: InvoicesLookupsState = {
    facilities: [],
    systems: [],
    disputeReasons: [],
    disputeStatuses: [],
    caregiverListingTypes: [],
    units: [],    
    loading: LoadingTypes.INIT
};

export function invoicesLookupsReducer(state: InvoicesLookupsState, action: Action) {
    return createReducer(
        initialState,
        on(loadInvoicesLookups, (state, action) => (
            {
                ...state,
                loading: LoadingTypes.LOADING
            }
        )),

        on(loadInvoicesLookupsSuccess, (state, action) => (
            {
                ...state,
                facilities: action.facilities,
                systems: action.systems,
                disputeStatuses: action.disputeStatuses,
                disputeReasons: action.disputeReasons,
                caregiverListingTypes: action.caregiverListingTypes,
                units: action.units,                
                loading: LoadingTypes.LOADED
            }
        )),

        on(loadInvoicesLookupsFail, (state, action) => (
            {
                ...state,
                loading: LoadingTypes.LOADED
            }
        ))

    )(state, action);
}

export function reducer(state: InvoicesLookupsState | undefined, action: Action) {
    return invoicesLookupsReducer(state, action);
}
