export interface FileAttachment {
    id: number;
    fileName: string;
    uploadDate: Date;
    userFullName: string;
    isDeleted: boolean;
    internalOnly?: boolean;
    metadata?: Record<string, number>;
}

export interface FileValidationResult {
    name: string;
    isValid?: boolean;
    message?: string;
}

export enum FileAttachmentStyle {
    TitleAndList = 1,
    ListOnly = 2
}

export class FilesToUpload extends FileList {}

export class AttachmentPreview {
    blob: Blob;
    totalPages: number;

    constructor(blob, totalPages) {
        this.blob = blob;
        this.totalPages = totalPages;
    }
}

export class AttachmentConfig {
    viewableByRadioButton: boolean;
    isDragging: boolean;
    deleteDialogTitle: string;
    deleteDialogText: string;
    validFileExtensions: string[];
    attachmentTitle: string;
    hasTemplate: boolean;
    attachmentRequired: boolean;
    defaultInternalOnly: boolean;
    attachmentNotes: string;
    hiddenNotes: boolean;
    /**
     * It will hide permission message,
     * but [hiddenNotes] will override all notes.
     */
    hiddenPermissionNote: boolean;
    isVendorExpenseDragAndDrop: boolean;

    constructor(options?: Partial<AttachmentConfig>) {
        this.deleteDialogText = 'Are you sure you want to delete the attachment?';
        this.deleteDialogTitle = 'Delete Attachment';
        this.viewableByRadioButton = false;
        this.validFileExtensions = [
            '.msg',
            '.pdf',
            '.xlsx',
            '.xls',
            '.csv',
            '.zip',
            '.png',
            '.jpg',
            '.jpeg',
            '.gif',
            '.bmp',
            '.txt',
            '.docx',
            '.doc',
            '.eml'
        ];
        this.isDragging = false;
        this.attachmentTitle = 'Item';
        this.hasTemplate = false;
        this.attachmentRequired = false;
        this.defaultInternalOnly = false;
        this.attachmentNotes = '';
        this.hiddenNotes = false;
        this.hiddenPermissionNote = false;
        this.isVendorExpenseDragAndDrop = false;

        Object.assign(this, options);
    }
}
