import { GridSearchQuery } from 'src/app/shared/grid/models/grid-search-query.model';
import { SortTypes } from 'src/app/shared/models/enums/sort-types.enum';
import { SubmittalsSortTypes } from 'src/app/shared/models/submittals/enums/submittal-sort-types.enum';
import {
    setSubmittalsSearchQuery,
    setDashboardSubmittalsQuery,
    setDashboardPendingSubmittalsQuery
} from 'src/app/submittals/store/submittals.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

const initialState: GridSearchQuery = {
	sort: [{field: SubmittalsSortTypes.SUBMITTALS_DATE, dir: SortTypes.DESC}],
	take: 10,
	skip: 0,
	filter: { logic: "and", filters: [] }
}

export function submittalsQueryReducer(state: GridSearchQuery, action: Action) {
    return createReducer(
		initialState,
		on(setSubmittalsSearchQuery, (state, action) => (
			{
				...state,
				...action.query
			}
		)),

		on(setDashboardSubmittalsQuery, (state, action) => {
			const mapFields = {
				facilityIds: 'searchFacilityIds',
				unitId: 'unitId',
				lineOfBusinessId: 'lineOfBusinessId'
			};

			const filters: any = Object.keys(mapFields)
				.filter(key => key in action && action[key] !== null)
				.map((key) => ({
					field: mapFields[key],
					operator: 'eq',
					value: action[key]
				}));

			filters.push({
				field: 'isRecentlySubmitted',
				operator: 'eq',
				value: true
            });

			return {
				...state,
				sort: [{ field: 'submissionSentOn', dir: SortTypes.DESC }],
                filter: ({
                    logic: "and",
                    filters: filters
                } as CompositeFilterDescriptor)
            }
		}),

		on(setDashboardPendingSubmittalsQuery, (state, action) => {
			const mapFields = {
				facilityIds: 'searchFacilityIds',
				unitId: 'unitId',
				statusId: 'statusId',
				lineOfBusinessId: 'lineOfBusinessId',
                submittalStatusId:'submittalStatusId'
			};

			const filters: any = Object.keys(mapFields)
				.filter(key => key in action && action[key] !== null)
				.map((key) => ({
					field: mapFields[key],
					operator: 'eq',
					value: action[key]
				}));

			return {
				...state,
				sort: [{ field: 'submissionSentOn', dir: SortTypes.ASC }],
				filter: ({
					logic: "and",
					filters: filters
				} as CompositeFilterDescriptor)
			}
		})
	)(state, action);
}

export function reducer(state: GridSearchQuery | undefined, action: Action) {
	return submittalsQueryReducer(state, action);
}
