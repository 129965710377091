import { createAction, props } from '@ngrx/store';
import { ResourceList } from 'src/app/shared/models/internal-pool/resource-list.model';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ListItem } from 'src/app/shared/models/list-item';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { SpecialtyLookup } from 'src/app/shared/models/lookups/specialty-lookup.model';
import { SystemLookup } from 'src/app/timecards/models';
import { GridSearchQuery } from 'src/app/shared/grid/models';

export const loadSystemId = createAction('[Internal Pool Resources] Load System Id');
export const loadSystemIdSuccess = createAction('[Internal Pool Resources] Load System Id Success', props<{ systemId: number}>());
export const loadSystemIdFail = createAction('[Interal Pool Resources] Load System Id Fail', props<{ error: any }>());

export const loadResources = createAction('[Internal Pool Resources] Load Resources', props<{ systemId: number}>());
export const loadResourcesCurrentSystem = createAction('[Internal Pool Resources] Load Resources Current System');
export const loadResourcesSuccess = createAction('[Internal Pool Resources] Load Resources Success', props<{ resources: PagingToken<ResourceList[]> }>());
export const loadResourcesFail = createAction('[Interal Pool Resources] Load Resources Fail', props<{ error: any }>());
export const setResourcesQuery = createAction('[Internal Pool Resources] Set Resources Search Query', props<{ query: GridSearchQuery }>());
export const setResourcesQueryAndLoadResources = createAction('[Internal Pool Resources] Set Resources Search Query And Load Resources',
                                    props<{ query: GridSearchQuery, systemId: number }>());
export const loadResourcesLookups = createAction('[Internal Pool Resources] Load Resources Lookups');
export const loadResourcesLookupsSuccess = createAction(
    '[Internal Pool Resources] Load Resources Lookups Success',
    props<
    {
        professions: ListItem[],
        specialties: SpecialtyLookup[],
        facilities: FacilityLookup[],
        systems: SystemLookup[],
        availabilityStatuses: ListItem[],
        epicSkills: ListItem[],
        languageSkillLevels: ListItem[],
        languages: ListItem[],
    }>());
export const loadResourcesLookupsFail = createAction('[Internal Pool Resources] Load Resources Lookups Fail', props<{ error: any }>());
export const loadResourcesLookupsFromServer = createAction('[Internal Pool Resources] Load Resources Lookups From Server', props<{ systemId: number}>());
export const loadResourcesLookupsLocal = createAction('[Internal Pool Resources] Load Shifts Resources From Local Store');
export const loadSystemIdWithLookupsAndResources = createAction('[Internal Pool Resources] Load System Id With Lookups and Resource');

