import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, catchError, tap, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubmittalsService } from 'src/app/submittals/services/submittals.service';
import { SubmittalsBaseEffect } from './submittals-base.effect';
import { ToasterService } from 'src/app/core/services/toaster.service';
import * as submittalActions from '../submittals.actions';

@Injectable()
export class SubmittalOfferDetailsEffect extends SubmittalsBaseEffect {
    getSubmittalOfferDetails$ = createEffect(() => {
        return this._actions.pipe(
            ofType(submittalActions.loadOfferDetails, submittalActions.updateOfferDetailsSuccess),
            map((action) => action.clientSubmittalId),
            exhaustMap((id: number) =>
                this._submittalsService.getSubmittalOfferDetails(id).pipe(
                    map((offerDetails) => submittalActions.loadOfferDetailsSuccess({ offerDetails })),
                    catchError((error: unknown) => of(submittalActions.loadOfferDetailsFailure({ error })))
                )
            )
        );
    });

    getSubmittalOfferDetailsSuccess$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(submittalActions.loadOfferDetailsSuccess),
                tap(() => this.logData('Submittal offer details loaded.'))
            );
        },
        { dispatch: false }
    );

    getSubmittalOfferDetailsFailure$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(submittalActions.loadOfferDetailsFailure),
                tap(() => this.toasterService.fail('Failed to retrieve offer details.'))
            );
        },
        { dispatch: false }
    );

    updateSubmittalOfferDetails$ = createEffect(() => {
        return this._actions.pipe(
            ofType(submittalActions.updateOfferDetails),
            exhaustMap((action) =>
                this._submittalsService.updateSubmittalOfferDetails(action.clientSubmittalId, action.request).pipe(
                    tap(() => this.toasterService.success('Submittal offer details successfully updated.')),
                    map(() =>
                        submittalActions.updateOfferDetailsSuccess({ clientSubmittalId: action.clientSubmittalId })
                    ),
                    catchError((error: unknown) => of(submittalActions.updateOfferDetailsFailure({ error })))
                )
            )
        );
    });

    updateSubmittalOfferDetailsFailure$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(submittalActions.updateOfferDetailsFailure),
                tap(() => this.toasterService.fail('Failed to update offer details.'))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions: Actions,
        private readonly _submittalsService: SubmittalsService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
