import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';

import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { map, takeUntil, filter, startWith, pairwise } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as submittalsSelectors from 'src/app/submittals/store/submittals.selectors';
import * as submittalsActions from 'src/app/submittals/store/submittals.actions';
import { loadSubmittalActivities } from 'src/app/submittals/store/actions/submittals-leads.actions';

import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';
import { CommonService } from 'src/app/shared/services/common.service';
import { DomainService } from 'src/app/shared/services/domain.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DeclineSubmittalDialogComponent } from 'src/app/submittals/components/submittal-details/decline-submittal-dialog/decline-submittal-dialog.component';
import { OfferCandidateModalComponent } from 'src/app/submittals/components/offer-candidate-modal/offer-candidate-modal.component';
import { IConfirmationDialogOptions, IDialogOptions } from 'src/app/shared/models/dialog.models';
import { PortalHeaderBadge } from 'src/app/shared/models/page-header-badge.model';

import {
    MspCustomFieldsList,
    SubmittalExpanded,
    SubmittalJob,
    SubmittalOfferDetails,
    SubmittalOfferDetailsUpdateRequest,
    SubmittalStatusLookup,
    SubmittalStatusMappedLookup,
    SubmittalStatusType,
    SubmittalStatuses,
    SubmittalSubStatus,
    SubmittalUpdateStatusRequest
} from 'src/app/shared/models/submittals';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { SubmittalAttachmentMetaData } from 'src/app/shared/models/submittals/responses/submittal-attachment-meta-data.model';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { UpdateStatusDialogComponent } from './update-status-dialog/update-status-dialog.component';
import { SubmittalVendorStatuses } from 'src/app/shared/models/submittals-vendor/enums/submittal-vendor-status-type.enum';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SubmittalDetailsRoutesHelperService } from 'src/app/submittals/components/submittal-details/submittal-details-routes-helper-service';
import { CandidateOfferStatus } from 'src/app/shared/models/offers/enums/candidate-offer-status.enum';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { LoadingTypes } from 'src/app/shared/models';

const clientSubmittalUrl = 'client/submittals';

const submittalDetailsTabs = [
    SubmittalDetailsRoutesHelperService.submittalDetails,
    SubmittalDetailsRoutesHelperService.offerDetails
];

@Component({
    selector: 'ayac-submittal-details',
    templateUrl: './submittal-details.component.html',
    styleUrls: ['./submittal-details.component.scss']
})
export class SubmittalDetailsComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    jobsDisplayedColumns: string[] = ['id', 'facility', 'profession', 'expertise', 'startDate', 'shiftText', 'note'];
    applicationDocumentsColumns: string[] = ['fileName'];
    id: number;

    submittalStatus: SubmittalUpdateStatusRequest;
    submittalStatusDisplay: string;
    isSubmittalDetailsLoading$: Observable<boolean>;
    isSubmittalDetailsUpdating$: Observable<LoadingTypes>;
    jobs$: Observable<SubmittalJob[]>;
    isApplicationLoading$: Observable<boolean>;
    statusLookupsMapped$: Observable<SubmittalStatusMappedLookup[]>;
    submittal$: Observable<SubmittalExpanded>;
    offerMailto$: Observable<string>;
    mspCustomFields$: Observable<MspCustomFieldsList>;
    offer$: Observable<SubmittalOfferDetails>;
    standaloneVmsFlag$: Observable<boolean>;

    canUpdateStatus$: Observable<boolean>;

    environment: string;
    statusDropDown: UntypedFormControl = new UntypedFormControl('');
    prevStatusValue: number;

    statusBadge$: Observable<PortalHeaderBadge>;
    showOfferButton$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    showDeclineButton$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    canOffer$: Observable<boolean>;
    canDecline$: Observable<boolean>;
    isPendingOverdue$: Observable<boolean>;
    featureFlag = FeatureFlag;
    attachmentsMetaData$: Observable<SubmittalAttachmentMetaData[]>;
    displayNewSubmittalStatusEnabled: boolean;
    isDisplayOfferTabEnabled: boolean;
    candidateNotSelected: SubmittalStatusLookup;
    submittalStatuses = SubmittalStatuses;

    selectedTabIndex: number = 0;

    offerHasChangeRequested = false;
    offerInEditMode = false;
    collectOfferDetailsUpdateRequest = false;
    isSendOfferDetailsBtnEnabled = false;

    loadingTypes = LoadingTypes;

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _store: Store,
        private readonly _commonService: CommonService,
        private readonly _domainService: DomainService,
        private readonly _dialogService: DialogService,
        private readonly _dialog: DialogService,
        private readonly _featureManager: LDFeatureManager,
        @Inject('Window') private readonly _window: Window
    ) {
        super();
    }

    get showSubmittalButtons() {
        return (
            !this.isDisplayOfferTabEnabled ||
            this.selectedTabIndex === submittalDetailsTabs.indexOf(SubmittalDetailsRoutesHelperService.submittalDetails)
        );
    }

    ngOnInit() {
        this.id = Number.parseInt(this._route.snapshot.paramMap.get('id'), 10);

        this.environment = this._domainService.environment();
        this._store.dispatch(submittalsActions.loadSubmittalsLookupsStatuses());
        this._store.dispatch(submittalsActions.loadSubmittalDetails({ id: this.id }));
        this._store.dispatch(submittalsActions.loadSubmittalJobs({ id: this.id }));

        this.submittal$ = this._store.select(submittalsSelectors.getSubmittalDetails);
        this.offer$ = this._store.select(submittalsSelectors.selectSubmittalOfferDetails);
        this.standaloneVmsFlag$ = this._featureManager.isEnabled(this.featureFlag.ConnectStandaloneVMSClientOfferFlow);

        this.canUpdateStatus$ = this._store.select(submittalsSelectors.getSubmittalDetailsCanUpdateStatus);
        this.jobs$ = this._store.select(submittalsSelectors.getSubmittalJobs);
        this.mspCustomFields$ = this._store.select(submittalsSelectors.getSubmittalMspCustomFields);
        this.statusLookupsMapped$ = this._store.select(submittalsSelectors.selectSubmittalDetailsDropdownStatuses);
        this.statusBadge$ = this._store.select(submittalsSelectors.selectStatusBadge);
        this.isPendingOverdue$ = this._store.select(submittalsSelectors.selectIsPendingOverdue);
        this.isSubmittalDetailsLoading$ = this._store.select(submittalsSelectors.getIsSubmittalDetailsLoading);
        this.isSubmittalDetailsUpdating$ = this._store.select(submittalsSelectors.selectSubmittalOfferDetailsUpdating);
        this.attachmentsMetaData$ = this._store.select(submittalsSelectors.selectSubmittalAttachmentsMetaData);
        this.isApplicationLoading$ = this._store
            .select(submittalsSelectors.getSubmittalApplicationsLoadingIds)
            .pipe(map((loadingIds) => loadingIds.some((x) => x === this.id)));

        this.canOffer$ = combineLatest([
            this._store.select(submittalsSelectors.getSubmittalDetailsCanUpdateStatus),
            this.showOfferButton$
        ]).pipe(map(([canUpdateStatus, showOfferButton]) => canUpdateStatus && showOfferButton));

        this.canDecline$ = combineLatest([
            this._store.select(submittalsSelectors.getSubmittalDetailsCanUpdateStatus),
            this.showDeclineButton$
        ]).pipe(map(([canUpdateStatus, showDeclineButton]) => canUpdateStatus && showDeclineButton));

        this.submittal$
            .pipe(
                filter((submittal) => !!submittal),
                takeUntil(this.d$)
            )
            .subscribe((submittal: SubmittalExpanded) => {
                this.setUpdatedSubmittal(submittal);
            });

        this._store
            .select(submittalsSelectors.getSubmittalLookupsStatuses)
            .pipe(takeUntil(this.d$))
            .subscribe((statuses) => {
                if (statuses && statuses.length > 0) {
                    this.candidateNotSelected = statuses.find((s) => s.id === SubmittalStatuses.CandidateNotSelected);
                }
            });

        this.statusDropDown.valueChanges
            .pipe(startWith(this.statusDropDown.value), pairwise(), takeUntil(this.d$))
            .subscribe(([prev, next]) => {
                if (prev && prev !== next) {
                    this.prevStatusValue = prev;
                }
            });

        this._store.dispatch(submittalsActions.loadSubmittalAttachmentsMetaData({ clientSubmittalId: this.id }));

        this._featureManager
            .isEnabled(this.featureFlag.ConnectClientDisplayNewSubmittalStatus)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.displayNewSubmittalStatusEnabled = flagIsEnabled;
            });

        combineLatest([this.standaloneVmsFlag$, this.offer$])
            .pipe(takeUntil(this.d$))
            .subscribe(([flagIsEnabled, offer]) => {
                this.isDisplayOfferTabEnabled = flagIsEnabled;

                if (this.isDisplayOfferTabEnabled) {
                    if (!offer) {
                        this._store.dispatch(submittalsActions.loadOfferDetails({ clientSubmittalId: this.id }));
                    } else {
                        this.offerHasChangeRequested = offer.offerStatusId === CandidateOfferStatus.ChangesRequested;

                        // If URL ends with /offer, switch to offer tab once offer is loaded if it exists.
                        const currentUrl = this._window.location.href;

                        const offerTab = SubmittalDetailsRoutesHelperService.offerDetails;

                        if (currentUrl.endsWith(offerTab)) {
                            this.selectedTabIndex = submittalDetailsTabs.indexOf(offerTab);
                        }
                    }
                }
            });

        // trick to hide Facility dropdown from old modules
        this._commonService.hidePageLayoutElement('FACILITY_DROPDOWN', 'client-submittal-details-page');

        this._featureManager
            .isEnabled(this.featureFlag.ConnectClientSubmittalEnableActivity)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                if (flagIsEnabled) {
                    this.submittal$.pipe(takeUntil(this.d$)).subscribe((x: SubmittalExpanded) => {
                        if (x?.id) {
                            this._dispatchSubmittalActivities(x.id);
                        }
                    });
                }
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this._store.dispatch(submittalsActions.clearSubmittalDetails());
    }

    onSelectedTabChange(event: MatTabChangeEvent) {
        this.selectedTabIndex = event.index;
        const selectedTabSuffix = submittalDetailsTabs[this.selectedTabIndex];
        const url = `${clientSubmittalUrl}/${this.id}/${selectedTabSuffix}`;
        this._router.navigate([url]);
    }

    setUpdatedSubmittal(submittal: SubmittalExpanded): void {
        const isStatusUpdate = !!this.submittalStatus;

        if (this.displayNewSubmittalStatusEnabled) {
            if (isStatusUpdate) {
                this._setCandidateStatus(
                    submittal.submittalStatusId,
                    submittal.submittalSubStatusId,
                    this.submittalStatus.note,
                    this.submittalStatus.declineReasonId
                );
            } else {
                this._setCandidateStatus(submittal.submittalStatusId, submittal.submittalSubStatusId);
            }
            this.updateButtonsVisibilityState(submittal.submittalStatusId);
            if (this.displayNewSubmittalStatusEnabled) {
                this.updateStatusDropdownDisplayName(
                    submittal.submittalStatusId,
                    submittal.submittalStatusName,
                    submittal.submittalSubStatusName
                );
            }
            this._store.dispatch(submittalsActions.mapStatus({ id: submittal.submittalStatusId }));
        } else {
            if (isStatusUpdate) {
                this._setCandidateStatus(
                    submittal.statusId,
                    null,
                    this.submittalStatus.note,
                    this.submittalStatus.declineReasonId
                );
            } else {
                this._setCandidateStatus(submittal.statusId);
            }
            this.updateButtonsVisibilityState(submittal.statusId);
            this._store.dispatch(submittalsActions.mapStatus({ id: submittal.statusId }));
        }
    }

    updateButtonsVisibilityState(statusId: number) {
        if (this.displayNewSubmittalStatusEnabled) {
            this.showDeclineButton$.next(statusId !== SubmittalStatuses.OfferDeclined);
            this.showOfferButton$.next(
                statusId !== SubmittalStatuses.Offer && statusId !== SubmittalStatuses.OfferDeclined
            );
        } else {
            this.showDeclineButton$.next(statusId !== SubmittalStatusType.DECLINED);
            this.showOfferButton$.next(
                statusId !== SubmittalStatusType.OFFER && statusId !== SubmittalStatusType.DECLINED
            );
        }
    }

    updateStatusDropdownDisplayName(submittalStatusId: number, statusName: string, subStatusName: string) {
        if (submittalStatusId === SubmittalVendorStatuses.Offer) {
            this.submittalStatusDisplay = 'Offered';
        } else if (subStatusName !== null) {
            this.submittalStatusDisplay = `${statusName} ${subStatusName}`;
        } else {
            this.submittalStatusDisplay = statusName;
        }
    }

    updateCandidateStatus(statusId: number, subStatusId?: number, note?: string, declineReasonId?: number) {
        this._setCandidateStatus(statusId, subStatusId, note, declineReasonId);
        this._dispatchCandidateStatus();
    }

    offerCandidate(candidate: SubmittalExpanded) {
        const data = { submittal: candidate };
        this._dialog
            .openDialog(OfferCandidateModalComponent, { width: '560px', data })
            .afterClosed()
            .subscribe((result) => {
                // This call should not be necessary as the status should be updated
                // as part of the OfferCandidateModalComponent call to CoreApi
                // A jira story will be created to address this and refactor
                if (result) {
                    if (this.displayNewSubmittalStatusEnabled) {
                        this.updateCandidateStatus(SubmittalStatuses.Offer);
                    } else {
                        this.updateCandidateStatus(SubmittalStatusType.OFFER);
                    }
                }
            });
    }

    declineCandidate(data: { declineReasonId: number; notes: string; status?: SubmittalStatusLookup }) {
        data.status = this.candidateNotSelected;
        this._dialogService
            .openDialog(DeclineSubmittalDialogComponent, { width: '560px', data } as IDialogOptions<any>)
            .afterClosed()
            .subscribe((result: { declineReasonId: number; notes: string }) => {
                if (result) {
                    if (this.displayNewSubmittalStatusEnabled) {
                        this.updateCandidateStatus(
                            SubmittalStatuses.CandidateNotSelected,
                            result.declineReasonId, //subStatusId
                            result.notes
                        );
                    } else {
                        this.updateCandidateStatus(
                            SubmittalStatusType.DECLINED,
                            null,
                            result.notes,
                            result.declineReasonId
                        );
                    }
                }
            });
    }

    updateStatus(status: SubmittalStatusLookup, subStatus?: SubmittalSubStatus): void {
        const data = {
            submittalStatusName: status.name,
            submittalSubStatusName: subStatus?.name,
            submittalStatusId: status.id
        };
        this._dialog
            .openDialog(UpdateStatusDialogComponent, { width: '560px', data })
            .afterClosed()
            .subscribe((result: { notes: string }) => {
                if (result) {
                    this.updateCandidateStatus(status.id, subStatus?.id, result.notes);
                }
            });
    }

    openAttachment(attachmentId: number | null): void {
        const submittalId = this.id;
        const url =
            attachmentId !== null
                ? `/#/client/submittals/${submittalId}/attachments?attachmentId=${attachmentId}`
                : `/#/client/submittals/${submittalId}/attachments`;

        this._window.open(`${url}`, '_blank');
    }

    editOffer(): void {
        this.offerInEditMode = true;
        this.collectOfferDetailsUpdateRequest = false;
    }

    cancelOfferEdit(): void {
        this.offerInEditMode = false;
    }

    confirmOfferUpdate(): void {
        const dialogText = 'You are about to send an offer update to the vendor. Do you wish to continue?';
        const titleText = 'Send Offer Update';

        const dialog: IConfirmationDialogOptions = {
            width: '350px',
            data: {
                title: titleText,
                text: `<p>${dialogText}</p>`,
                confirmButtonText: 'Send Offer Update',
                isHtml: true
            }
        };

        const dialogRef = this._dialogService.openDialog(ConfirmationDialogComponent, dialog);
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.collectOfferDetailsUpdateRequest = true;
            }
        });
    }

    saveOfferUpdate(request: SubmittalOfferDetailsUpdateRequest) {
        this._store.dispatch(submittalsActions.updateOfferDetails({ clientSubmittalId: this.id, request }));
        this.offerInEditMode = false;
    }

    canUpdateOffer(status: boolean) {
        this.isSendOfferDetailsBtnEnabled = status;
    }

    rescindOffer(): void {
        const dialogText = 'Are you sure you wish to rescind this offer?';
        const titleText = 'Rescind Offer';

        const dialog: IConfirmationDialogOptions = {
            width: '350px',
            data: {
                title: titleText,
                text: `<p>${dialogText}</p>`,
                confirmButtonText: 'Rescind',
                isHtml: true
            }
        };

        const dialogRef = this._dialogService.openDialog(ConfirmationDialogComponent, dialog);
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                console.log('Do nothing until Rescind ticket is done.');
            }
        });
    }

    private _setCandidateStatus(
        statusId: number,
        subStatusId: number | null = null,
        note: string | null = null,
        declineReasonId: number | null = null
    ): void {
        if (!this.submittalStatus) {
            this.submittalStatus = {} as SubmittalUpdateStatusRequest;
        }

        if (this.displayNewSubmittalStatusEnabled) {
            this.submittalStatus.statusId = null;
            this.submittalStatus.submittalStatusId = statusId;
            this.submittalStatus.submittalSubStatusId = subStatusId;
        } else {
            this.submittalStatus.statusId = statusId;
            this.submittalStatus.submittalStatusId = null;
            this.submittalStatus.submittalSubStatusId = null;
        }

        this.submittalStatus.submittalId = this.id;
        this.submittalStatus.note = note;
        this.submittalStatus.declineReasonId = declineReasonId;
    }

    private _dispatchCandidateStatus(submittalStatus: SubmittalUpdateStatusRequest = this.submittalStatus): void {
        this._store.dispatch(submittalsActions.updateSubmittalStatus({ payload: { ...submittalStatus } }));
    }

    private _dispatchSubmittalActivities(submittalId: number) {
        this._store.dispatch(loadSubmittalActivities({ submittalId }));
    }
}
