import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
    StageHistoryItem,
    SubmittalOffer,
    VendorSubmittalDetails
} from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';
import { VendorSubmittalStatus } from 'src/app/shared/models/submittals-vendor/submittal-vendor-statuses.enum';
import { CandidateInfo } from 'src/app/shared/models/offers/models/candidate-offer';
import { VendorSubmittalStage } from 'src/app/shared/components/submittal-details/enums/vendor-submittal-stage.enum';
import { FeatureFlag } from '../../models/enums/feature-flag.enum';
import { ListItem } from '../../models/list-item';
import { Observable } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';
import { DialogService } from '../../services/dialog.service';
import { SubmittalRtoDialogComponent } from 'src/app/submittals-vendor/submittal-rto-dialog/submittal-rto-dialog.component';
import { ShiftTypeEnum } from 'src/app/shifts/models/shift-type.enum';

@Component({
    selector: 'ayac-shared-submittal-details',
    templateUrl: './submittal-details.component.html',
    styleUrls: ['./submittal-details.component.scss']
})
export class SharedSubmittalDetailsComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() hasOfferDetailsPerms: boolean;
    @Input() newSubmittal = false;
    @Input() noteLabel: string;
    @Input() isAdmin = false;
    @Input() submittalStages$: Observable<ListItem[]>;
    @Input()
    set submittalDetails(data: VendorSubmittalDetails) {
        if (data) {
            this.details = data;
            this.submittalId = data.id;
            this.offer = data.offer;
            this.form.patchValue({
                note: data.note
            });
            this.submittalStageHistoryEntries = data.stageHistoryEntries;
            this.isOfferInContractChangeStatus = this.hasOfferWithAnyOfStatus([VendorSubmittalStatus.ContractChange]);

            //ACX-6357 - if the vendor has electronic offer tag (EOT) enabled we display the View Offer button
            //this way we are backwards compatible with the vendors with EOT disabled
            if (!this.hideAcceptDeclineButtonFlag) {
                this.showAcceptSubmittal =
                    data.currentStage.postStages.some(
                        (ps) => ps.submittalStageId === VendorSubmittalStage.OfferAccepted && ps.availableToVendor
                    ) && !this.hasActiveOffer();
                this.showDeclineSubmittal =
                    data.currentStage.postStages.some(
                        (ps) => ps.submittalStageId === VendorSubmittalStage.OfferDeclined && ps.availableToVendor
                    ) && !this.hasActiveOffer();
            }

            //ACX-6407 - "Change Requested" should not display in the submittal history permanently
            const canShowRequestedChangesHistoryItem = this.hasOfferWithAnyOfStatus([
                VendorSubmittalStatus.ChangesRequested
            ]);
            if (canShowRequestedChangesHistoryItem) {
                const offer = data.offer;
                const requestedChangesHistoryItems = [
                    {
                        dateEntered: offer ? offer.updatedDate : null,
                        submittalStage: {
                            name: 'Change Requested'
                        }
                    }
                ];
                this.submittalStageHistoryEntries = data.stageHistoryEntries
                    ? data.stageHistoryEntries.concat(requestedChangesHistoryItems)
                    : data.stageHistoryEntries;
            }
        }
    }

    get submittalDetails(): VendorSubmittalDetails {
        return this.details;
    }

    @Input()
    set submittalLookups(data: any) {
        if (data) {
            this.lookups = data;
        }
    }

    get submittalLookups(): any {
        return this.lookups;
    }

    get candidateName(): string {
        if (this.candidateInfo?.firstName && this.candidateInfo?.lastName) {
            return `${this.candidateInfo.firstName} ${this.candidateInfo.lastName}`;
        }
        return this.details?.candidateFullName ?? '';
    }

    @Input() candidateInfo: CandidateInfo;
    @Output() hasNewChanges = new EventEmitter();
    @Output() goViewOffer = new EventEmitter();
    @Output() openAcceptOfferDialog = new EventEmitter();
    @Output() openRejectOfferDialog = new EventEmitter();
    @Output() goViewJobDetails = new EventEmitter();
    @Output() goViewCandidateDetails = new EventEmitter();
    @Output() goViewVendorDetails = new EventEmitter();
    @Output() updateStage = new EventEmitter();

    details: VendorSubmittalDetails;
    lookups: any[];
    submittalId: number;
    form = this._formBuilder.group({
        note: ['', [Validators.required]],
        stageSelect: ['']
    });
    offer: SubmittalOffer;
    noteText = '';
    isOfferInContractChangeStatus: boolean;
    showAcceptSubmittal = false;
    showDeclineSubmittal = false;
    submittalStageHistoryEntries: StageHistoryItem[];
    hideAcceptDeclineButtonFlag = false;
    availableDatePreferredShiftFlag = false;
    useJobStartDateAsCandidateAvailableDateFlag = false;
    existingSubmittalEditRTOFlag = false;
    readonly featureFlag = FeatureFlag;
    shiftType = ShiftTypeEnum;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _ldFeatureManager: LDFeatureManager,
        private readonly _dialogService: DialogService
    ) {
        super();
    }

    ngOnInit() {
        this.form
            .get('note')
            .valueChanges.pipe(debounceTime(500), takeUntil(this.d$))
            .subscribe((note) => {
                const hasChanges = note !== this.details.note ? true : false;
                const subValues = {
                    hasChanges,
                    note
                };
                this.hasNewChanges.emit(subValues);
            });

        this.form
            .get('stageSelect')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((stage) => {
                this.updateStage.emit(stage);
            });

        this._ldFeatureManager
            .isEnabled(FeatureFlag.ConnectVendorSubmittalDetailsHideAcceptDecline)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.hideAcceptDeclineButtonFlag = isEnabled;
            });

        this._ldFeatureManager
            .isEnabled(FeatureFlag.VendorAvailableDatePreferredShift)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.availableDatePreferredShiftFlag = isEnabled;
            });

        this._ldFeatureManager
            .isEnabled(FeatureFlag.UseJobStartDateAsCandidateAvailableDate)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.useJobStartDateAsCandidateAvailableDateFlag = isEnabled;
            });

        this._ldFeatureManager
            .isEnabled(FeatureFlag.ExistingVendorSubmittalEditRTO)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.existingSubmittalEditRTOFlag = isEnabled;
            })
    }

    viewOffer(offerId: number) {
        this.goViewOffer.emit(offerId);
    }

    canShowViewOfferButton() {
        const vendorStatuses = [
            VendorSubmittalStatus.VendorReview,
            VendorSubmittalStatus.ChangesRequested,
            VendorSubmittalStatus.DeclinedOffer,
            VendorSubmittalStatus.Accepted,
            VendorSubmittalStatus.ContractRequested,
            VendorSubmittalStatus.ContractChange
        ];
        return this.hasOfferDetailsPerms && this.hasOfferWithAnyOfStatus(vendorStatuses);
    }

    acceptOffer() {
        this.openAcceptOfferDialog.emit();
    }

    rejectOffer() {
        this.openRejectOfferDialog.emit();
    }

    goToJobDetails(jobId: number): void {
        this.goViewJobDetails.emit(jobId);
    }

    goToCandidateDetails(candidateId: number): void {
        this.goViewCandidateDetails.emit(candidateId);
    }

    goToVendorDetails(vendorId: number): void {
        this.goViewVendorDetails.emit(vendorId);
    }

    clearStageSelection(): void {
        this.form.get('stageSelect').setValue(null);
    }

    editRto() {
        this._dialogService
            .openDialog(SubmittalRtoDialogComponent, {
                data: this.buildModalData(),
                width: '33%'
            })
            .afterClosed()
            .subscribe((value: any) => {
                if (value) {
                    if (!this.candidateInfo) {
                        this.candidateInfo = {} as CandidateInfo;
                    }
                    this.candidateInfo.requestTimeOffDates = value.requestTimeOffDates.length
                        ? value.requestTimeOffDates
                        : [];
                    if (this.availableDatePreferredShiftFlag) {
                        this.candidateInfo.availableStartDate = value.availableStartDate
                            ? value.availableStartDate
                            : null;
                        this.candidateInfo.preferredShiftTypes = value.preferredShifts ? value.preferredShifts : null;
                        this.hasNewChanges.emit({
                            requestTimeOffDates: value.requestTimeOffDates,
                            availableStartDate: value.availableStartDate[0],
                            preferredShiftTypeIds: value.preferredShifts
                        });
                    } else {
                        this.hasNewChanges.emit({ requestTimeOffDates: value.requestTimeOffDates });
                    }
                }
            });
    }

    private buildModalData() {
        const availableStartDate = this.details?.availableStartDate
            ? [this.details.availableStartDate]
            : (!this.candidateInfo?.availableStartDate && this.useJobStartDateAsCandidateAvailableDateFlag
                ? [this.details?.jobStartDate]
                : [this.candidateInfo?.availableStartDate]);
        const rtoNeeded = !!(this.candidateInfo?.requestTimeOffDates.length || this.details.requestTimeOffDates.length);
        const requestTimeOffDates = (this.candidateInfo?.requestTimeOffDates || this.details.requestTimeOffDates) ?? [];
        const preferredShiftTypeIds = (this.candidateInfo?.preferredShiftTypes || this.details.preferredShifts) ?? [];

        return {
            submittalId: this.submittalId,
            newSubmittal: this.newSubmittal,
            title: this.availableDatePreferredShiftFlag ? 'Edit Submittal Information' : 'Edit Submittal RTO',
            rtoNeeded,
            requestTimeOffDates,
            availableStartDate,
            preferredShiftTypeIds
        };
    }

    private showEditLink(): boolean {
        return this.existingSubmittalEditRTOFlag || !!(this.newSubmittal || this.candidateInfo?.requestTimeOffDates); //this function can be removed along with its associated *ngIf when the FF existingSubmittalEditRTOFlag is removed
    }

    private hasOfferWithAnyOfStatus(statuses: number[]) {
        const offerStatusId: number = this.offer ? this.offer.statusId : null;
        return statuses.includes(offerStatusId);
    }

    private hasActiveOffer() {
        return this.offer != null;
    }

    protected readonly featureFlagEnum = FeatureFlag;
}
