import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as internalPoolActions  from 'src/app/internal-pool/store/actions';
import { ToasterService } from 'src/app/core/services';
import { InternalPoolService } from '../../internal-pool.service';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { Store } from '@ngrx/store';
import * as fromResources from '../internal-pool.selectors';
import { LoadingTypes } from 'src/app/shared/models';

@Injectable()
export class ResourcesLookupsEffect extends BaseEffect {
    constructor(
        private actions: Actions,
        private internalPoolService: InternalPoolService,
        private store: Store<{}>,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    loadResourcesLookups$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.loadResourcesLookups),
        withLatestFrom(this.store.select(fromResources.selectLookupsIsLoaded),
                       this.store.select(fromResources.selectSystemId)),
        switchMap(([action, isLoaded, systemId]) => {
            return of(isLoaded === LoadingTypes.LOADED
                ? internalPoolActions.loadResourcesLookupsLocal()
                : internalPoolActions.loadResourcesLookupsFromServer({systemId}));
        })
    ));

    loadResourcesLookupsFromServer$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.loadResourcesLookupsFromServer),
        switchMap((actions) => this.internalPoolService.getLookups(actions.systemId).pipe(
            map(response => 
                {
                    const languages = response.languages
                        ?.slice()
                        .sort((a,b) => a.name.localeCompare(b.name));
                        
                    const facilities = response.securityLookups?.facilities
                        ?.slice()
                        .sort((a, b) => a.name.localeCompare(b.name));

                    return internalPoolActions.loadResourcesLookupsSuccess(
                        {
                            facilities: facilities || [],
                            professions: response.professions || [],
                            specialties: response.specialties || [],
                            systems: response.securityLookups != null && response.securityLookups.systems != null
                                ? response.securityLookups.systems
                                : [],
                            availabilityStatuses: response.availabilityStatuses || [],
                            epicSkills: response.epicSkills || [],
                            languageSkillLevels: response.languageSkillLevels || [],
                            languages: languages || [],
                        })
                }),
            catchError((error) => of(internalPoolActions.loadResourcesLookupsFail({error})))
        ))
    ));
}
