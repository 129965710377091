import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    Validators,
    AsyncValidatorFn,
    ValidationErrors
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { State } from 'src/app/shared/grid/models/state.model';
import { StateLicense } from 'src/app/shared/models/candidate';
import { expirationDateValidator } from 'src/app/shared/utilities';
import { FeatureFlag } from '../../models/enums/feature-flag.enum';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';
import { SubmittalsVendorService } from 'src/app/submittals-vendor/submittals-vendor.service';
import { candidateClearNPIValidator } from '../../utilities/candidate-clear-npi.validator';

@Component({
    selector: 'ayac-licenses-form',
    templateUrl: './licenses-form.component.html',
    styleUrls: ['./licenses-form.component.scss']
})
export class LicensesFormComponent extends UnsubscribeOnDestroy implements OnInit {
    formArray: UntypedFormArray;
    private _enableNpiRequiredValidation = false;
    @Input() formGroupName = 'licenses';
    @Input() candidateId?: number;
    @Input() stateLabel = 'State';
    @Input() licensesLabel = 'Licenses';
    @Input() npiLabel = 'NPI';
    @Input() isAdminForm = false;
    @Input() jobId: number;
    @Input() vendorId: number;
    @Input()
    set enableNpiRequiredValidation(value: boolean) {
        this._enableNpiRequiredValidation = value;
        if (value) {
            this.setNpiValidation();
        }
    }

    get enableNpiRequiredValidation(): boolean {
        return this._enableNpiRequiredValidation;
    }

    states: State[] = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    filteredStates$: Observable<State[]>;
    @Output() onChangeMade = new EventEmitter();
    @Output() npiValidityChanged = new EventEmitter<boolean>();
    @Output() showNpiNotClearedBanner = new EventEmitter<boolean>();
    @Output() userChangedNpiChange = new EventEmitter<boolean>();
    dataPopulated = false;

    public stateAutocompleteFormControl = new UntypedFormControl();
    public npiFormControl = new UntypedFormControl('');
    @ViewChild('stateInput')
    stateInput: ElementRef<HTMLInputElement>;

    @ViewChild('npiInput')
    npiInput: ElementRef<HTMLInputElement>;

    npiValue: string;
    featureFlag = FeatureFlag;
    npiDefaultValidators = [Validators.pattern(/^[0-9]{10}$/)];
    userChangedNpi = false;

    get selectedStateIds(): number[] {
        const result: number[] = [];
        this.formArray.controls.forEach((stateForm: UntypedFormGroup) => {
            result.push(stateForm.get('stateId').value);
        });
        return result;
    }

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly formGroupDirective: FormGroupDirective,
        private readonly lookupService: LookupsService,
        private readonly _ldFeatureManager: LDFeatureManager,
        private readonly submittalsVendorService: SubmittalsVendorService
    ) {
        super();
        this.formArray = this.formBuilder.array([], Validators.required);
    }

    ngOnInit(): void {
        this.filteredStates$ = this.stateAutocompleteFormControl.valueChanges.pipe(
            startWith(null),
            map((filter: string | null) => {
                const states = this.states.filter((x) => this.selectedStateIds.indexOf(x.id) < 0);

                return filter
                    ? states.filter((x) => x.name.toLowerCase().includes(filter.toString().toLowerCase()))
                    : states.slice();
            })
        );

        const statesLookup$ = this.isAdminForm
            ? this.lookupService.getStates(null, true)
            : this.lookupService.getStates();
        statesLookup$.pipe(takeUntil(this.d$)).subscribe((states) => {
            this.states = states?.filter((x) => x.code);
            this.stateAutocompleteFormControl.setValue('');
        });

        this.formGroupDirective.form.addControl(this.formGroupName, this.formArray);
        this.formArray.setParent(this.formGroupDirective.form);

        this.formArray.valueChanges.pipe(takeUntil(this.d$)).subscribe((valueChange) => {
            if (!this.dataPopulated) {
                return;
            }
            this.onChangeMade.emit();
        });

        this.npiFormControl.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.d$))
            .subscribe((value) => {
                if (this.userChangedNpi) {
                    this.npiValue = value;
                    if (this.npiValue && this.npiValue.length) {
                        this.setNpiValidation();
                    } else if (!this._enableNpiRequiredValidation) {
                        this.npiFormControl.clearValidators();
                        this.npiFormControl.clearAsyncValidators();
                        this.npiFormControl.updateValueAndValidity();
                    }
                    this.npiValidityChanged.emit(this.npiFormControl.valid);
                    this.onChangeMade.emit();
                } else {
                    this.npiValidityChanged.emit(this.npiFormControl.valid);
                    this.onUserChangedNpiChange(true);
                }
            });
    }

    async setNpiValidation() {
        const npiAsyncValidator: AsyncValidatorFn | null =
            this.npiFormControl.value && this.npiFormControl.value.length === 10
                ? candidateClearNPIValidator(this.submittalsVendorService, this.jobId, this.vendorId)
                : null;

        if (this._enableNpiRequiredValidation) {
            this.npiDefaultValidators = [Validators.required, Validators.pattern(/^[0-9]{10}$/)];
        } else {
            this.npiDefaultValidators = [Validators.pattern(/^[0-9]{10}$/)];
        }

        if (this.npiFormControl) {
            this.npiFormControl.setValidators(this.npiDefaultValidators);
            if (npiAsyncValidator && this._enableNpiRequiredValidation) {
                try {
                    const validationResult: ValidationErrors | null = await npiAsyncValidator(this.npiFormControl);

                    validationResult.pipe(takeUntil(this.d$)).subscribe((result) => {
                        this.showNpiNotClearedBanner.emit(result && result.npiNotCleared);
                    });
                } catch (error) {
                    return;
                }
            } else {
                this.showNpiNotClearedBanner.emit(false);
            }
            this.npiFormControl.updateValueAndValidity();
            this.npiFormControl.markAsTouched();
        }
    }

    deleteLicense(stateId: number): void {
        const controlToRemove = this.formArray.controls.find(
            (formGroup: UntypedFormGroup) => formGroup.get('stateId').value === stateId
        );
        this.formArray.removeAt(this.formArray.controls.indexOf(controlToRemove));
        this.stateAutocompleteFormControl.setValue('');
        this.stateAutocompleteFormControl.markAsTouched();
    }

    addLicense(license?: StateLicense): void {
        const form = this.formBuilder.group({
            candidateId: this.formBuilder.control(this.candidateId),
            stateId: this.formBuilder.control(license?.stateId),
            stateName: this.formBuilder.control(license?.stateDto?.name),
            licenseNumber: this.formBuilder.control(license?.licenseNumber),
            expDate: this.formBuilder.control(license?.expDate, [expirationDateValidator]),
            isCompact: this.formBuilder.control(license.isCompact)
        });

        const sortedFormArray = this.formArray.controls.sort((controlA, controlB) => {
            const stateA = controlA.get('stateName').value;
            const stateB = controlB.get('stateName').value;

            return stateA < stateB ? -1 : 1;
        });

        const higherStateIndex = sortedFormArray.findIndex((x) => x.get('stateName').value > license.stateDto?.name);

        if (higherStateIndex === -1) {
            this.formArray.push(form);
        } else {
            this.formArray.insert(higherStateIndex, form);
        }
    }

    populate(licenses: StateLicense[], npi?: string): void {
        this.formArray.clear();

        licenses?.forEach((license) => {
            this.addLicense(license);
        });

        this.stateAutocompleteFormControl.setValue('');
        if (npi?.length) {
            this.npiFormControl.setValue(npi);
        }

        this.dataPopulated = true;
    }

    getLicenses(): StateLicense[] {
        const result = new Array<StateLicense>();

        this.formArray.controls.forEach((licenseForm: UntypedFormGroup) => {
            const license: StateLicense = {
                vendorCandidateId: licenseForm.get('candidateId').value,
                stateId: licenseForm.get('stateId').value,
                licenseNumber: licenseForm.get('licenseNumber').value,
                expDate: licenseForm.get('expDate').value,
                isCompact: licenseForm.get('isCompact').value,
                stateDto: this.states.find((x) => x.id === licenseForm.get('stateId').value)
            };

            result.push(license);
        });
        return result;
    }

    public getStateName(stateId: number): string {
        return this.states?.find((x) => x.id === stateId)?.name ?? '';
    }

    stateSelected(event: MatAutocompleteSelectedEvent): void {
        const state = this.states.find((x) => x.id === +event.option.value);

        this.addLicense({
            vendorCandidateId: this.candidateId,
            stateId: state.id,
            licenseNumber: null,
            expDate: null,
            isCompact: false,
            stateDto: state
        });

        if (this.stateInput?.nativeElement) {
            this.stateInput.nativeElement.value = '';
        }
        this.stateAutocompleteFormControl.setValue(null);
        this.stateAutocompleteFormControl.markAsTouched();
    }

    addFilteredState(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        const state = this.states.find((x) => x.name.toLowerCase() === value?.toLowerCase());

        if (state) {
            this.addLicense({
                vendorCandidateId: this.candidateId,
                stateId: state.id,
                licenseNumber: null,
                expDate: null,
                isCompact: false,
                stateDto: state
            });
        }

        if (this.stateInput?.nativeElement) {
            this.stateInput.nativeElement.value = '';
        }
        this.stateAutocompleteFormControl.setValue(null);
        this.stateAutocompleteFormControl.markAsTouched();
    }

    onNpiInputChange(event): void {
        this.onUserChangedNpiChange(true);
    }

    onUserChangedNpiChange(newValue: boolean) {
        this.userChangedNpi = newValue;
        this.userChangedNpiChange.emit(this.userChangedNpi);
    }
}
