import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'validation-message',
    templateUrl: 'validation-message.component.html',
    styleUrls: ['validation-message.component.scss']
})
export class ValidationMessageComponent implements OnInit {
    @Input() label: string = 'Field';
    @Input() control: UntypedFormControl;
    @Input() maxLength?: number;
    @Input() customMessage?: string;
    @Input() useConstantCustomMessage?: boolean;
    @Input() orientation: 'right' | 'top' = 'right';

    messages$: Observable<Array<string>>;

    ngOnInit() {
        // Doesn't trigger when markAllAsTouched
        this.messages$ = this.control.statusChanges.pipe(
            map(() => {
                if (this.control.errors) {
                    return Object.keys(this.control.errors).map((key) => this.mapError(key, this.control.errors[key]));
                }

                return null;
            })
        );
    }

    get messages() {
        if (this.control.touched && this.control.errors) {
            const errors = Object.keys(this.control.errors).map((key) => this.mapError(key, this.control.errors[key]));
            return errors.slice(0, 1);
        }

        return null;
    }

    mapError(key: string, errorValue: any) {
        if (this.useConstantCustomMessage) {
            return this.customMessage;
        }

        switch (key.toLowerCase()) {
            case 'required':
                if (this.customMessage === undefined) {
                    this.customMessage = 'is Required';
                }
                return `${this.label} ${this.customMessage}`;
            case 'maxlength':
                return `${this.label} must be ${errorValue.requiredLength} characters or less`;
            case 'password-mindigits':
                return `${this.label} should at least one digit`;
            case 'password-minlowers':
                return `${this.label} should at least one lower case letter`;
            case 'password-minuppers':
                return `${this.label} should at least one upper case letter`;
            case 'password-minchars':
                return `${this.label} should contain minimum 8 characters`;
            case 'password-match':
                return `${this.label} does not match`;
            case 'email-is-exist':
                return `${this.label} address already exist.`;
            case 'min-age':
                return `${this.label} should be at least ${errorValue} years old.`;
            case 'max-age':
                return `${this.label} should be less ${errorValue} years old.`;
            case 'html':
                return `${this.label} is not a valid HTML text.`;
            case 'chartstring':
                return `${this.label} ${errorValue}.`;
            case 'decimalnumber':
            case 'min':
                return `${this.label} is not a valid number.`;
            case 'empty':
            default:
                return `${this.label} is Invalid`;
        }
    }

    get orientationClassName() {
        return `orientation-${this.orientation}`;
    }
}
