import {
    Component,
    Input,
    ChangeDetectionStrategy,
    EventEmitter,
    Output,
    ViewChild,
    ElementRef,
    Renderer2,
    OnInit,
    OnDestroy,
    AfterViewInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { debounce, of, Subject, timer } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LDFeatureManager } from "src/app/shared/feature-management/ld-feature-manager";
import { FeatureFlag } from "src/app/shared/models/enums/feature-flag.enum";

@Component({
    selector: 'grid-text-filter',
    templateUrl: './text-filter.component.html',
    styleUrls: ['./text-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFilterComponent extends BaseFilterCellComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public field: string;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() public disabled: boolean;
    @Input() public placeholder: string;
    @Input() public widthPercentage: number;
    @Input() public width: number;
    @Output() public filterChanging = new EventEmitter<any>();
    @Input() title: string;
    @Input() uniqueFilterClass: string;
    @Input() filterDebounceTime: number = 500;
    @ViewChild('filterText', { static: true }) textControl: ElementRef;

    filterCtrl = new UntypedFormControl('');

    disabledTemplateVal = false;

    private readonly _cleanup$ = new Subject();
    featureFlag = FeatureFlag;
    enabledDebounceTime = false;

    constructor(filterService: FilterService, private readonly renderer: Renderer2,
                private readonly _featureManager: LDFeatureManager) {
        super(filterService);
    }

    public get selectedValue(): any {
        const filter = this.filterByField(this.field);
        if (filter && filter.value) {
            return filter.value;
        }
        return null;
    }

    public get placeholderText(): string {
        return this.placeholder ? this.placeholder : '';
    }

    ngOnInit(): void {
        this._featureManager
            .isEnabled(this.featureFlag.EnableDebounceTimeInGridTextFilters)
            .pipe(takeUntil(this._cleanup$))
            .subscribe((flagIsEnabled) => {
                if (flagIsEnabled) {
                    this.enabledDebounceTime = true;
                }
            });

        this.filterCtrl.valueChanges
            .pipe(
                debounce(() => this.enabledDebounceTime ? timer(this.filterDebounceTime) : of({})), //change to debounceTime(this.filterDebounceTime) when flag is removed
                distinctUntilChanged(),
                takeUntil(this._cleanup$))
            .subscribe((value: string) => {
                this.filterChanging.emit({ value, filter: this.filter });

                this.applyFilter(
                    value === null || value.length === 0
                        ? this.removeFilter(this.field)
                        : this.updateFilter({
                              field: this.field,
                              operator: 'contains',
                              value
                          })
                );
            });

        this.handleDisabled();
    }

    handleDisabled() {
        if (this.disabled) {
            this.filterCtrl.disable();
        }
    }

    ngOnDestroy(): void {
        this._cleanup$.next(true);
        this._cleanup$.complete();
    }

    ngAfterViewInit() {
        const filterValue = this.selectedValue;
        if (filterValue) {
            this.filterCtrl.setValue(filterValue);
        }
        if ((this.width || this.widthPercentage) && this.textControl) {
            this.renderer.setStyle(
                this.textControl.nativeElement,
                'width',
                this.width ? `${this.width}px` : `${this.widthPercentage}%`
            );
        }
    }
}
