import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, catchError, tap, exhaustMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubmittalsService } from 'src/app/submittals/services/submittals.service';
import * as submittalActions from 'src/app/submittals/store/submittals.actions';
import { SubmittalsBaseEffect } from './submittals-base.effect';
import { ToasterService } from 'src/app/core/services/toaster.service';

@Injectable()
export class SubmittalsLookupsEffect extends SubmittalsBaseEffect {
    getLookupsSecurity$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittalsLookupsSecurity),
            exhaustMap((action) =>
                this.submittalsService.getLookups().pipe(
                    map((res) =>
                        res
                            ? submittalActions.loadSubmittalsLookupsSecuritySuccess({
                                  systems: res.systems,
                                  facilities: res.facilities,
                                  units: res.units
                              })
                            : submittalActions.loadSubmittalsLookupsSecurityFail({ error: 'Error occured.' })
                    ),
                    catchError((error) => of(submittalActions.loadSubmittalsLookupsSecurityFail({ error })))
                )
            )
        )
    );

    getLookupsSecuritySuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalsLookupsSecuritySuccess),
                tap((action) => {
                    this.logData('Lookups (security) loaded.');
                })
            ),
        { dispatch: false }
    );

    getLookupsSecurityFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalsLookupsSecurityFail),
                tap((error) => {
                    this.logError(error);
                })
            ),
        { dispatch: false }
    );

    getLookupsStatuses$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittalsLookupsStatuses),
            exhaustMap((action) =>
                this.submittalsService.getStatuses().pipe(
                    map((res) =>
                        res && res.statuses
                            ? submittalActions.loadSubmittalsLookupsStatusesSuccess({
                                  statuses: res.statuses,
                                  mappedStatuses: null
                              })
                            : submittalActions.loadSubmittalsLookupsStatusesFail({
                                  error: this.submittalStatusErrorMessage
                              })
                    ),
                    catchError((error) => {
                        this.logError(error);
                        return of(submittalActions.loadSubmittalsLookupsStatusesFail({ error }));
                    })
                )
            )
        )
    );

    getIrpSubmittalsStatusesV2$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittalsLookupsStatuses),
            exhaustMap((action) =>
                this.submittalsService.getStatusesV2(true).pipe(
                    map((res) =>
                        res && res.statuses
                            ? submittalActions.loadIrpSubmittalStatusesSuccess({
                                  irpStatuses: res.statuses
                              })
                            : submittalActions.loadSubmittalsLookupsStatusesFail({
                                  error: this.submittalStatusErrorMessage
                              })
                    ),
                    catchError((error) => {
                        this.logError(error);
                        return of(submittalActions.loadSubmittalsLookupsStatusesFail({ error }));
                    })
                )
            )
        )
    );

    getLookupsStatusesSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalsLookupsStatusesSuccess),
                tap((res) => {
                    this.logData('Lookups (statuses) loaded.');
                })
            ),
        { dispatch: false }
    );

    loadSubmittalDeclineReasons$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittalDeclineReasons),
            switchMap((action) => {
                return this.submittalsService.getDeclineReasons().pipe(
                    map((response) =>
                        submittalActions.loadSubmittalDeclineReasonsSuccess({ declineReasons: response })
                    ),
                    catchError((error) => {
                        this.logError(error);
                        return of(submittalActions.loadSubmittalDeclineReasonsFail({ error }));
                    })
                );
            })
        )
    );

    submittalStatusErrorMessage = 'Cannot load submittal statuses.';

    constructor(
        private readonly actions: Actions,
        private readonly submittalsService: SubmittalsService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
