import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Unit, UnitFacilityInformation } from 'src/app/clinical/models';
import { ChartingSystem } from 'src/app/clinical/models/charting-systems.model';
import { RequiretyText } from 'src/app/clinical/models/requirety-text.enum';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager as LdFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-work-experience',
    templateUrl: './work-experience.component.html',
    styleUrls: ['./work-experience.component.scss']
})
export class WorkExperienceComponent extends UnsubscribeOnDestroy implements OnDestroy, OnChanges, OnInit {
    @Output() valueChanged = new EventEmitter<{ unit: Partial<Unit>; isValid: boolean }>();

    @Input() readOnly = false;
    chartingSystems: ChartingSystem[];

    @Input() isPrinting: boolean = false;
    @Input() hasClinicalTrackRequirements: boolean = false;
    @Input() clinicalTrackId: number | null = null;

    _unitInfo: UnitFacilityInformation;

    @Input()
    set unitInfo(unitInfo: UnitFacilityInformation) {
        this._unitInfo = unitInfo;
        this.isLoading = true;
        this._unit = unitInfo?.unit;
        this.chartingSystems = unitInfo?.chartingSystems;

        this.setFormValues(this._unit);
        this.isLoading = false;
        this.watchFormChanges();
    }

    workExperienceForm: UntypedFormGroup;
    isLoading = false;
    requirety = RequiretyText;
    requireAnyElectronicDocumentationIsEnabled: boolean = false;

    private _unit: Unit;

    get chartingExperience(): AbstractControl {
        return this.workExperienceForm.get('chartingExperience');
    }

    get chartingExperienceCode(): AbstractControl {
        return this.workExperienceForm.get('chartingExperienceCode');
    }

    get hasRequirety(): boolean {
        return ['isPreferred', 'isRequired'].includes(this.chartingExperience.value);
    }

    get preferredOrRequired(): boolean {
        return (
            this.chartingExperience.value === this.requirety.Preferred ||
            this.chartingExperience.value === this.requirety.Required
        );
    }

    get isValidForSubmit(): boolean {
        if (this.workExperienceForm && this.preferredOrRequired) {
            const isValid = this.chartingExperienceCode.value !== null;

            return isValid;
        }

        return true;
    }

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _ldFeatureManager: LdFeatureManager
    ) {
        super();
        this.buildForm();
    }

    ngOnInit(): void {
        this.setChartingExperienceCodeOnFormLoad();

        this._ldFeatureManager
            .isEnabled(FeatureFlag.RequireAnyElectronicDocumentation)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.requireAnyElectronicDocumentationIsEnabled = isEnabled;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.workExperienceForm, changes.readOnly.currentValue);
        }

        if (this.requireAnyElectronicDocumentationIsEnabled && changes.clinicalTrackId !== undefined) {
            if (!!changes.clinicalTrackId.currentValue) {
                this.chartingExperience.patchValue(this.requirety.Required);
                this.chartingExperience.disable();
            } else {
                this.chartingExperience.patchValue(this.requirety.None)
                this.chartingExperience.enable();
            }

            this.chartingExperienceCode.updateValueAndValidity();
        }
    }

    buildForm(): void {
        this.workExperienceForm = this._formBuilder.group({
            previousChargeExperience: this._formBuilder.control(''),
            yearOfExperience: this._formBuilder.control(
                0,
                Validators.compose([Validators.min(0), Validators.max(10000), Validators.maxLength(10)])
            ),
            minimumExperienceRequiredOther: this._formBuilder.control('', Validators.maxLength(500)),
            patientRatioExperience: this._formBuilder.control('', Validators.maxLength(50)),
            chartingExperience: this._formBuilder.control(''),
            chartingExperienceCode: this._formBuilder.control('', this.getChartingExperienceCodeValidator()),
            teachingHospitalExperience: this._formBuilder.control(''),
            communityHospitalExperience: this._formBuilder.control(''),
            ltacExperience: this._formBuilder.control(''),
            traumaLevelIExperience: this._formBuilder.control(''),
            traumaLevelIIExperience: this._formBuilder.control(''),
            chartingExperiences: this._formBuilder.control('')
        });
    }

    setFormValues(unit: Unit) {
        if (unit) {
            this.workExperienceForm
                .get('previousChargeExperience')
                .patchValue(this.convertToRequirety(unit.previousChargeExperience));
            this.workExperienceForm.get('yearOfExperience').patchValue(unit.yearOfExperience);
            this.workExperienceForm
                .get('minimumExperienceRequiredOther')
                .patchValue(unit.minimumExperienceRequiredOther);
            this.workExperienceForm.get('patientRatioExperience').patchValue(unit.patientRatioExperience);
            this.workExperienceForm
                .get('chartingExperience')
                .patchValue(this.convertToRequirety(unit.chartingExperience));
            this.workExperienceForm.get('chartingExperienceCode').patchValue(unit.chartingExperienceCode);
            this.workExperienceForm
                .get('teachingHospitalExperience')
                .patchValue(this.convertToRequirety(unit.teachingHospitalExperience));
            this.workExperienceForm
                .get('communityHospitalExperience')
                .patchValue(this.convertToRequirety(unit.communityHospitalExperience));
            this.workExperienceForm.get('ltacExperience').patchValue(this.convertToRequirety(unit.ltacExperience));
            this.workExperienceForm
                .get('traumaLevelIExperience')
                .patchValue(this.convertToRequirety(unit.traumaLevelIExperience));
            this.workExperienceForm
                .get('traumaLevelIIExperience')
                .patchValue(this.convertToRequirety(unit.traumaLevelIIExperience));
        }
    }

    getFormValues(): Partial<Unit> {
        const unit: Partial<Unit> = {
            previousChargeExperience: this.workExperienceForm.get('previousChargeExperience').value,
            yearOfExperience: this.workExperienceForm.get('yearOfExperience').value,
            minimumExperienceRequiredOther: this.workExperienceForm.get('minimumExperienceRequiredOther').value,
            patientRatioExperience: this.workExperienceForm.get('patientRatioExperience').value,
            chartingExperience: this.workExperienceForm.get('chartingExperience').value,
            chartingExperienceCode: this.workExperienceForm.get('chartingExperienceCode').value,
            communityHospitalExperience: this.workExperienceForm.get('communityHospitalExperience').value,
            ltacExperience: this.workExperienceForm.get('ltacExperience').value,
            traumaLevelIExperience: this.workExperienceForm.get('traumaLevelIExperience').value,
            traumaLevelIIExperience: this.workExperienceForm.get('traumaLevelIIExperience').value,
            teachingHospitalExperience: this.workExperienceForm.get('teachingHospitalExperience').value
        };

        return unit;
    }

    watchFormChanges(): void {
        this.workExperienceForm.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => {
            if (!this.isLoading) {
                this.valueChanged.emit({ unit: this.getFormValues(), isValid: this.workExperienceForm.valid });
            }
        });
    }

    convertToRequirety(value: string): RequiretyText {
        if (value === 'isPreferred' || value === 'Preferred') {
            return RequiretyText.Preferred;
        } else if (value === 'isRequired' || value === 'Required') {
            return RequiretyText.Required;
        }

        return RequiretyText.None;
    }

    setChartingExperienceCodeOnFormLoad() {
        if (this.hasRequirety && this.chartingExperienceCode.value === null) {
            this.chartingExperienceCode.patchValue('');
        }
    }

    getChartingExperienceCodeRequirety(): boolean {
        if (this.workExperienceForm) {
            const touchedOrDirty = this.chartingExperienceCode.touched || this.chartingExperienceCode.dirty;
            const isRequired = this.preferredOrRequired && touchedOrDirty && this.chartingExperienceCode.value === null;

            return isRequired;
        }

        return false;
    }

    getChartingExperienceCodeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const required = this.getChartingExperienceCodeRequirety();

            if (required) {
                return { required: true };
            }

            return null;
        };
    }

    markAsTouched(): void {
        if (this.hasRequirety) {
            this.chartingExperienceCode.markAsTouched();
            this.chartingExperienceCode.updateValueAndValidity();
        }
    }
}
