import { NgModule } from '@angular/core';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { FixTimezoneOffsetPipe } from './fix-timezone-offset.pipe';
import { FilterPipe } from './filter.pipe';
import { ArraySortPipe } from './sort.pipe';
import { MultipleDatesFormatPipe } from './multiple-dates-format.pipe';
import { AddDurationPipe } from './add-duration.pipe';
import { AddSpaceBeforeCapitalLettersPipe } from './add-space-before-capital-letters.pipe';
import { GroupByPipe } from './group-by.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { StringListPipe } from './string-list.pipe';
import { NotEqualsFilterPipe } from './not-equals-filter.pipe';
import { FacilityImagePipe } from './facility-image.pipe';
import { FindOnePipe } from 'src/app/shared/pipes/find-one.pipe';
import { PredictiveSearchFilterPipe } from './predictive-search.pipe';
import { ByteFormatterPipe } from 'src/app/shared/pipes/byte-formatter.pipe';
import { TrimWhiteSpacePipe } from 'src/app/shared/pipes/trim-white-space.pipe';
import { FilterByPipe } from 'src/app/shared/pipes/filter-by.pipe';
import { VendorContactNamePipe } from './vendor-contact-name.pipe';
import { VendorContactEmailPipe } from './vendor-contact-email.pipe';
import { VendorContactPhonePipe } from './vendor-contact-phone.pipe';
import { LookupPipe } from './lookup.pipe';
import { IsActivePipe } from './is-active.pipe';
import { IsSubmenuActivePipe } from './is-submenu-active.pipe';
import { EnumStringValuePipe } from './enum-to-string.pipe';
import { ReplacePipe } from 'src/app/shared/pipes/replace.pipe';
import { NullNaPipe } from './null-na.pipe';
import { SanitizerPipe } from './sanitizer.pipe';
import { StringDatePipe } from './string-date.pipe';
import { PhonePipe } from './phone.pipe';
import { IconTypePipe } from './icon-type.pipe';
import { ReleaseDayPipe } from './days-to-release-pipe';
import { GetOptionNamePipe } from './get-option-name.pipe';
import { HighlightSearchPipe } from './highlight-search.pipe';
import { InitialsFromNamePipe } from './initials-from-name.pipe';
import { NullDashPipe } from './null-dash.pipe';
import { RuleNamePipe } from './rule-name.pipe';
import { HoursRuleNamePipe } from './hours-rule-name-pipe';
import { VaccinationTypePipe } from './vaccination-type.pipe';
import { BooleanPipe } from './boolean.pipe';
import { CertificationDetailsPipe } from './certification-details.pipe';
import { RelativeDateFormatPipe } from './relative-date-format.pipe';
import { RequestTimeOffPipe } from './request-time-off.pipe';
import { EnumListToStringPipe } from "src/app/shared/pipes/enum-list-to-string.pipe";

@NgModule({
    declarations: [
        ArraySortPipe,
        FilterPipe,
        SafePipe,
        FixTimezoneOffsetPipe,
        MultipleDatesFormatPipe,
        AddDurationPipe,
        AddSpaceBeforeCapitalLettersPipe,
        GroupByPipe,
        LinkifyPipe,
        StringListPipe,
        NotEqualsFilterPipe,
        FacilityImagePipe,
        FindOnePipe,
        PredictiveSearchFilterPipe,
        ByteFormatterPipe,
        TrimWhiteSpacePipe,
        ReplacePipe,
        FilterByPipe,
        VendorContactNamePipe,
        VendorContactEmailPipe,
        VendorContactPhonePipe,
        LookupPipe,
        IsActivePipe,
        IsSubmenuActivePipe,
        EnumStringValuePipe,
        EnumListToStringPipe,
        NullNaPipe,
        SanitizerPipe,
        StringDatePipe,
        PhonePipe,
        IconTypePipe,
        ReleaseDayPipe,
        GetOptionNamePipe,
        HighlightSearchPipe,
        InitialsFromNamePipe,
        NullDashPipe,
        RuleNamePipe,
        HoursRuleNamePipe,
        VaccinationTypePipe,
        CertificationDetailsPipe,
        BooleanPipe,
        RelativeDateFormatPipe,
        RequestTimeOffPipe
    ],
    exports: [
        ArraySortPipe,
        FilterPipe,
        SafePipe,
        FixTimezoneOffsetPipe,
        MultipleDatesFormatPipe,
        AddDurationPipe,
        AddSpaceBeforeCapitalLettersPipe,
        GroupByPipe,
        LinkifyPipe,
        StringListPipe,
        NotEqualsFilterPipe,
        FacilityImagePipe,
        FindOnePipe,
        PredictiveSearchFilterPipe,
        ByteFormatterPipe,
        TrimWhiteSpacePipe,
        ReplacePipe,
        FilterByPipe,
        VendorContactNamePipe,
        VendorContactEmailPipe,
        VendorContactPhonePipe,
        LookupPipe,
        IsActivePipe,
        IsSubmenuActivePipe,
        EnumStringValuePipe,
        EnumListToStringPipe,
        NullNaPipe,
        StringDatePipe,
        SanitizerPipe,
        PhonePipe,
        IconTypePipe,
        ReleaseDayPipe,
        GetOptionNamePipe,
        HighlightSearchPipe,
        InitialsFromNamePipe,
        NullDashPipe,
        RuleNamePipe,
        HoursRuleNamePipe,
        VaccinationTypePipe,
        BooleanPipe,
        CertificationDetailsPipe,
        RelativeDateFormatPipe,
        RequestTimeOffPipe
    ]
})
export class PipesModule {}
