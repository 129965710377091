
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { ShiftsState, shiftsStoreKey } from 'src/app/shifts/store/shifts.state';
import { AppState } from 'src/app/store/state/App.state';
import { ShiftStatusView } from 'src/app/shared/models/shifts';
import { SubmittalShiftStatus } from 'src/app/shared/models/submittals/enums/submittal-shift-status.enum';

export const selectFeature = createFeatureSelector< ShiftsState>(shiftsStoreKey);

export const getError = createSelector(
    selectFeature,
    state => state.list.error
)

export const selectShifts = createSelector(
    selectFeature,
    (state: ShiftsState) => {
      return state.list.shifts
    }
)

export const selectShiftsData = createSelector(
    selectShifts,
    state => ({
        data: state?.data ?? [],
        total: state?.total ?? 0
    })
)

export const selectSelectedShift = createSelector(
    selectFeature,
    (state: ShiftsState) => state.list.selectedShift
)

export const selectGridDataQuery = createSelector(
    selectFeature,
    (state: ShiftsState) => state.list.filterParams
)

export const selectPredefinedQuery = createSelector(
    selectFeature,
    (state: ShiftsState) => {
        const filterParams = state.list.filterParams;
        const target = {
            facilityId: filterParams.facilities,
            unitId: filterParams.units,
            professionId: filterParams.professions,
            specialtyId: filterParams.specialties
        }

        return Object.keys(target)
            .filter(key => target[key].length === 1)
            .reduce((group, key) => ({
                ...group,
                [key]: target[key][0].id
            }), {});
    }
)

export const selectIsShiftGridLoading = createSelector(
    selectFeature,
    (state) =>
    [
        state.lookups.loading,
        state.list.shiftsLoadStatus
    ].some(x => x === LoadingTypes.LOADING)
)

export const selectUnitFilterLookups = createSelector(
    selectFeature,
    (state: ShiftsState) => {
        const units = state.lookups === null ? [] :
            state.lookups.units;
        return units;
    }
)

export const selectLookupsFeature = createSelector(
    selectFeature,
    (state) => state.lookups
)

export const selectIsLookupsLoaded = createSelector(
    selectLookupsFeature,
    (state) => state.loading === LoadingTypes.LOADED
)

export const selectLookupsProfessions = createSelector(
    selectLookupsFeature,
    (state) => state.professions
)

export const selectLookupsUnits = createSelector(
    selectLookupsFeature,
    (state) => state.units
)

export const selectLookupsSpecialties = createSelector(
    selectLookupsFeature,
    (state) => state.specialties
)

export const selectLookupsFacilities = createSelector(
    selectLookupsFeature,
    (state) => state.facilities
)

export const selectLookupsFacilitiesWithAll = createSelector(
    selectLookupsFacilities,
    (facilities) => [
        {
            id: 0,
            name: "Select All",
            profileGroupId: 0,
            profileGroupName: "Select All",
            clientId: 0,
            systemId: 0,
            facilityTypeId: 0,
        },
        ...facilities
    ]
)

export const selectLookupsUnions = createSelector(
    selectLookupsFeature,
    (state) => state.unions
)

export const selectLookupsStates = createSelector(
    selectLookupsFeature,
    (state) => state.states
)

export const selectLookupsLanguages = createSelector(
    selectLookupsFeature,
    (state) => state.languages
)

export const selectLookupsLanguageSkills = createSelector(
    selectLookupsFeature,
    (state) => state.languageSkillLevels
)

export const selectLookupsAvailability = createSelector(
    selectLookupsFeature,
    (state) => state.availabilityStatuses
)

export const selectLookupsEpicSkills = createSelector(
    selectLookupsFeature,
    (state) => state.epicSkills
)

export const selectLookupsReleases = createSelector(
    selectLookupsFeature,
    (state) => state.releases
)

export const selectResourceTypes = createSelector(
    selectLookupsFeature,
    (state) => state.resourceTypes
);

export const selectFacilityAddressInfos = createSelector(
    selectFeature,
    (state) => state.shiftFacilityAddressInfo.facilityAddressInfos
)

export const selectShiftStatus = createSelector(
    selectFeature,
    (state) => state.details.shift?.statusId
);


export const selectIsShiftPendingApproval = createSelector(
    selectShiftStatus,
    (statusId) => statusId === ShiftStatusView.PendingApproval
);

export const selectIsShiftPendingApprovalOrRejected = createSelector(
    selectShiftStatus,
    selectIsShiftPendingApproval,
    (statusId, isPending) => isPending || statusId === ShiftStatusView.Rejected
);

export const selectLookupsSubmittalStatuses = createSelector(
    selectLookupsFeature,
    selectIsShiftPendingApprovalOrRejected,
    (state, isPendingOrRejected) => isPendingOrRejected ?
        state.submittalStatuses.filter(ss => ss.id !== SubmittalShiftStatus.Booked) :
        state.submittalStatuses
)

export const selectShiftDetailsFeature = createSelector(
    selectFeature,
    (state) => state.details
)

export const selectShiftDetails = createSelector(
    selectShiftDetailsFeature,
    (state) => {
        return state.shift
    }
)

export const selectShiftReleaseGroups = createSelector(
    selectShiftDetailsFeature,
    (state) => {
        return state.releaseGroups
    }
)

export const selectPreseletedVendors = createSelector(
    selectShiftDetailsFeature,
    (state) => {
        return state.preseletedVendors
    }
)

export const selectIsPreseletedVendorsChanged = createSelector(
    selectShiftDetailsFeature,
    (state) => {
        const initialPreselected = (state.releaseGroups || []).reduce((a, { id, vendors }) =>
            a.concat(vendors.filter(v => v.isSelectedForRelease).map(vendor => ({ vendorId: vendor.id, releaseGroupId: id }))), []);
        const currentPreseleted = (state.preseletedVendors || []).map(x => ({ vendorId: x.id, releaseGroupId: x.releaseGroupId }));
        if (initialPreselected.length !== currentPreseleted.length) {
            return true;
        }
        for (const initial of initialPreselected) {
            if (!currentPreseleted.some(x => x.vendorId === initial.vendorId && x.releaseGroupId === initial.releaseGroupId)) {
                return true;
            }
        }
        return false;
    }
)

export const selectCanSavePreselectVendors = createSelector(
    selectFeature,
    (state) => {
        const features = state.list.shiftFacilityFeatures;
        const shift = state.details.shift;
        if (!shift || !features) {
            return false;
        }
        return !shift.isInternalPool && features.isShiftReleaseEnabled &&
            (shift.id && shift.canSavePreselectedVendorsForRelease || !shift.id);
    }
)

export const selectShiftDetailsIsLoading = createSelector(
    selectFeature,
    (state) =>
    [
        state.lookups.loading,,
        state.lookups.templatesLoading,
        state.details.loading,
        state.details.saving,
        state.combinedHistory.loading,
        state.shiftCandidates.loading,
        state.attachments.loading,
        state.shiftCandidates.loading,
        state.shiftSubmittalCandidates.loading,
        state.templateDetails.loading
    ].some(x => x === LoadingTypes.LOADING)
)

export const selectShiftDetailsIsLoaded = createSelector(
    selectShiftDetailsFeature,
    (state) => state.loading === LoadingTypes.LOADED
)

export const selectShiftDetailsIsLoadingFail = createSelector(
    selectShiftDetailsFeature,
    (state) => state.loading === LoadingTypes.FAIL
)

export const selectIsShiftSaved = createSelector(
    selectShiftDetailsFeature,
    (state) => state.saving === LoadingTypes.LOADED
)

export const selectStatusesFilterLookups = createSelector(
    selectFeature,
    (state: ShiftsState) => {
        return state.lookups.statuses.filter(s => !s.hidden);
    }
)

export const selectShiftVisibility= createSelector(
    selectFeature,
    (state) => state.lookups.shiftsVisibility
)

export const selectClientLookups = createSelector(
    selectFeature,
    (state: ShiftsState) => state.clientLookups
)

export const selectShiftCombinedHistoryFeature = createSelector(
    selectFeature,
    (state) => state.combinedHistory
)

export const selectShiftCombinedHistory = createSelector(
    selectShiftCombinedHistoryFeature,
    (state) => state.history
)

export const selectShiftCandidates = createSelector(
    selectFeature,
    (state) => state.shiftCandidates.candidates
)

export const selectShiftCandidatesIsLoading = createSelector(
    selectFeature,
    (state) => state.shiftCandidates.loading === LoadingTypes.LOADING
)

export const selectBulkShiftSeries = createSelector(
    selectFeature,
    (state: ShiftsState) => {
        return state.bulkShifts.bulkShifts
      }
)

export const selectAttachmentsFeature = createSelector(
    selectFeature,
    (state: ShiftsState) =>
        state.attachments
)

export const selectShiftFacilityFeature = createSelector(
    selectFeature,
    (state: ShiftsState) =>
        state.list.shiftFacilityFeatures
)

export const selectShiftAttachmentsFeature = createSelector(
    selectFeature,
    (state: ShiftsState) =>
        state.attachments.attachmentFeatures
)

export const selectAttachments = createSelector(
    selectAttachmentsFeature,
    (state) =>
        state.attachments.filter(a => a.isDeleted !== true)
)

export const selectAttachmentPreview = createSelector(
    selectAttachmentsFeature,
    (state) => {
        return {
            blob: state.preview,
            totalPages: state.previewTotal
        }
    }
)

export const selectAttachmentCurrent = createSelector(
    selectAttachmentsFeature,
    (state) =>
        state.currentFile
)

export const selectAttachmentsPreviewLoading = createSelector(
    selectAttachmentsFeature,
    (state) =>
        state.previewTotal
)

export const selectAttachmentsLoading = createSelector(
    selectAttachmentsFeature,
    (state) =>
        state.loading === LoadingTypes.LOADING
)

export const selectAttachmentChangeStatus = createSelector(
    selectAttachmentsFeature,
    (state) =>
        state.attachmentChange
)

export const selectApprovalDetails = createSelector(
    selectFeature,
    (state: ShiftsState) => state.approvalDetails.approvalDetailsTiers
);

export const selectApprovalDetailsIsLoading = createSelector(
    selectFeature,
    (state: ShiftsState) => state.approvalDetails.isLoading
);

export const selectShiftReleaseHistory = createSelector(
    selectFeature,
    (state: ShiftsState) => state.details.history
);
