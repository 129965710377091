import { ConnexusFeatureFlags } from './connexus-feature-flags.enum';
import { StarBucksFeatureFlags } from './starbucks-feature-flags.enum';
import { AyaWannaConnectFeatureFlags } from './aya-wanna-connect-feature-flags.enum';
import { ALatteFunFeatureFlags } from './a-latte-fun-feature-flags';
import { AyaKiddingMeFeatureFlags } from './aya-kidding-me-feature-flags.enum';
import { ItsOver9000FeatureFlags } from './its-over-9000-feature-flags';
import { AMIRightFeatureFlags } from './am-i-right-feature-flags';
import { ProgramManagementFeatureFlags } from './program-management-feature-flags';
import { SupersonicFeatureFlags } from './supersonic-feature-flags.enum';
import { SyncOrSwimFlags } from './sync-or-swim-flags.enum';
import { SvtFeatureFlags } from './svt-feature-flags.enum';
import { QuesoFormaggioFeatureFlags } from './queso-formaggio-feature-flags.enum';
import { XAlchemistsFeatureFlags } from 'src/app/shared/models/enums/x-alchemists-feature-flags';
import { ShiftsGettingRealFeatureFlags } from 'src/app/shared/models/enums/shifts-getting-real-feature-flags.enum';

export enum FeatureFlags {
    // Note: leave a trailing comma,
    // it will help avoid conflicts in merge
    FeatureA = 'FeatureA',
    FeatureB = 'FeatureB',
    AddIRPRecordType = 'ADD_IRP_RECORD_TYPE',
    UpdateSelectAllForGetShifts = '20220711_SHIFTS-1970_UPDATE_SELECTALL_GETSHIFTS',
    OrientationDetailsFix = '20220808_CNW-11933_ORIENTAION_DETAILS_FIX',
    DevOpsFlag = 'DEVOPS_FLAG',
    ShowIsOnHoldFieldSubmittalsGrid = 'SHOW_IS_ON_HOLD_FIELD_SUBMITTALS_GRID',
    ConnectInvoiceDisputeAttachments = 'CONNECT_INVOICE_DISPUTE_ATTACHMENTS',
    ShowInvoicingApprovalStatus = 'CONNECT_SHOW_INVOICING_APPROVAL_STATUS',
    UseCoreEndpointsForClinicalClientsGrid = 'USE_CORE_ENDPOINTS_FOR_CLINICAL_CLIENTS_GRID',
    EnablePayTypesForLocumUserTimeCards = 'ENABLE_PAYTYPES_FOR_LOCUMS_USER_TIMECARDS',
    ConnectClientJobApprovalsForExtensionJobs = 'CONNECT_CLIENT_JOB_APPROVALS_FOR_EXTENSION_JOBS',
    EnableAccessToVendorToShiftsApp = 'ENABLE_ACCESS_TO_VENDOR_TO_SHIFTS_APP',
    EnableIrpResourceFulfillmentSettings = 'ENABLE_IRP_RESOURCE_FULFILLMENT_SETTINGS',
    RefreshCustomFieldsOnFacilityChange = 'REFRESH_CUSTOM_FIELDS_ON_FACILITY_CHANGE',
    AddApplicationIdToRequestHeaders = 'add-application-id-to-request-headers',
    EnableJobTemplateCustomFields = 'ENABLE_JOB_TEMPLATE_CUSTOM_FIELDS',
    EnableJobTemplateCustomFieldsSync = 'ENABLE_JOB_TEMPLATE_CUSTOM_FIELDS_SYNC',
    EnablePerDiemWorkerCustomFields = 'ENABLE_PER_DIEM_WORKER_CUSTOM_FIELDS',
    CopyCustomFieldsOnDuplicateJob = 'COPY_CUSTOM_FIELDS_ON_DUPLICATE_JOB',
    FacilityLocumsBenefitsToConnect = 'FACILITY_LOCUMS_BENEFITS_TO_CONNECT',
    EnableCascadingDropdownCustomField = 'ENABLE_CASCADING_DROPDOWN_CUSTOM_FIELD',
    EnableRichDropdownCustomField = 'ENABLE_RICH_DROPDOWN_CUSTOM_FIELD',
    BookCandidateShiftsTearing = 'connect-book-candidate-with-shifts-tearing',
    HideFacilitySettingCancelShiftMaxValue = 'CONNECT_HIDE_FACILITY_SETTING_CANCEL_SHIFT_TIMEFRAME_MAX',
    SystemSettingsWarningPopupForDisableShiftsTiering = 'connect-system-settings-warning-popup-for-disable-shifts-tiering',
    AddSystemColumnAtContractGroupInManageFeaturePageSection = 'ADD_SYSTEM_COLUMN_AT_CONTRACT_GROUP_IN_MANAGE_FEATURES_PAGE_SECTION',
    ResourceFulfillmentVisibilityFilter = 'RESOURCE_FULFILLMENT_VISIBILITY_FILTER',
    ShowCandidatesAccordingToAvailabilityBasedOnStartDates = 'SHOW_CANDIDATES_ACCORDING_TO_AVAILABILITY',
    EnableAvailableAndBookedTiersDisplayedForShiftDetails = 'ENABLE_AVAILABLE_AND_BOOKED_TIERS_DISPLAYED_FOR_SHIFT_DETAILS',
    OnlyCancelBulkShiftsInTheFuture = 'ONLY_CANCEL_BULK_SHIFTS_IN_THE_FUTURE',
    ShowNativeToolTipForCheckboxUnderBookAClinican = 'SHOW_NATIVE_TOOLTIP_FOR_CHECKBOX_UNDER_BOOK_A_CLINICIAN',
    ShiftTieringReleaseAtShiftStartTime = 'SHIFT_TIERING_RELEASE_AT_SHIFT_START_TIME',
    RefactoringOfEditingShiftSeries = 'REFACTORING_OF_EDITING_SHIFT_SERIES',
    ShowNativeToolTipForAgencyInShiftSettingsPage = 'SHOW_NATIVE_TOOLTIP_FOR_AGENCY_IN_SHIFT_SETTINGS_PAGE',
    ShiftSeriesCancelAllOrNothing = 'SHIFT_SERIES_CANCEL_ALL_OR_NOTHING',
    WfdHideUnitDescriptionRestrictedFields = 'WFD-2486_CONNECT_HIDE_UNIT_DESCRIPTION_RESTRICTED_FIELDS',
    AccessSecureUDLinks = 'ACCESS_SECURE_UD_LINKS',
    AddFacilityFilterToIrpWorkerPage = 'ADD_FACILITY_FILTER_TO_IRP_WORKER_PAGE',
    ConnectReportEventPendoLogging = 'CONNECT_REPORT_EVENT_PENDO_LOGGING',
    IrpWorkerFacilityFiltering = 'IRP_WORKER_FACILITY_FILTERING',
    AllowReOpenOfShiftSeriesOnceCancelled = 'ALLOW_RE_OPENING_OF_SHIFT_SERIES_ONCE_CANCELLED',
    PreventSavingShiftSeriestInOverlapSituation = 'PREVENT_SAVING_SHIFT_SERIES_IN_OVERLAP_SITUATION',
    CancelModalProfessionPopulatedFix = 'connect-cancel-modal-profession-populated-fix',
    ShiftsScheduleCalendarGridLarger = 'connect-shifts-schedule-calendar-grid-larger',
}

export const FeatureFlag = {
    ...FeatureFlags,
    ...ConnexusFeatureFlags,
    ...StarBucksFeatureFlags,
    ...AyaWannaConnectFeatureFlags,
    ...ALatteFunFeatureFlags,
    ...AyaKiddingMeFeatureFlags,
    //...ItsOver9000FeatureFlags,
    ...AMIRightFeatureFlags,
    ...ProgramManagementFeatureFlags,
    ...SupersonicFeatureFlags,
    ...SyncOrSwimFlags,
    ...SvtFeatureFlags,
    ...QuesoFormaggioFeatureFlags,
    ...ShiftsGettingRealFeatureFlags,
    ...XAlchemistsFeatureFlags
};

export type FeatureFlag =
    | FeatureFlags
    | ConnexusFeatureFlags
    | StarBucksFeatureFlags
    | AyaWannaConnectFeatureFlags
    | ALatteFunFeatureFlags
    | AyaKiddingMeFeatureFlags
    //| ItsOver9000FeatureFlags
    | AMIRightFeatureFlags
    | ProgramManagementFeatureFlags
    | SupersonicFeatureFlags
    | SyncOrSwimFlags
    | SvtFeatureFlags
    | QuesoFormaggioFeatureFlags
    | ShiftsGettingRealFeatureFlags
    | XAlchemistsFeatureFlags;
