import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { Observable, ReplaySubject, map, startWith, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ListItem } from '../../models/list-item';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';
import { FeatureFlag } from '../../models/enums/feature-flag.enum';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'autocomplete-pill-input',
    templateUrl: './autocomplete-pill-input.component.html',
    styleUrls: ['./autocomplete-pill-input.component.scss']
})
export class AutocompletePillInputComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;
    @Output() optionUpdate = new EventEmitter<ListItem[]>();
    @Input() maxChipsToShow = 5;
    @Input() enableSelectClearActions = false;
    @Input() placeholderText = 'Please make a selection';
    @Input() validateField = false;
    @Input() preselectedOptions = [];
    optionsList = [];
    optionsListCopy = [];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = false;
    separatorKeysCodes: number[] = [];
    optionsCtrl = new FormControl('');
    filteredOptions$: Observable<string[]>;
    optionsSelected: ListItem[] = [];
    selectAllEnabled = false;
    noMatches = false;
    noMatchMessage = 'No matches found';
    allOptionsSelectedMessage = 'All options have been selected';
    allOptionsSelected = false;
    remainingChipsToShow: number;
    incrementBy: number;
    noOptionsAvailable = false;
    isVendorTrimFlagEnabled = false;

    @Input() set optionsListInput(value: ListItem[]) {
        this.optionsList = this.sortOptionsByName(value);
        this.optionsListCopy = this.optionsList;
        this.selectAllEnabled = false;
        this.setFilteredOptions();
    }

    constructor(private readonly ldFeatureManager: LDFeatureManager) {
        super();
    }

    ngOnInit(): void {
        this.remainingChipsToShow = this.maxChipsToShow;
        this.incrementBy = this.maxChipsToShow;

        if (this.validateField) {
            this.optionsCtrl.setValidators([this.validateSelection]);
        }

        this.ldFeatureManager
            .isEnabled(FeatureFlag.JobRulesVendorTrimFrontEnd)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                if (flagIsEnabled) {
                    this.isVendorTrimFlagEnabled = flagIsEnabled;
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('optionsListInput' in changes || 'preselectedOptions' in changes) {
            this.matchPreselectedOptions();
        }
    }

    matchPreselectedOptions() {
        if (this.optionsList && this.preselectedOptions) {
            const selectedOptions = this.preselectedOptions
                .map((id) => {
                    const option = this.optionsList.find((opt) => opt.id === id);
                    if (option) {
                        this.filterSelectedOptions(option);
                    }
                    return option;
                })
                .filter(Boolean);
            this.optionsSelected = this.sortOptionsByName(selectedOptions);
            this.updateRemainingChipsToShow();
            this.setFilteredOptions();
            this.selectAllEnabled = this.optionsSelected.length === this.optionsListCopy.length;
            this.optionUpdate.emit(this.optionsSelected);
        }
    }

    setFilteredOptions() {
        this.filteredOptions$ = this.optionsCtrl.valueChanges.pipe(
            startWith(''),
            map((option: string) => {
                const filteredOptions = option ? this.filter(option) : this.getAvailableOptions();
                this.noMatches = this.optionsList.length && filteredOptions.length === 0 ? true : false;
                this.allOptionsSelected = this.optionsSelected.length === this.optionsListCopy.length ? true : false;
                this.noOptionsAvailable = this.optionsList.length === 0 && filteredOptions.length === 0;

                return filteredOptions;
            })
        );
    }

    remove(selectedOption: ListItem): void {
        const removedOption = this.optionsSelected.find((option) => option.id === selectedOption.id);
        const optionIndex = this.optionsSelected.indexOf(removedOption);

        if (optionIndex >= 0) {
            this.optionsSelected.splice(optionIndex, 1);
            this.optionsList.push(removedOption);
            this.optionsList = this.sortOptionsByName(this.optionsList);
            this.setFilteredOptions();
            this.onOptionChange();
            this.selectAllEnabled = false;
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        let selectedOption;
        if (this.isVendorTrimFlagEnabled) {
            selectedOption = this.optionsListCopy.find((option) => option.name.trim() === event.option.viewValue);
        } else {
            selectedOption = this.optionsListCopy.find((option) => option.name === event.option.viewValue);
        }
        if (selectedOption) {
            this.filterSelectedOptions(selectedOption);
        }

        this.optionInput.nativeElement.value = '';
        this.optionsCtrl.setValue(null);
        this.onOptionChange();
        this.selectAllEnabled = this.optionsSelected.length === this.optionsListCopy.length ? true : false;
    }

    filterSelectedOptions(selectedOption: ListItem) {
        this.optionsSelected.push(selectedOption);
        this.optionsList = this.optionsList.filter((option) => option.id !== selectedOption.id);
        this.optionsSelected = this.sortOptionsByName(this.optionsSelected);
    }

    getOptionNameById(selectedOption: ListItem): string {
        const option = this.optionsListCopy.find((o) => o.id === selectedOption.id);
        return option ? option.name : '';
    }

    onOptionChange() {
        this.optionUpdate.emit(this.optionsSelected);
    }

    selectAllOptions() {
        if (!this.selectAllEnabled) {
            this.optionsSelected = this.optionsListCopy.map((option) => option);
            this.optionsList = [];
            this.setFilteredOptions();
            this.onOptionChange();
            this.selectAllEnabled = true;
        }
    }

    clearAllOptions() {
        this.optionsList = this.optionsListCopy;
        this.optionsSelected = [];
        this.optionInput.nativeElement.value = '';
        this.setFilteredOptions();
        this.onOptionChange();
        this.selectAllEnabled = false;
    }

    showMoreChips() {
        const remainingOptions = this.optionsSelected.length - this.maxChipsToShow;

        if (remainingOptions > this.incrementBy) {
            this.maxChipsToShow += this.incrementBy;
        } else {
            this.maxChipsToShow += remainingOptions;
        }

        this.updateRemainingChipsToShow();
    }

    validateSelection(control: FormControl) {
        const selectedOptions = (control.value as ListItem[]) || [];
        return selectedOptions.length > 0 ? null : { required: true };
    }

    onInputBlur() {
        this.optionsCtrl.markAsTouched();
    }

    filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.getAvailableOptions().filter((option) => option.toLowerCase().indexOf(filterValue) === 0);
    }

    private updateRemainingChipsToShow() {
        this.remainingChipsToShow = Math.max(this.optionsSelected.length - this.maxChipsToShow, 0);
    }

    private getAvailableOptions(): string[] {
        return this.optionsList.map((f) => f.name);
    }

    private sortOptionsByName(options: ListItem[]) {
        return options.sort((a, b) => a.name.localeCompare(b.name));
    }
}
