import { LoadingTypes } from 'src/app/shared/models';
import {
    loadOfferDetails,
    loadOfferDetailsSuccess,
    loadOfferDetailsFailure,
    updateOfferDetails,
    updateOfferDetailsSuccess,
    updateOfferDetailsFailure
} from 'src/app/submittals/store/submittals.actions';
import { createReducer, on, Action } from '@ngrx/store';

import { SubmittalOfferDetails } from 'src/app/shared/models/submittals';

export interface SubmittalOfferDetailsState {
    submittalOfferDetails: SubmittalOfferDetails;
    submittalOfferDetailsLoading: LoadingTypes;
    submittalOfferDetailsUpdating: LoadingTypes;
}

const initialState: SubmittalOfferDetailsState = {
    submittalOfferDetails: null,
    submittalOfferDetailsLoading: LoadingTypes.INIT,
    submittalOfferDetailsUpdating: LoadingTypes.INIT
};

export const submittalOfferDetailsReducer = (reducerState: SubmittalOfferDetailsState, reducerAction: Action) => {
    return createReducer(
        initialState,
        on(
            loadOfferDetails,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetailsLoading: LoadingTypes.LOADING
            })
        ),
        on(loadOfferDetailsSuccess, (state, action): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalOfferDetails: action.offerDetails,
                submittalOfferDetailsLoading: LoadingTypes.LOADED
            };
        }),
        on(
            loadOfferDetailsFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetails: null,
                submittalOfferDetailsLoading: LoadingTypes.FAIL
            })
        ),
        on(
            updateOfferDetails,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetailsUpdating: LoadingTypes.LOADING
            })
        ),
        on(updateOfferDetailsSuccess, (state): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalOfferDetailsUpdating: LoadingTypes.LOADED
            };
        }),
        on(
            updateOfferDetailsFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetailsUpdating: LoadingTypes.FAIL
            })
        )
    )(reducerState, reducerAction);
};

export const reducer = (state: SubmittalOfferDetailsState | undefined, action: Action) => {
    return submittalOfferDetailsReducer(state, action);
};
