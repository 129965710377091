import { Component, Input, Output, EventEmitter, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { DropDownFilterSettings, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'grid-multiselect-filter',
    templateUrl: './multiselect-filter.component.html'
})
export class MultiSelectFilterComponent extends BaseFilterCellComponent implements AfterViewInit {
    @Input() public field: string;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() public data: any[];
    @Input() public textField: string;
    @Input() public disabled: boolean;
    @Input() public valueField: string;
    @Input() public defaultText: string;
    @Input() public width: number;
    @Input() uniqueFilterClass: string;
    @Output() public filterChanging = new EventEmitter<any>();
    @ViewChild(MultiSelectComponent, { static: true }) multiselectComponent: MultiSelectComponent;

    @Input() filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'startsWith'
    };

    public get selectedValue(): any {
        const filter = this.filterByField(this.field);
        return filter ? filter.value : null;
    }

    public popupSettings = {
        width: 'auto'
    };

    constructor(filterService: FilterService, private readonly renderer: Renderer2) {
        super(filterService);
    }

    public onChange(value: any): void {
        this.filterChanging.emit({ value, filter: this.filter });

        this.applyFilter(
            value === null
                ? this.removeFilter(this.field)
                : this.updateFilter({
                      field: this.field,
                      operator: 'eq',
                      value
                  })
        );
    }

    ngAfterViewInit() {
        if (this.width && this.multiselectComponent) {
            this.renderer.setStyle(this.multiselectComponent['wrapper'].nativeElement, 'width', `${this.width}px`);
        }
    }
}
