import { ComponentType } from '@angular/cdk/portal';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { DialogRef, IConfirmationDialogOptions, IDialogOptions } from 'src/app/shared/models/dialog.models';
import { ISnackBarOptions, ISnackBarWithActionData } from 'src/app/shared/models/snack-bar.models';
import { PromptDialogComponent } from '../components/prompt-dialog/prompt-dialog.component';
import { PromptDialogOptions } from '../models/promt-dialog-options.model';
import { InformationDialogComponent } from '../components/dialog/information-dialog/information-dialog.component';

@Injectable()
export class DialogService {
    constructor(public dialog: MatDialog, public snackBar: MatSnackBar) {}

    //dialog
    openDialog<TComponent, TDialogData>(
        component: ComponentType<TComponent>,
        options: IDialogOptions<TDialogData> = {} as IDialogOptions<TDialogData>
    ): DialogRef<TComponent, any> {
        const dialogRef = this.dialog.open(component, {
            data: null,
            width: '50%',
            height: 'auto',
            hasBackdrop: true,
            ...options
        } as IDialogOptions<TDialogData>);

        return new DialogRef(dialogRef);
    }

    openConfirmationDialog(options: IConfirmationDialogOptions = {} as IConfirmationDialogOptions): Promise<boolean> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: null,
            width: '500px',
            height: 'auto',
            disableClose: true,
            hasBackdrop: true,
            ...options
        } as IConfirmationDialogOptions);

        return dialogRef.afterClosed().pipe(first()).toPromise();
    }

    openInformationDialog(options: IConfirmationDialogOptions = {} as IConfirmationDialogOptions) {
        return this.dialog.open(InformationDialogComponent, {
            data: null,
            width: '500px',
            height: 'auto',
            hasBackdrop: true,
            ...options
        } as IConfirmationDialogOptions);
    }

    //snack bar
    openCustomSnackBar<TComponent, TSnackBarData>(
        component: ComponentType<TComponent>,
        options: ISnackBarOptions<TSnackBarData> = {} as ISnackBarOptions<TSnackBarData>
    ): MatSnackBarRef<TComponent> {
        return this.snackBar.openFromComponent(component, options);
    }

    openSnackBarSuccess(message: string = 'Success', action: string = null): MatSnackBarRef<SnackBarComponent> {
        return this.openSnackBar(message, action, 'success');
    }

    openSnackBarWarning(message: string = 'Warning', action: string = null): MatSnackBarRef<SnackBarComponent> {
        return this.openSnackBar(message, action, 'warning');
    }

    openSnackBarError(message: string = 'Error', action: string = null): MatSnackBarRef<SnackBarComponent> {
        return this.openSnackBar(message, action, 'error');
    }

    openSnackBarErrorFromErrorResponse(error: HttpErrorResponse): MatSnackBarRef<SnackBarComponent> {
        const message = this.parseError(error);
        return this.openSnackBarError(message);
    }

    openMigratedSnackBarErrorFromErrorResponse(
        error: unknown,
        message: string = 'Error',
        action: string = null
    ): MatSnackBarRef<SnackBarComponent> {
        const errorMessage = this.parseErrorWithBaseMessage(error, message);
        return this.openMigratedSnackBarError(errorMessage, action);
    }

    openMigratedSnackBarSuccess(message: string = 'Success', action: string = null): MatSnackBarRef<SnackBarComponent> {
        return this.openMigratedSnackBar(message, action, 'success');
    }

    openMigratedSnackBarWarning(message: string = 'Warning', action: string = null): MatSnackBarRef<SnackBarComponent> {
        return this.openMigratedSnackBar(message, action, 'warning');
    }

    openMigratedSnackBarError(message: string = 'Error', action: string = null): MatSnackBarRef<SnackBarComponent> {
        return this.openMigratedSnackBar(message, action, 'error');
    }

    parseErrorWithBaseMessage(error: unknown, message: string) {
        let errorInfo = '';
        if (error instanceof HttpErrorResponse) {
            if (typeof error.error === 'object' && error.error?.error_description) {
                errorInfo = error.error.error_description;
            } else {
                errorInfo = error.error;
            }
        }
        return `${message} ${errorInfo}`;
    }

    parseError(e: HttpErrorResponse): string {
        let errorDetails = `An unexpected error has occurred, please try again. If the issue continues, please contact support.`;
        const errorDescriptionPropName = 'error_description';
        if (typeof e.error === 'object' && e.error.hasOwnProperty(errorDescriptionPropName)) {
            errorDetails = e.error[errorDescriptionPropName];
        } else if (typeof e.error === 'string') {
            errorDetails = e.error;
        }

        return `${e.statusText}: ${errorDetails}`;
    }

    openMigratedSnackBar(
        message: string,
        action: string = null,
        style: 'success' | 'warning' | 'error' = 'success'
    ): MatSnackBarRef<SnackBarComponent> {
        const options = {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['snack-bar', `migrated-${style}`],
            data: {
                message,
                action,
                icon: style === 'success' ? 'check' : 'error'
            }
        } as ISnackBarOptions<ISnackBarWithActionData>;
        return this.openCustomSnackBar(SnackBarComponent, options);
    }

    openSnackBar(
        message: string,
        action: string = null,
        style: 'success' | 'warning' | 'error' = 'success'
    ): MatSnackBarRef<SnackBarComponent> {
        const options = {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['snack-bar', style],
            data: {
                message,
                action
            }
        } as ISnackBarOptions<ISnackBarWithActionData>;
        return this.openCustomSnackBar(SnackBarComponent, options);
    }

    openPromptDialog(data: PromptDialogOptions): Observable<any> {
        const options = {
            width: '500px',
            hasBackdrop: true,
            data: {
                okText: 'Ok',
                cancelText: 'Cancel',
                value: '',
                ...data
            }
        };

        const dialogRef = this.dialog.open(PromptDialogComponent, options);

        return dialogRef.afterClosed().pipe(switchMap((result) => (result ? of(result.input) : of())));
    }
}
