import { createAction, props } from '@ngrx/store';
import { VendorBusinessType } from 'src/app/admin/vendor-details/models/vendor-business-types.model';
import { VendorSpecificContractLineType } from 'src/app/shared/models/contract-line-contract-type-update.model';
import { ContractLineContractType } from 'src/app/shared/models/contract-line-contract-type.model';
import { VendorDetail } from 'src/app/shared/models/vendor-detail.model';
import { SelectedVendorType, VendorType } from 'src/app/shared/models/vendor-type.model';
import { JobSettingsLookupsResponse } from '../../vendor-details/models/job-settings-lookups-response.model';
import { VendorJobSettingsResponse } from '../../vendor-details/models/job-settings-response.model';
import { JobSettingsSaveRequest } from '../../vendor-details/models/job-settings.model';

const vendorProfileComponentLoaded = createAction('[VENDOR PROFILE COMPONENT] Vendor Profile Component Is Loaded');

const loadContractLineTypes = createAction('[VENDOR PROFILE COMPONENT] Load Contract Line Types');
const loadContractLineTypesSuccess = createAction(
    '[VENDOR PROFILE API] Load Contract Line Types Success',
    props<{ lineTypes: ContractLineContractType[] }>()
);
const loadContractLineTypesFailure = createAction(
    '[VENDOR PROFILE API] Load Contract Line Types Failure',
    props<{ error: unknown }>()
);

const loadContractLineTypesByVendorId = createAction(
    '[VENDOR PROFILE COMPONENT] Load Contract Line Types By Vendor Id',
    props<{ vendorId: number }>()
);
const loadContractLineTypesByVendorIdSuccess = createAction(
    '[VENDOR PROFILE COMPONENT] Load Contract Line Types By Vendor Id Success',
    props<{ types: VendorSpecificContractLineType[] }>()
);
const loadContractLineTypesbyVendorIdFailure = createAction(
    '[VENDOR PROFILE COMPONENT] Load Contract Line Types By Vendor Id Failure',
    props<{ error: unknown }>()
);

const updateContractLineTypesByVendorId = createAction(
    '[VENDOR PROFILE COMPONENT] Update Contract Line Types By Vendor Id',
    props<{ vendorId: number }>()
);
const updateContractLineTypesByVendorIdSuccess = createAction(
    '[VENDOR PROFILE COMPONENT] Update Contract Line Types By Vendor Id Success'
);
const updateContractLineTypesByVendorIdFailure = createAction(
    '[VENDOR PROFILE COMPONENT] Update Contract Line Types By Vendor Id Failure',
    props<{ error: unknown }>()
);

const loadVendorDetails = createAction('[VENDOR PROFILE COMPONENT] Load Vendor Details', props<{ vendorId: number }>());
const loadVendorDetailsSuccess = createAction(
    '[VENDOR PROFILE API] Load Vendor Details Success',
    props<{ details: VendorDetail }>()
);
const loadVendorDetailsFailure = createAction(
    '[VENDOR PROFILE API] Load Vendor Details Failure',
    props<{ error: unknown }>()
);

const loadVendorBusinessTypes = createAction('[VENDOR PROFILE COMPONENT] Load Vendor Business Types');
const loadVendorBusinessTypesSuccess = createAction(
    '[ADMIN VENDORS API] Load Business Types Success',
    props<{ businessTypes: VendorBusinessType[] }>()
);
const loadVendorBusinessTypesFailure = createAction(
    '[ADMIN VENDORS API] Load Business Types Failure',
    props<{ error: unknown }>()
);

const loadVendorTypes = createAction('[VENDOR PROFILE COMPONENT] Load Vendor Types');
const loadVendorTypesSuccess = createAction(
    '[ADMIN VENDORS API] Load Vendor Types Success',
    props<{ vendorTypes: VendorType[] }>()
);
const loadVendorTypesFailure = createAction(
    '[ADMIN VENDORS API] Load Vendor Types Failure',
    props<{ error: unknown }>()
);

const loadSelectedVendorTypesWithVendorId = createAction(
    '[VENDOR PROFILE COMPONENT] Load Selected Vendor Types With Vendor Id',
    props<{ vendorId: number }>()
);
const loadSelectedVendorTypesWithVendorIdSuccess = createAction(
    '[ADMIN VENDORS API] Load Selected Vendor Types Success',
    props<{ selectedVendorTypes: SelectedVendorType[] }>()
);
const loadSelectedVendorTypesWithVendorIdFailure = createAction(
    '[ADMIN VENDORS API] Load Selected Vendor Types Failure',
    props<{ error: unknown }>()
);
const updateSelectedVendorTypes = createAction(
    '[VENDOR PROFILE COMPONENT] Update Selected Vendor Types',
    props<{ vendorId: number; vendorTypes: VendorType[] }>()
);

const updateSelectedVendorContractLines = createAction(
    '[VENDOR PROFILE COMPONENT] Update Vendor Contract Lines',
    props<{ lines: VendorSpecificContractLineType[] }>()
);

const updateVendorTypeTags = createAction(
    '[LEGACY ANGULARJS CONTROLLER] Update Vendor Type Tags',
    props<{ vendorId: number; tagIds: number[] }>()
);
const updateVendorTypeTagsSuccess = createAction(
    '[ADMIN VENDORS API] Update Vendor Type Tags Success',
    props<{ vendorId: number }>()
);
const updateVendorTypeTagsFailure = createAction(
    '[ADMIN VENDORS API] Update Vendor Type Tags Failure',
    props<{ error: unknown }>()
);

const addVendorContactsPermissions = createAction(
    '[LEGACY ANGULARJS CONTROLLER] Add Vendor Contacts Permissions',
    props<{ vendorId: number }>()
);
const addVendorContactsPermissionsSuccess = createAction('[PERMISSIONS API] Add Vendor Contacts Permissions Success');
const addVendorContactsPermissionsFailure = createAction(
    '[PERMISSIONS API] Add Vendor Contacts Permissions Failure',
    props<{ error: unknown }>()
);

const removeVendorContactsPermissions = createAction(
    '[LEGACY ANGULARJS CONTROLLER] Remove Vendor Contacts Permissions',
    props<{ vendorId: number }>()
);
const removeVendorContactsPermissionsSuccess = createAction(
    '[PERMISSIONS API] Remove Vendor Contacts Permissions Success'
);

const removeVendorContactsPermissionsFailure = createAction(
    '[PERMISSIONS API] Remove Vendor Contacts Permissions Failure',
    props<{ error: unknown }>()
);

// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
const saveJobPermissions = createAction(
    '[VENDOR PROFILE COMPONENT] Save Job Permissions',
    props<{ perms: JobSettingsSaveRequest }>()
);

// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
const saveJobPermissionsSuccess = createAction(
    '[PERMISSIONS API] Save Job Permissions Success',
    props<{ vendorId: number }>()
);

// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
const saveJobPermissionsFailure = createAction(
    '[PERMISSIONS API] Save Job Permissions Failure',
    props<{ error: unknown }>()
);

// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
const loadJobLookups = createAction('[VENDOR PROFILE COMPONENT] Load Job Lookups');

// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
const loadJobLookupsSuccess = createAction(
    '[VENDOR PROFILE API] Load Job Lookups Success',
    props<{ result: JobSettingsLookupsResponse }>()
);

// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
const loadJobLookupsFailure = createAction(
    '[VENDOR PROFILE API] Load Job Lookups Failure',
    props<{ error: unknown }>()
);

// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
const loadJobPermissions = createAction(
    '[VENDOR PROFILE COMPONENT] Load Job Permissions',
    props<{ vendorId: number }>()
);

// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
const loadJobPermissionsSuccess = createAction(
    '[VENDOR PROFILE API] Load Job Permissions Success',
    props<{ result: VendorJobSettingsResponse }>()
);

// DEPRECATED - CAN BE REMOVED WHEN FEATURE FLAG RemoveVendorJobPermissionsSection IS BEING REMOVED
const loadJobPermissionsFailure = createAction(
    '[VENDOR PROFILE API] Load Job Permissions Failure',
    props<{ error: unknown }>()
);

const resetVendorProfile = createAction('[VENDOR PROFILE COMPONENT] Reset Vendor Profile');

const setVendorContractsPagination = createAction(
    '[VENDOR PROFILE COMPONENT] Set Pagination',
    props<{ pageSize?: number; skip?: number; vendorId: number }>()
);

export const saveVendorSuccess = createAction('[VENDOR PROFILE COMPONENT] Save Vendor Success');

export const vendorProfileActions = {
    vendorProfileComponentLoaded,
    loadContractLineTypes,
    loadContractLineTypesSuccess,
    loadContractLineTypesFailure,
    loadContractLineTypesByVendorId,
    loadContractLineTypesByVendorIdSuccess,
    loadContractLineTypesbyVendorIdFailure,
    updateContractLineTypesByVendorId,
    updateContractLineTypesByVendorIdSuccess,
    updateContractLineTypesByVendorIdFailure,
    loadVendorDetails,
    loadVendorDetailsSuccess,
    loadVendorDetailsFailure,
    loadVendorBusinessTypes,
    loadVendorBusinessTypesSuccess,
    loadVendorBusinessTypesFailure,
    loadVendorTypes,
    loadVendorTypesSuccess,
    loadVendorTypesFailure,
    loadSelectedVendorTypesWithVendorId,
    loadSelectedVendorTypesWithVendorIdSuccess,
    loadSelectedVendorTypesWithVendorIdFailure,
    updateSelectedVendorTypes,
    updateSelectedVendorContractLines,
    updateVendorTypeTags,
    updateVendorTypeTagsSuccess,
    updateVendorTypeTagsFailure,
    addVendorContactsPermissions,
    addVendorContactsPermissionsSuccess,
    addVendorContactsPermissionsFailure,
    removeVendorContactsPermissions,
    removeVendorContactsPermissionsSuccess,
    removeVendorContactsPermissionsFailure,
    resetVendorProfile,
    saveJobPermissions,
    saveJobPermissionsSuccess,
    saveJobPermissionsFailure,
    loadJobPermissions,
    loadJobPermissionsFailure,
    loadJobPermissionsSuccess,
    loadJobLookups,
    loadJobLookupsFailure,
    loadJobLookupsSuccess,
    setVendorContractsPagination,
    saveVendorSuccess
};
