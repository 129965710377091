import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { InternalPoolService } from '../../internal-pool.service';
import * as internalPoolActions from 'src/app/internal-pool/store/actions';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { CoreStoreFacade } from 'src/app/core/state/core-store.facade';

@Injectable()
export class ResourceDetailsEffect extends BaseEffect {
    constructor(
        private readonly actions: Actions,
        private readonly service: InternalPoolService,
        private readonly coreStoreFacade: CoreStoreFacade,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    loadSystemIdWithLookupsAndResourceDetails$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.loadSystemIdWithLookupsAndResourceDetails),
        switchMap((action) =>
            this.coreStoreFacade.getClientSharedData().pipe(
                mergeMap(response => [
                    internalPoolActions.loadSystemIdSuccess({systemId: response.systemId}),
                    internalPoolActions.loadResourceDetails({id: action.id, systemId: response.systemId}),
                    internalPoolActions.loadResourcesLookups()
                ]),
                catchError((error) => of(internalPoolActions.loadSystemIdFail({error})))
            ))
    ))

    loadResourceDetails$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.loadResourceDetails),
        switchMap((action) => {
            if (action.id === 0 || action.systemId === 0) {
                return of(internalPoolActions.loadResourceDetailsSuccess({ resource: null }));
            };
            return this.service.getResourceDetails(action.id, action.systemId).pipe(
                map(resource => {
                    const facilities = resource.facilities
                        ?.slice()
                        .sort((a, b) => a.facilityName.localeCompare(b.facilityName));

                    return internalPoolActions.loadResourceDetailsSuccess({
                        resource: {
                            ...resource,
                            facilities
                        }
                    })
                }),
                catchError((error) => of(internalPoolActions.loadResourceDetailsFail({ error })))
            )
        })
    ));

    loadResourceDetailsCurrentSystem$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.loadResourceDetailsCurrentSystem),
        switchMap((action) =>
            this.coreStoreFacade.getClientSharedData().pipe(
                map(response => internalPoolActions.loadResourceDetails({
                    id: action.id,
                    systemId: response.systemId
                })),
            ))
    ));

    loadResourceDetailsFailed$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.loadResourceDetailsFail),
        map((action) => action.error),
        tap((error) => this.handleError(error))
    ), { dispatch: false });

    checkResourceUsername$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.checkResourceUsername),
        withLatestFrom(this.coreStoreFacade.getClientSharedData()),
        switchMap(([action, sharedData]) => {
            const systeId = sharedData.systemId;
            const email = action.email;
            return this.service.checkResourceEmail(systeId, email).pipe(
                map(response => {
                    return internalPoolActions.checkResourceUsernameSuccess({username: response.userSuggestion, isInUse: response.isInUse});
                }),
                catchError((error) =>
                    of(internalPoolActions.checkResourceUsernameFail({error}))
                )
            )
        })
    ))

    resourceUsernameInUse$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.checkResourceUsernameSuccess),
        filter(action => action.isInUse),
        tap(() => {
            const message = 'IRP worker has existing email and will be assigned a new Shifts App User ID to log in to the Shifts App. Please see Shifts App User ID field below';
            this.toasterService.info(message);
        })
    ), {dispatch: false})

   userNameAlreadyTaken$ =  createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.checkResourceUsernameFail),
        map(action => action.error),
        tap((error) => {
            if(error.status === 400) {
                this.toasterService.fail(error.error);
            }
        })
    ), {dispatch: false})


}
