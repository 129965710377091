import { lookupsStoreKey } from 'src/app/lookups/state/lookups.state';
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LookupsState } from './lookups.state';

const getLookupsState = createFeatureSelector<LookupsState>(lookupsStoreKey);

export const getLinesOfBusiness = createSelector(
    getLookupsState,
    state => state.linesOfBusiness.linesOfBusiness
);

export const getJobsLinesOfBusiness = createSelector(
    getLookupsState,
    state => state.jobsLinesOfBusiness.linesOfBusiness
);

export const getSubmittalsLinesOfBusiness = createSelector(
    getLookupsState,
    state => state.submittalsLinesOfBusiness.linesOfBusiness
);

export const getTimecardsLinesOfBusiness = createSelector(
    getLookupsState,
    state => state.timecardsLinesOfBusiness.linesOfBusiness
);

export const getWorkersLinesOfBusiness = createSelector(
    getLookupsState,
    state => state.workersLinesOfBusiness.linesOfBusiness
);

export const getEditJobsLinesOfBusiness = createSelector(
    getLookupsState,
    state => state.editJobsLinesOfBusiness.linesOfBusiness
);

export const getShiftTypes = createSelector(
    getLookupsState,
    state => state.shiftTypes.shiftTypes
);
