import {
    Component,
    AfterViewInit,
    Input,
    ElementRef,
    ViewChild,
    ChangeDetectorRef,
    Output,
    EventEmitter
} from '@angular/core';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FilesToUpload, FileValidationResult, AttachmentConfig } from 'src/app/shared/models/attachment';
import { Subject } from 'rxjs';

@Component({
    selector: 'ayac-draganddrop-uploader',
    templateUrl: './drag-and-drop-uploader.component.html',
    styleUrls: ['./drag-and-drop-uploader.component.scss']
})
export class DragAndDropUploaderComponent extends UnsubscribeOnDestroy implements AfterViewInit {
    document: any = { files: new Array<FilesToUpload>() };
    validationResults: FileValidationResult[] = [];
    fileExtensions: string;

    @Input()
    fileUploaderConfig = new AttachmentConfig();

    @Input()
    editMode = true;

    @Input() loading: boolean;

    @Output() hasFileAttached = new EventEmitter<boolean>();

    @ViewChild('fileInput') fileInput: ElementRef;

    private readonly filesChangedSubject$: Subject<boolean> = new Subject();
    public filesChanged$ = this.filesChangedSubject$.asObservable();

    constructor(private readonly dialog: DialogService, private readonly ref: ChangeDetectorRef) {
        super();
    }

    ngAfterViewInit(): void {
        this.fileExtensions = this.fileUploaderConfig.validFileExtensions.join(', ');
        this.ref.detectChanges(); //seems to throw a console error if it detects any changes
    }

    //triggered when a user drags/drops a file
    getDroppedFiles(e: DragEvent): void {
        this.updateDragState(e);
        this.addFiles(e.dataTransfer.files);
    }

    //triggered when a user clicks the "browse" button
    openExplorer(): void {
        this.fileInput.nativeElement.click();
    }

    addSelectedFiles(): void {
        this.addFiles(this.fileInput.nativeElement.files);
        this.fileInput.nativeElement.value = null;
    }

    updateDragState(e: DragEvent): void {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = 'copy';
        }
        this.fileUploaderConfig.isDragging = e.type === 'dragover';
    }

    //User clicks the trash can to remove a file.
    removeFile(value: File): void {
        this.dialog
            .openConfirmationDialog({
                data: {
                    title: this.fileUploaderConfig.deleteDialogTitle,
                    text: this.fileUploaderConfig.deleteDialogText,
                    cancelButtonText: this.fileUploaderConfig.isVendorExpenseDragAndDrop ? 'No, cancel' : null,
                    confirmButtonText: this.fileUploaderConfig.isVendorExpenseDragAndDrop ? 'Yes, delete' : null,
                    isDeleteConfirmation: this.fileUploaderConfig.isVendorExpenseDragAndDrop ? true : false
                }
            } as IConfirmationDialogOptions)
            .then((result) => {
                if (result) {
                    this.document.files = this.document.files.filter((file) => file.name !== value.name);
                    this.filesChangedSubject$.next(true);
                    this.hasFileAttached.emit(false);
                }
            });
    }

    getFiles(): File[] {
        if (this.document.files && this.document.files.length) {
            return this.document.files;
        }
        return [];
    }

    private addFiles(fileList: FileList): void {
        this.validationResults = [];
        //make sure only one file is being uploaded at a time.
        if (fileList.length > 1) {
            this.validationResults.push({
                isValid: false,
                name: '',
                message: 'Multiple files are not allowed. Please upload only a single file.'
            });
        } else {
            for (let i = 0; i < fileList.length; i++) {
                const file: any = fileList[i];
                if (this.validateFile(fileList[i])) {
                    this.document.files.pop();
                    this.document.files.push(file);
                    this.document.originalFileName = this.document.files.length > 1 ? 'Multiple files' : file.name;
                    this.hasFileAttached.emit(Boolean(this.document.files.length));
                }
            }
        }
        this.filesChangedSubject$.next(true);
    }

    private validateFile(file: File): boolean {
        if (file.size > 50 * 1024 * 1024) {
            //50Mb
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: 'File format exceeds 50MB size limit. Please save the file as a smaller size and try again.'
            });
            return false;
        }

        if (this.document.files.find((f) => f.name === file.name)) {
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: `There is already a file named ${file.name}. Please save the file with a different file name and try again.`
            });
            return false;
        }

        const ext = /(?:\.([^.]+))?$/.exec(file.name)[1];
        if (!this.fileUploaderConfig.validFileExtensions.some((x) => x === `.${ext}`.toLowerCase())) {
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: `Error uploading "${file.name}": Invalid file type.`
            });
            return false;
        }
        return true;
    }
}
