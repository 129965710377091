import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes, ɵEmptyOutletComponent } from '@angular/router';
import { DatadogLoggerComponent } from './datadog-logger/datadog-logger.component';
import { MasterDetailRouteReuseStrategy } from './shared/master-detail-route-reuse-strategy';

export const appRoutes: Routes = [
    {
        path: 'devops/logger',
        component: DatadogLoggerComponent
    },
    {
        path: 'admin',
        loadChildren: () => import('src/app/admin/admin.module').then((m) => m.AdminModule)
    },
    {
        path: 'admin/vendors',
        loadChildren: () => import('src/app/admin-vendors/admin-vendors.module').then((m) => m.AdminVendorsModule)
    },
    {
        path: 'admin/clientContacts',
        loadChildren: () => import('src/app/client-contacts/client-contacts.module').then((m) => m.ClientContactsModule)
    },
    {
        path: 'admin/clientContact',
        loadChildren: () => import('src/app/client-contacts/client-contacts.module').then((m) => m.ClientContactsModule)
    },
    {
        path: 'admin/vendorContacts',
        loadChildren: () => import('src/app/vendor-contacts/vendor-contacts.module').then((m) => m.VendorContactsModule)
    },
    {
        path: 'admin/vendorContact/:id',
        loadChildren: () =>
            import('src/app/admin/vendor-contact-details/vendor-contact-details.module').then(
                (m) => m.VendorContactDetailsModule
            )
    },
    {
        path: 'cs',
        loadChildren: () =>
            import('src/app/customer-service/customer-service.module').then((m) => m.CustomerServiceModule)
    },
    {
        path: 'client/home',
        loadChildren: () => import('src/app/dashboard-new/dashboard.module').then((m) => m.DashboardModule)
    },
    {
        path: 'client/dashboard/qa',
        loadChildren: () => import('src/app/dashboard-new/dashboard.module').then((m) => m.DashboardModule)
    },
    {
        path: 'client/help',
        loadChildren: () => import('src/app/help/help.module').then((m) => m.HelpModule)
    },
    {
        path: 'client/shiftssubmittals',
        loadChildren: () =>
            import('src/app/shifts-submittals/shifts-submittals.module').then((m) => m.ShiftsSubmittalsModule)
    },
    {
        path: 'extension',
        loadChildren: () => import('src/app/travelers/extension/extension.module').then((m) => m.ExtentionModule)
    },
    {
        path: 'client',
        loadChildren: () => import('src/app/client/client.module').then((m) => m.ClientModule)
    },
    {
        path: 'admin/facilities',
        loadChildren: () => import('src/app/facilities/facilities.module').then((m) => m.FacilitiesModule)
    },
    {
        path: 'client/workers',
        loadChildren: () => import('src/app/travelers/travelers.module').then((m) => m.TravelersModule)
    },
    {
        path: 'client/expenses',
        loadChildren: () => import('src/app/client-expenses/client-expenses.module').then((m) => m.ClientExpensesModule)
    },
    {
        path: 'admin/reports/powerbi/:code',
        loadChildren: () => import('src/app/powerbi/powerbi-report.module').then((m) => m.PowerBiReportModule)
    },
    {
        path: 'vendor',
        loadChildren: () => import('src/app/vendor/vendor.module').then((m) => m.VendorModule)
    },
    {
        path: 'admin/facility',
        loadChildren: () => import('src/app/facilities/facilities.module').then((m) => m.FacilitiesModule)
    },
    {
        path: 'admin/reports',
        loadChildren: () => import('src/app/admin-reports/admin-reports.module').then((m) => m.AdminReportsModule)
    },
    {
        path: 'admin/systemContacts',
        loadChildren: () =>
            import('src/app/admin-system-contacts/admin-system-contacts.module').then(
                (m) => m.AdminSystemContactsModule
            )
    },
    {
        path: 'admin/systemContactPemissions',
        loadChildren: () =>
            import('src/app/admin-system-contacts/system-contacts-permissions/system-contacts-permissions.module').then(
                (m) => m.SystemContactsPermissionsModule
            )
    },
    {
        path: 'admin/clientContactPemissions',
        loadChildren: () =>
            import('src/app/client-contacts/client-contacts-permissions/client-contacts-permissions.module').then(
                (m) => m.ClientContactsPermissionsModule
            )
    },
    {
        path: 'admin/clientContactRoles',
        loadChildren: () =>
            import('src/app/admin/role-permissions/role-permissions.module').then((m) => m.RolePermissionsModule)
    },
    {
        path: 'admin/clients',
        loadChildren: () => import('src/app/admin-clients/admin-clients.module').then((m) => m.AdminClientsModule)
    },
    {
        path: 'admin/clients/:id',
        loadChildren: () => import('src/app/admin-clients/admin-clients.module').then((m) => m.AdminClientsModule)
    },
    {
        path: 'woc',
        loadChildren: () => import('src/app/woc/woc.module').then((m) => m.WocModule)
    },
    {
        path: 'devops',
        loadChildren: () => import('src/app/devops/devops.module').then((m) => m.DevOpsModule)
    },
    {
        path: 'sso/:idp',
        loadChildren: () => import('src/app/universal-login/universal-login.module').then((m) => m.UniversalLoginModule)
    },
    {
        path: 'login',
        loadChildren: () => import('src/app/universal-login/universal-login.module').then((m) => m.UniversalLoginModule)
    },
    {
        path: 'help',
        loadChildren: () => import('src/app/admin/help/help-page.module').then((m) => m.HelpPageModule)
    },
    {
        path: 'clinical',
        loadChildren: () => import('src/app/clinical/clinical.module').then((m) => m.ClinicalModule)
    },
    {
        path: '',
        loadChildren: () => import('src/app/portal/portal.module').then((m) => m.PortalModule)
    },
    {
        path: '',
        loadChildren: () => import('src/app/landing/landing.module').then((m) => m.LandingModule)
    },
    {
        path: '**',
        component: ɵEmptyOutletComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            useHash: true,
            initialNavigation: 'enabledBlocking',
            enableTracing: false,
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [RouterModule],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: MasterDetailRouteReuseStrategy
        }
    ]
})
export class AppRoutingModule {}
